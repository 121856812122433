import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { useInjection } from '../di/ioc-provider';
import { IUserApiClient } from '../clients/globis/userApiClient';
import { TYPES } from '../di/types';
import { LoginModel, Roles } from '../clients/globis/models';
import { LoadButton } from '../components/loadButton';
import { useTranslation } from 'react-i18next';
import { AuthStore } from '../stores/AuthStore';
import { useHistory } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { timeoutQuery } from '../components/helpers';
import Alert from '@mui/material/Alert';
import { useRef } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const theme = createTheme();

export const LoginPage =() =>  {
    const _userClient = useInjection<IUserApiClient>(TYPES.IUserClient);
    const theme = useTheme();
    const formRef = useRef(null);
    const history = useHistory();
    const [errorReceived, setErrorReceived] = React.useState(false);
    const [loginModel, setLoginModel] = React.useState<LoginModel>({
        userName: "",
        password: ""
    });
    const [t] = useTranslation("common");
    const [loading, setLoading] = React.useState(false);
    const saveValidate = () => {
        if (formRef != null && formRef.current != null) {
            var testForm = formRef.current as any;
            testForm
                .isFormValid()
                .then((result: boolean) => {
                    if (result) {
                        login();
                    }
                })
                .catch((err: any) => {});
        }
    };
    const login = () => {
        setLoading(true);
        setErrorReceived(false);
        _userClient.login(loginModel)
            .takeAtLeast(1000).then((res) => {

                var info = jwt_decode(res.data) as any;
                var org = null;
                if (info.organisation != null) {
                    org = parseInt(info.organisation);
                }
                var team = null;
                try {
                    var x = Number.parseInt(info.team);
                    if(!isNaN(x)) team = x;
                } catch { }
                var obj = { newUser: info.newUser === "True", jwtToken: res.data,team: team, name: info.name, email: info.email, role: Roles[info.role as keyof typeof Roles], organisation: org };
                AuthStore.update(w => { w.authSettings = obj });
                console.log(obj);
                window.localStorage.setItem('jwt', res.data);

                history.push("/");
            })
            .catch((err) => {
                setErrorReceived(true);
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return <>
        <ValidatorForm style={{ width: "100%" }} ref={formRef} onSubmit={() => { }}>
            <TextValidator

                disabled={loading}
                margin="normal"

                fullWidth
                label={t("general.email")}
                name="email"
                value={loginModel.userName}
                autoFocus
                validators={["required"]}
                errorMessages={[t("validation.required")]}
                onChange={(ev) => {
                    var copy = { ...loginModel };
                    copy.userName = ev.target.value;
                    setLoginModel(copy);
                }}
            />
            <TextValidator
                disabled={loading}
                margin="normal"
                value={loginModel.password}
                fullWidth
                name="password"
                label={t("general.password")}
                type="password"
                validators={["required"]}
                errorMessages={[t("validation.required")]}
                autoComplete="current-password"
                onChange={(ev) => {
                    var copy = { ...loginModel };
                    copy.password = ev.target.value;
                    setLoginModel(copy);
                }}
            />
            <LoadButton
                style={{ width: "100%" }}
                isLoading={loading}
                type="submit"
                variant="contained"
                buttonText={t("general.loginBtn")}
                onClick={saveValidate}>

            </LoadButton>
            {errorReceived ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="error">{t("general.errorLogin")}</Alert> : null}

            <Grid container sx={{ mt: "20px" }}>
                <Grid item xs style={{ textAlign: "right" }}>
                    <Link href="#" variant="body2" onClick={() => {
                        history.push("/pwdResetAttempt")
                    }}>
                        {t("general.passwordForgotLink")}
                    </Link>
                </Grid>
            </Grid>
        </ValidatorForm></>

}

export default LoginPage