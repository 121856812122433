import { Grid, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { useInjection } from '../di/ioc-provider';
import { TYPES } from '../di/types';
import React from 'react';
import { ITeamsClient } from '../clients/globis/teamsClient';
import { TeamListDto } from '../clients/globis/models';
import { useOrganisation } from '../hooks/useOrganisation';
import { SortOrder } from './dcDataTable';
import Loader from './Loader';
import { List } from 'linqts';

export interface TeamPickerProps {
    defaultTeam?: number | null,
    selectFirst:boolean
    onTeamPicked: (team: number) => void;
}
export const TeamPicker = (props: TeamPickerProps) => {


    const [t] = useTranslation("common");
    const [loading, setLoading] = React.useState(true);
    const [teams, setTeams] = React.useState<TeamListDto[]>([]);
    const [selectedTeam, setSelectedTeam] = React.useState<TeamListDto | undefined>(undefined);
    const _teamClient = useInjection<ITeamsClient>(TYPES.ITeamsClient);
    const [render, organisationId] = useOrganisation();
    React.useEffect(() => {

        fetchTeams();
    }, [])
    React.useEffect(() => {
        if (props.defaultTeam != null && teams.length > 0) {
            var team = new List(teams).FirstOrDefault(w => w.id == props.defaultTeam);
            if (team != null) {
                setSelectedTeam(team);
            }
        }
    }, [props.defaultTeam, teams])
    const fetchTeams = () => {
        setLoading(true);
        _teamClient.fetchTeams({ payload: { organisation: organisationId, search: "" }, skip: 0, take: 10000, sortColumn: "Name", sortOrder: SortOrder.ASC }).then((res) => {
            setTeams(res.data.data);
            if (res.data.data.length > 0 && props.selectFirst) {
                setSelectedTeam(res.data.data[0]);
                props.onTeamPicked(res.data.data[0].id);
            }


        }).finally(() => {
            setLoading(false);
        })
    }
    if (loading) return <Loader />
    return <>

        <Grid item xs={12}>
            <FormControl fullWidth>
                <InputLabel>{t("team.chooseTeam")}</InputLabel>
                <Select
                    inputProps={{ shrink: selectedTeam }}
                    label={t("team.chooseTeam")}
                    disabled={loading}
                    onChange={(ev) => {
                        props.onTeamPicked(ev.target.value as number);
                        var team = new List(teams).FirstOrDefault(w => w.id == ev.target.value);
                        if (team != undefined) {
                            setSelectedTeam(team);
                        }
                    }}
                    value={selectedTeam?.id ?? 0}
                >
                    {/* <MenuItem key={null} value={null}>{t("general.noTeamSelected")}</MenuItem> */}
                    {teams.map((el) => {
                        return <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Grid>
    </>
}