import AddIcon from "@mui/icons-material/Add";
import MergeIcon from "@mui/icons-material/CallMerge";
import SearchIcon from "@mui/icons-material/Search";
import { Alert, Grid, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { List } from "linqts";
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useImmer } from 'use-immer';
import { IContentClient } from '../clients/globis/contentClient';
import { CreateEvaluationQuestionFormModel, CreateUpdateEvaluationFicheFormModel, EvaluationFicheListDto, EvaluationQuestionDto, EvaluationQuestionLevel, RemoveEvaluationFiche } from '../clients/globis/models';
import Loader from '../components/Loader';
import DcMenu from '../components/contextMenu';
import { SortOrder } from '../components/dcDataTable';
import { EvaluationFicheList } from '../components/evaluationFicheList';
import GeneralDialog from '../components/generalDialog';
import { beautifyDate, createdByText, swapElements, timeoutQuery } from '../components/helpers';
import { LoadButton } from '../components/loadButton';
import { MergeEvaluationFiche } from "../components/mergeEvaluationFIche";
import OverviewBlock from '../components/overviewBlock';
import { useInjection } from '../di/ioc-provider';
import { TYPES } from '../di/types';
import { IQuestionFormModel, NewQuestion } from '../forms/content/newQuestion';
import { useOrganisation } from '../hooks/useOrganisation';
import Save from "@mui/icons-material/Save"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from '@mui/icons-material/Edit';
import { AuthStore } from "../stores/AuthStore";
export interface IContentProps {

}
export enum ChangeQuestionAction {
    CreateHeading, CreateCategory, CreateQuestion, Edit, MoveUp, MoveDown, Remove
}

export const ContentPages = (props: IContentProps) => {
    const formRef = useRef(null);
    const theme = useTheme();
    const [t] = useTranslation("common");
    const _templateFetchCount = 6;
    const [isSaving, setIsSaving] = React.useState(false);
    const [openSearchDialog, setOpenSearchDialog] = React.useState(false);
    const [openMergeDialog, setOpenMergeDialog] = React.useState(false);
    const _contentClient = useInjection<IContentClient>(TYPES.IContentClient);
    const [templatePickerLoading, setTemplatePickerLoading] = React.useState(false);
    const [templateLoading, setTemplateLoading] = React.useState(false);
    const [templates, setTemplates] = React.useState<EvaluationFicheListDto[]>([]);
    const [formModel, setFormModel] = useImmer<CreateUpdateEvaluationFicheFormModel | undefined>(undefined);
    const renderEditContent = React.useMemo(() => { return formModel != null }, [formModel]);
    const [, organisation] = useOrganisation();
    const [onQuestion, setOnQuestion] = useImmer<QuestionPickerProps>(undefined);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [removing, setRemoving] = React.useState(false);
    const __activeOrganisation = AuthStore.useState(w=>w.organisation);
    const openNewQuestionDialog = React.useMemo(() => {
        return onQuestion != undefined && onQuestion.action != ChangeQuestionAction.Edit;
    }, [onQuestion])
    const openEditQuestionDialog = React.useMemo(() => {
        return onQuestion != undefined && onQuestion.action == ChangeQuestionAction.Edit;
    }, [onQuestion])
    const [removingError, setRemovingError] = React.useState(undefined);
    React.useEffect(() => {
        searchEvaluationRecentFiches();
    }, [organisation])
    const searchEvaluationRecentFiches = () => {
        _contentClient.searchEvaluationFiche({
            skip: 0,
            take: _templateFetchCount,
            sortOrder: SortOrder.DESC,
            sortColumn: "Updated",
            payload: { organisation: organisation, team: null, search: "", includeForeignFiches: false }
        }).then((res) => {
            setTemplates(res.data.data);
        }).finally(() => {
            setTemplatePickerLoading(false);
        })
    }
    const fetchModelByIndexes = (draft: CreateUpdateEvaluationFicheFormModel, parentHeaderIndex: number, parentCatIndex?: number, questionIndex?: number) => {
        if (parentHeaderIndex != null && parentCatIndex != null && questionIndex != null) {
            return draft.questions[parentHeaderIndex].children[parentCatIndex].children[questionIndex];
        }
        else if (parentHeaderIndex != null && parentCatIndex != null) {
            return draft.questions[parentHeaderIndex].children[parentCatIndex];
        }
        else if (parentHeaderIndex != null) {
            return draft.questions[parentHeaderIndex];
        }
    }
    const renderTemplateCard = (ev: EvaluationFicheListDto) => {
        return <OverviewBlock enableHoverAnimation={true} >
            <Grid container justifyContent={"space-between"} onClick={() => { loadNewTemplate(ev.id) }}>
                <Grid item xs={12} md={12}>
                    <Typography
                        variant="body1">
                        {ev.name}
                    </Typography>

                    <Typography
                        style={{ fontSize: "0.8rem", marginTop: "10px" }}
                        variant="body2">
                        {createdByText(t, ev.created, ev.createdByName)}
                    </Typography>
                </Grid>
            </Grid>
        </OverviewBlock>
    }
    const renderFormModel = () => {
        return <>
            {formModel.questions.map((heading, index) => {
                return renderHeading(heading, index)
            })}
        </>
    }
    const generateOptions = (model: CreateEvaluationQuestionFormModel) => {
        var options = [{ option: t("content.menu.edit"), id: ChangeQuestionAction.Edit }];
        if (model.level == EvaluationQuestionLevel.HEADING) {
            options.push({ option: t("content.menu.createHeading"), id: ChangeQuestionAction.CreateHeading });
            options.push({ option: t("content.menu.createCategory"), id: ChangeQuestionAction.CreateCategory });
        } else if (model.level == EvaluationQuestionLevel.CATEGORIE) {
            options.push({ option: t("content.menu.createCategory"), id: ChangeQuestionAction.CreateCategory })
            options.push({ option: t("content.menu.createQuestion"), id: ChangeQuestionAction.CreateQuestion });
        } else if (model.level == EvaluationQuestionLevel.QUESTION) {
            options.push({ option: t("content.menu.createQuestion"), id: ChangeQuestionAction.CreateQuestion });
        }
        options.push({ option: t("content.menu.moveUp"), id: ChangeQuestionAction.MoveUp });
        options.push({ option: t("content.menu.moveDown"), id: ChangeQuestionAction.MoveDown });
        options.push({ option: t("content.menu.remove"), id: ChangeQuestionAction.Remove });
        return options;
    }
    interface QuestionPickerProps {
        action: ChangeQuestionAction, parentHeaderIndex?: number, parentCatIndex?: number, questionIndex?: number, currentModel: CreateEvaluationQuestionFormModel
    }
    //used to store question -> open dialog -> info about question -> save 
    const storeQuestionAction = (props: QuestionPickerProps) => {
        if (props.action == ChangeQuestionAction.Edit || props.action == ChangeQuestionAction.CreateCategory || props.action == ChangeQuestionAction.CreateHeading || props.action == ChangeQuestionAction.CreateQuestion) {
            setOnQuestion((draft) => { return props });
        } else {
            executeQuestionPickerAction(props, undefined);
        }

    }
    const executeQuestionPickerAction = (props: QuestionPickerProps, model: IQuestionFormModel) => {
        let { action, parentHeaderIndex, parentCatIndex, questionIndex } = props;
        setFormModel((draft) => {
            switch (action) {
                case ChangeQuestionAction.CreateHeading:
                    draft.questions.splice(parentHeaderIndex + 1, 0, createNewQuestion(EvaluationQuestionLevel.HEADING, model));

                    break;
                case ChangeQuestionAction.CreateCategory:
                    draft.questions[parentHeaderIndex].children.splice(parentCatIndex + 1, 0, createNewQuestion(EvaluationQuestionLevel.CATEGORIE, model))
                    break;
                case ChangeQuestionAction.CreateQuestion:
                    draft.questions[parentHeaderIndex].children[parentCatIndex].children.splice(questionIndex + 1, 0, createNewQuestion(EvaluationQuestionLevel.QUESTION, model))
                    break;
                case ChangeQuestionAction.Edit:
                    var draftObject = fetchModelByIndexes(draft, parentHeaderIndex, parentCatIndex, questionIndex);
                    draftObject.question = model.text;
                    draftObject.color = model.color;
                    break;
                case ChangeQuestionAction.Remove:
                    if (parentCatIndex == null) {
                        draft.questions.splice(parentHeaderIndex, 1);
                    } else if (questionIndex == null) {
                        draft.questions[parentHeaderIndex].children.splice(parentCatIndex, 1);
                    } else {
                        draft.questions[parentHeaderIndex].children[parentCatIndex].children.splice(questionIndex, 1);
                    }
                    break;
                case ChangeQuestionAction.MoveUp:
                    var draftObject = fetchModelByIndexes(draft, parentHeaderIndex, parentCatIndex, questionIndex);
                    switch (draftObject.level) {
                        case EvaluationQuestionLevel.QUESTION:
                            if (questionIndex == 0 && parentCatIndex == 0 && parentHeaderIndex == 0) {
                                //already on top
                            } else if (questionIndex == 0 && parentCatIndex != 0 && parentHeaderIndex == 0) {
                                //move to cat above
                                draft.questions[parentHeaderIndex].children[parentCatIndex].children.splice(questionIndex, 1)
                                draft.questions[parentHeaderIndex].children[parentCatIndex - 1].children.push(draftObject);

                            } else if (questionIndex == 0 && parentCatIndex == 0 && parentHeaderIndex != 0) {
                                if (draft.questions[parentHeaderIndex - 1].children.length > 0) {
                                    //Move to last cat from the new header
                                    draft.questions[parentHeaderIndex].children[parentCatIndex].children.splice(questionIndex, 1)
                                    draft.questions[parentHeaderIndex - 1].children[draft.questions[parentHeaderIndex - 1].children.length - 1].children.push(draftObject);
                                }
                            }
                            else {
                                swapElements(draft.questions[parentHeaderIndex].children[parentCatIndex].children, questionIndex - 1, questionIndex)
                            }

                            break;
                        case EvaluationQuestionLevel.CATEGORIE:

                            if (parentHeaderIndex == 0 && parentCatIndex == 0) {
                                //already above
                            } else if (parentHeaderIndex != 0 && parentCatIndex == 0) {
                                draft.questions[parentHeaderIndex].children.splice(parentCatIndex, 1)
                                draft.questions[parentHeaderIndex - 1].children.push(draftObject);
                            } else if (parentCatIndex != 0) {
                                swapElements(draft.questions[parentHeaderIndex].children, parentCatIndex - 1, parentCatIndex)
                            }

                            break;
                        case EvaluationQuestionLevel.HEADING:
                            if (parentHeaderIndex != 0) {
                                swapElements(draft.questions, parentHeaderIndex - 1, parentHeaderIndex)
                            }
                            break;
                    }
                    break;
                case ChangeQuestionAction.MoveDown:
                    var draftObject = fetchModelByIndexes(draft, parentHeaderIndex, parentCatIndex, questionIndex);
                    switch (draftObject.level) {

                        case EvaluationQuestionLevel.QUESTION:
                            var maxIndexHead = draft.questions.length - 1;
                            var maxIndexCat = draft.questions[maxIndexHead].children.length - 1;
                            var maxIndexQ = draft.questions[maxIndexHead].children[maxIndexCat].children.length - 1;

                            if (parentHeaderIndex == maxIndexHead && parentCatIndex == maxIndexCat && questionIndex == maxIndexQ) {
                                //the bottom
                            } else if (draft.questions[parentHeaderIndex].children.length - 1 != parentCatIndex && questionIndex == draft.questions[parentHeaderIndex].children[parentCatIndex].children.length - 1) {
                                //move to cat below
                                draft.questions[parentHeaderIndex].children[parentCatIndex].children.splice(questionIndex, 1)
                                draft.questions[parentHeaderIndex].children[parentCatIndex + 1].children.unshift(draftObject);

                            }//Try move to first category from the next header 
                            else if (parentHeaderIndex != maxIndexHead && parentCatIndex == draft.questions[parentHeaderIndex].children.length - 1 && questionIndex == draft.questions[parentHeaderIndex].children[parentCatIndex].children.length - 1) {

                                if (draft.questions[parentHeaderIndex + 1].children.length > 0) {
                                    //Move to last cat from the new header
                                    draft.questions[parentHeaderIndex].children[parentCatIndex].children.splice(questionIndex, 1)
                                    draft.questions[parentHeaderIndex + 1].children[0].children.unshift(draftObject);
                                }
                            }
                            else {
                                swapElements(draft.questions[parentHeaderIndex].children[parentCatIndex].children, questionIndex + 1, questionIndex)
                            }

                            break;
                        case EvaluationQuestionLevel.CATEGORIE:

                            if (parentHeaderIndex == draft.questions.length - 1 && parentCatIndex == draft.questions[draft.questions.length - 1].children.length - 1) {
                                //already below
                            } else if (parentHeaderIndex != draft.questions.length - 1 && parentCatIndex == draft.questions[parentHeaderIndex].children.length - 1) {
                                draft.questions[parentHeaderIndex].children.splice(parentCatIndex, 1)
                                draft.questions[parentHeaderIndex + 1].children.unshift(draftObject);
                            } else if (parentCatIndex != draft.questions[parentHeaderIndex].children.length - 1) {
                                swapElements(draft.questions[parentHeaderIndex].children, parentCatIndex + 1, parentCatIndex)
                            }

                            break;
                        case EvaluationQuestionLevel.HEADING:
                            if (parentHeaderIndex != draft.questions.length - 1) {
                                swapElements(draft.questions, parentHeaderIndex + 1, parentHeaderIndex)
                            }
                            break;
                    }
                    break;
            }
        })
    }

    const renderHeading = (heading: CreateEvaluationQuestionFormModel, index: number) => {
        return <>
            <OverviewBlock color={heading.color} style={{ marginBottom: "10px" }}>
                <Grid container justifyContent={"space-between"}>
                    <Grid item xs={10} style={{ marginTop: "auto", marginBottom: "auto" }}>
                        <Typography
                            className="alignCenter"
                            variant="h4">
                            {heading.question}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: "end" }}>
                        <DcMenu options={generateOptions(heading)} onClick={(id) => { storeQuestionAction({ action: id, parentHeaderIndex: index, currentModel: heading }) }} />
                    </Grid>
                </Grid>
            </OverviewBlock>
            {heading.children.map((cat, catIndex) => {
                return renderCategory(cat, index, catIndex)
            })}
        </>
    }
    const renderCategory = (heading: CreateEvaluationQuestionFormModel, parentIndex: number, index: number) => {
        return <>
            <Grid container>
                <Grid item xs={10} style={{ marginTop: "auto", marginBottom: "auto" }}>
                    <Typography
                        style={{ paddingLeft: "2vw", marginBottom: "20px" }}
                        className="strike"
                        variant="body2">
                        <span>{heading.question}</span>

                    </Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "end" }}>
                    <DcMenu options={generateOptions(heading)} onClick={(id) => { storeQuestionAction({ action: id, parentHeaderIndex: parentIndex, parentCatIndex: index, currentModel: heading }) }} />
                </Grid>
            </Grid>
            {heading.children.map((question, questionIndex) => { return renderQuestion(question, parentIndex, index, questionIndex) })}
        </>
    }
    const renderQuestion = (heading: CreateEvaluationQuestionFormModel, parentIndex: number, catindex: number, index: number) => {
        return <>
            <Grid container>
                <Grid item xs={10} style={{ marginTop: "auto", marginBottom: "auto" }}>
                    <Typography
                        style={{ paddingLeft: "4vw", marginBottom: "20px" }}
                        variant="body2">
                        {heading.question}
                    </Typography>
                </Grid>
                <Grid xs={2} item style={{ textAlign: "end" }}>
                    <DcMenu options={generateOptions(heading)} onClick={(id) => { storeQuestionAction({ action: id, parentHeaderIndex: parentIndex, parentCatIndex: catindex, questionIndex: index, currentModel: heading }) }} />
                </Grid>
            </Grid>
        </>
    }
    const createNewQuestion = (level: EvaluationQuestionLevel, model?: IQuestionFormModel): CreateEvaluationQuestionFormModel => {

        return {
            question: model == undefined ? t("content.firstQuestionDefault") : model.text,
            order: 1,
            color: model == undefined ? null : model.color,
            level: level,
            children: [],
        };
    }
    const cancelQuestionCreation = () => {
        setOnQuestion(undefined);
    }
    const onExecuteQuestionCreation = (props: IQuestionFormModel) => {

        executeQuestionPickerAction(onQuestion, props);
        setOnQuestion(undefined);
    }
    const onSave = () => {
        setIsSaving(true);
        if (formModel.id == null) {
            _contentClient.insertEvaluationFiche(formModel)
                .takeAtLeast(timeoutQuery)
                .then((res) => {
                    loadNewTemplate(res.data);
                    searchEvaluationRecentFiches();
                }).finally(() => {
                    setIsSaving(false);
                })
        } else {
            _contentClient.updateEvaluationFiche(formModel)
                .then((res) => {
                    loadNewTemplate(formModel.id)
                }).finally(() => {
                    setIsSaving(false)
                })
        }

    }
    const saveBtnCalled = () => {
        if (formRef != null && formRef.current != null) {
            var testForm = formRef.current as any;
            testForm
                .isFormValid()
                .then((result: boolean) => {
                    if (result) {
                        setIsSaving(true);
                        onSave();
                    }
                })
                .catch((err: any) => { });
        }
    }
    const loadNewTemplate = (id: number) => {
        setTemplateLoading(true);
        _contentClient.getEvaluationFiche(id)
            .takeAtLeast(timeoutQuery * 2)
            .then((res) => {
                setFormModel(res.data);
            }).finally(() => {
                setTemplateLoading(false);
            })
    }
    const merge = (dto: EvaluationQuestionDto[]) => {
        dto.forEach((h) => { h.id = null; h.children.forEach((c) => { c.id = null; c.children.forEach((q) => { q.id = null }) }) })
        if (formModel != null) {
            setFormModel((draft) => {
                new List(draft.questions).AddRange(dto)
            })
        }
        setOpenMergeDialog(false);
    }
    const deleteCurrentEvFiche = () => {
        if (formModel?.id == null) return;

        setRemoving(true);
        _contentClient.removeEvaluationFiche({ id: formModel.id }).takeAtLeast(timeoutQuery).then((res) => {
            setFormModel(undefined);
            setOpenDeleteDialog(false);
            searchEvaluationRecentFiches();
            setRemovingError(undefined);
        }).catch((err) => {
            setRemovingError(t("general.removingFicheError"))
        }).finally(() => {
            setRemoving(false);
        })
    }
    return <>
        <GeneralDialog title={t("general.searchTemplate")} open={openSearchDialog}
            onClose={() => { setOpenSearchDialog(false) }}
            actions={<Grid item xs={12} container justifyContent={"flex-end"}>
                <Button onClick={() => { setOpenSearchDialog(false) }} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                    {t("general.cancel-btn")}
                </Button>
            </Grid>}>
            <EvaluationFicheList onRowClick={(el) => { loadNewTemplate(el.id); setOpenSearchDialog(false); }} ></EvaluationFicheList>
        </GeneralDialog>
        <GeneralDialog title={t("general.mergeTemplate")} open={openMergeDialog}
            onClose={() => { setOpenMergeDialog(false) }} >
            <MergeEvaluationFiche onCancel={() => { setOpenMergeDialog(false) }} onSave={(questions) => merge(questions)} />
        </GeneralDialog>
        <GeneralDialog title={t(`general.createEvQuestionTitle.${onQuestion?.action}`)} open={openNewQuestionDialog} onClose={() => { setOpenMergeDialog(false) }}>
            <NewQuestion onSaveAddNew={(model) => {
                var copy = { ...onQuestion };
                copy.questionIndex += 1;
                onExecuteQuestionCreation(model);
                setTimeout(() => {
                    setOnQuestion(copy);
                }, 500)

            }} forLevel={onQuestion?.action as any} onCancel={cancelQuestionCreation} onSave={onExecuteQuestionCreation} />
        </GeneralDialog>
        <GeneralDialog title={t("general.edit")} open={openEditQuestionDialog} onClose={() => { setOpenMergeDialog(false) }}>
            {onQuestion != null ? <><NewQuestion default={{ color: onQuestion.currentModel.color, text: onQuestion.currentModel.question }} forLevel={onQuestion.currentModel.level} onCancel={cancelQuestionCreation} onSave={onExecuteQuestionCreation} /></> : null}
        </GeneralDialog>
        <GeneralDialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
            <Grid container>
                <Grid item xs={12}>
                    <Alert severity="warning" >
                        <Typography
                            variant="body2">
                            {t("general.removeFicheWarning")}
                        </Typography>
                    </Alert>
                    {removingError ? <>
                        <Alert severity="error" >
                            <Typography
                                variant="body2">
                                {removingError}
                            </Typography>
                        </Alert>
                    </> : <></>}
                </Grid>
            </Grid>
            <Grid sx={{ mt: "4px" }} item xs={12} container justifyContent={"flex-end"}>
                <Button onClick={() => setOpenDeleteDialog(false)} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                    {t("general.cancel-btn")}
                </Button>
                <LoadButton color={"error"} startIcon={<DeleteIcon />} isLoading={removing}
                    type="submit"
                    onClick={() => { deleteCurrentEvFiche() }}
                    className="no-box-shadow"
                    style={{ textTransform: "capitalize", marginLeft: "10px" }}
                    buttonText={t("general.delete-btn")} />
            </Grid>

        </GeneralDialog>

        <Grid container justifyContent={"space-between"}>
            {__activeOrganisation?.id == 1?<>
                <Grid item xs={12}>
                    <Alert sx={{mb:"4px"}} severity="info"><Typography variant="body2">Sjablonen die op dit dossier aangemaakt worden zijn zichtbaar voor iedereen!</Typography></Alert>
                </Grid>
            </> : <></>}
            
            <Grid item>
                <Typography
                    color={(theme as any).palette.text.heading}
                    variant="h1">
                    {t("route.create-content")}
                </Typography>
            </Grid>
            <Grid item>
                {renderEditContent ? <>
                    <Button sx={{ mb: 2 }} startIcon={<MergeIcon />} onClick={() => {
                        setOpenMergeDialog(true)
                    }} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                        {t("general.mergeTemplate")}
                    </Button>
                </> : <> <>  <Button sx={{ mb: 2 }} startIcon={<AddIcon />} onClick={() => {
                    setFormModel((draft) => {
                        return {
                            name: "",
                            score: 3,
                            questions: [
                                createNewQuestion(EvaluationQuestionLevel.HEADING)
                            ],
                            organisation: organisation,
                        }
                    })
                }} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                    {t("general.createNewTemplate")}
                </Button></>
                    <>  <Button sx={{ mb: 2 }} startIcon={<SearchIcon />} onClick={() => {
                        setOpenSearchDialog(true)
                    }} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                        {t("general.searchTemplate")}
                    </Button></></>}

            </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: "8px" }}>

            {renderEditContent ? <></> : <>
                {templates.map((el) => {
                    return <>
                        <Grid item md={3} lg={2}>
                            {renderTemplateCard(el)}
                        </Grid>
                    </>
                })}
            </>
            }
        </Grid>
        {templateLoading ? <><Loader styles={{ textAlign: "center", width: "auto", marginTop: "2vh" }} /></> : null}
        {renderEditContent && !templateLoading ? <>
            <ValidatorForm ref={formRef} onSubmit={() => { }}>
                <Typography
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                    color={(theme as any).palette.text.heading}
                    variant="h3">
                    {formModel.id == null ? t("content.createContent") : `${t("content.updateContent")} ${formModel.name}`}
                </Typography>
                <Grid container>
                    <OverviewBlock>
                        <Grid container spacing={4}>
                            <Grid item md={2}>
                                <Typography
                                    variant="body2">
                                    {t("content.titleExplText")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextValidator onChange={(event) => { var copy = { ...formModel }; copy.name = event.target.value; setFormModel(copy); }}
                                    id="outlined-basic" value={formModel.name} className={"app-input-full-width"} label={t("content.labelTitle")} variant="outlined" size="small"
                                    validators={["required"]}
                                    errorMessages={[t("validation.required")]}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} sx={{ mt: 1 }}>
                            <Grid item md={2}>
                                <Typography
                                    variant="body2">
                                    {t("content.scoreExplText")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextValidator onChange={(event) => {
                                    var copy = { ...formModel };
                                    var score = Number.parseInt(event.target.value);
                                    if (!Number.isNaN(score) && score > 0) {
                                        copy.score = score
                                        setFormModel(copy)
                                    }

                                }}
                                    type="number"
                                    id="outlined-basic" value={formModel.score} className={"app-input-full-width"} label={t("content.labelScore")} variant="outlined" size="small"
                                    validators={["required"]}
                                    errorMessages={[t("validation.required")]}
                                />
                            </Grid>
                        </Grid>
                    </OverviewBlock>
                </Grid>
                <Typography
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                    color={(theme as any).palette.text.heading}
                    variant="h3">
                    {t("content.editQuestions")}
                </Typography>
                <Grid container sx={{ mt: 1 }}>
                    {renderFormModel()}
                </Grid>
                <Grid container justifyContent="space-between" sx={{ mt: "20px", mb: "20px" }}>
                    <Grid item>
                        {(formModel as any).updated != undefined ? <>
                            <Typography
                                className="alignCenter"
                                variant="body2" >
                                {t("general.lastUpdated")} {beautifyDate((formModel as any).updated)}
                            </Typography>
                        </> : <></>}
                    </Grid>
                    <Grid item xs={12} container justifyContent={"flex-end"}>
                        <Button sx={{ mr: 1 }} onClick={() => { setFormModel(undefined) }} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                            {t("general.cancel-btn")}
                        </Button>
                        <Button startIcon={<DeleteIcon />} sx={{ mr: 1 }} onClick={() => { setOpenDeleteDialog(true) }} variant="outlined" style={{ textTransform: "capitalize" }} color="error">
                            {t("general.delete-btn")}
                        </Button>
                        <LoadButton
                            startIcon={<Save />}
                            className="no-box-shadow full-width"
                            style={{ textTransform: "capitalize" }}
                            variant="contained"
                            type={"submit"}
                            color="primary"
                            buttonText={formModel.id == undefined ? t("general.create-btn") : t("general.update-btn")}
                            onClick={saveBtnCalled}
                            isLoading={isSaving}>
                        </LoadButton>
                    </Grid>

                </Grid>
            </ValidatorForm>
        </> : null}

    </>
}

