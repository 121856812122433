import { EmployeesRoute } from '../routing/routes';
import { Grid, Typography, useTheme, Chip } from '@mui/material';
import { useTeam } from '../hooks/useTeam';
import { useTranslation } from 'react-i18next';
import OverviewBlock from '../components/overviewBlock';
import { OverviewCard, OverviewCardLoading } from '../components/overviewCard';
import Pagination from '@mui/material/Pagination';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import React from 'react';
import { EmployeeDto, EvaluationDashboardModel, EvaluationDto, EvaluationFicheDto, PerformEvaluationDto, TaskDto } from '../clients/globis/models';
import { useInjection } from '../di/ioc-provider';
import { IEmployeeClient } from '../clients/globis/employeeClient';
import { TYPES } from '../di/types';
import Loader from '../components/Loader';
import Button from '@mui/material/Button';
import { IContentClient } from '../clients/globis/contentClient';
import { SortOrder, TableFilterState } from '../components/dcDataTable';
import { createChartData, createdByText, defaultFilter, getPageSkipTake, getSkip, timeoutQuery } from '../components/helpers';
import { ITaskClient } from '../clients/globis/taskClient';
import { useOrganisation } from '../hooks/useOrganisation';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Link from '@mui/material/Link';
import PersonIcon from "@mui/icons-material/Person";
import ContentIcon from "@mui/icons-material/ContentPaste"
import EditIcon from "@mui/icons-material/Edit"
import TaskIcon from "@mui/icons-material/QuestionAnswer"
import GeneralDialog from '../components/generalDialog';
import { UpdateEmployee } from '../forms/employees/updateEmployee';
import { GlobisRadarChart, RadarChartPlot, RadarChartProps, data } from '../components/GlobisRadarChart';
import { List } from 'linqts';
import { graphColorMapping } from '../components/taskTypeMapper';
type TParams = { id: string };

export interface IEmployeeProps extends RouteComponentProps<TParams> {

}

export const EmployeePage = (props: IEmployeeProps) => {
    const history = useHistory();
    const theme = useTheme();
    const [renderTeamPicker] = useTeam({selectFirstTeam:true});
    const [,organisationId] = useOrganisation();
    const [t] = useTranslation("common");
    const [model,setModel] = React.useState<EmployeeDto | undefined>(undefined);
    const _employeeClient = useInjection<IEmployeeClient>(TYPES.IEmployeeClient);
    const _contentClient = useInjection<IContentClient>(TYPES.IContentClient);
    const _taskClient = useInjection<ITaskClient>(TYPES.ITaskClient);
    const [openUpdateEmployee,setOpenUpdateEmployee] = React.useState(false);
    const [evaluationFilterState, setEvaluationFilterState] = React.useState<TableFilterState>({
        search: "",
        skip: 0,
        take: 4,
        sortOrder: SortOrder.DESC,
        sortColumn: "Updated"
    });
    const [actionFilterState, setActionFilterState] = React.useState<TableFilterState>({
        search: "",
        skip: 0,
        take: 4,
        sortOrder: SortOrder.DESC,
        sortColumn: "Updated"
    });
    const [evaluations, setEvaluations] = React.useState<EvaluationDto[]>([]);
    const [tasks,setTaskDto] = React.useState<TaskDto[]>([]);
    const [loading,setLoading] = React.useState(false);
    const [evaluationsLoading,setEvaluationsLoading] = React.useState(false);
    const [tasksLoading,setTasksLoading] = React.useState(false);
    const [entityCount, setEntityCount] = React.useState<number>(0);
    const [actionEntityCount,setActionEntityCount] = React.useState<number>(0);
    const [dashboardScores, setDashboardScores] = React.useState<EvaluationDashboardModel[]>([]);
    const [evaluationFiche, setEvaluationFiche] = React.useState<EvaluationFicheDto |undefined>(undefined);
    const id = React.useMemo(() => {
        return Number.parseInt((props as any).match.params.id)
    }, [(props as any).match.params.id]);


    React.useEffect(() => {
        fetchEmployee(id);
        fetchEvFiche();
    }, [id])
    React.useEffect(() => {
        if(model!=null){
            fetchEvaluations();
        }
    },[model,evaluationFilterState])
    React.useEffect(() => {
        if(model!=null){
            fetchActions();
        }
    },[model,actionFilterState])
    React.useEffect(() => {
        if(evaluations.length>0){
            _contentClient.getScoresFromEvaluations({evaluations : new List(evaluations).Select(w=>w.id).ToArray()}).then((res) => {
                setDashboardScores(res.data);
            })
        }
    },[evaluations])
    const employeeGraphData = React.useMemo(() => {
        return createChartData(evaluationFiche,dashboardScores);
    }, [evaluationFiche, dashboardScores]) as RadarChartProps | undefined
    const fetchEmployee = (id : number) => {
        _employeeClient.get(id).then((res) => {
            setModel(res.data);
        })
    }
    const fetchEvFiche = () => {
        _contentClient.getFicheByEmployee(id).then((res) => {
            setEvaluationFiche(res.data);
        })
    }
    const fetchEvaluations = () => {
        setEvaluationsLoading(true);
        _contentClient.fetchEvaluationsForEmployee(model.id,evaluationFilterState.skip,evaluationFilterState.take)
        .takeAtLeast(timeoutQuery)
        .then((res) => {
            setEvaluations(res.data.data);
            setEntityCount(res.data.entityCount);
        }).catch((err) => {

        }).finally(() => {
            setEvaluationsLoading(false);
        });
    }
    const fetchActions = () => {
        setTasksLoading(true);
        _taskClient.fetchTasks({skip : actionFilterState.skip,
            take : actionFilterState.take,
            sortColumn : actionFilterState.sortColumn,
            sortOrder : actionFilterState.sortOrder,
            payload : {employeeId : model.id,organisation : organisationId,search:""}
        }).takeAtLeast(timeoutQuery).then((res) => {
            setTaskDto(res.data.data);
            setActionEntityCount(res.data.entityCount);
        }).catch((err) => {

        }).finally(() => {
            setTasksLoading(false);
        })
    }
    if(loading || model == undefined){
        return <Loader/>
    }
    const onChangeActionPage = (page: number) => {
        var skip = getSkip(page - 1, actionFilterState.take);
        var copy = { ...actionFilterState };
        copy.skip = skip;
        setActionFilterState(copy);
    }
    const onChangeEvPage = (page: number) => {
        var skip = getSkip(page-1, evaluationFilterState.take);
        var copy = { ...evaluationFilterState };
        copy.skip = skip;
        setEvaluationFilterState(copy);
    }
    const renderStats = (ev : EvaluationDto) => {
        if(ev.scoreSummary!=undefined){
            return ev.scoreSummary.map((el,index) => {
                return <span title={el.question}>{el.score} {index < ev.scoreSummary.length - 1 ? '/' : ''}</span>
            })
        }
    }
   
    const renderEvaluationsLoading = () => {
        return <>
            <Grid item xs={12}>
                <OverviewCardLoading />
            </Grid>
            <Grid item xs={12}>
                <OverviewCardLoading />
            </Grid>
            <Grid item xs={12}>
                <OverviewCardLoading />
            </Grid>
            <Grid item xs={12}>
                <OverviewCardLoading />
            </Grid>
        </>
    }
    return <>
    {model!=undefined?<>
            <GeneralDialog onClose={() => { setOpenUpdateEmployee(false) }} title={t("general.updateEmployee")} open={openUpdateEmployee}>
                    <UpdateEmployee id={model.id} onSaved={() => { setOpenUpdateEmployee(false); }} onCancel={() => { setOpenUpdateEmployee(false) }} />
            </GeneralDialog></>:<></>}
      
        <Grid container justifyContent={"space-between"}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb" sx={{mb:2}}>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                        color="inherit"
                        onClick={() => { history.push("/") }}
                    >
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {t("route.dashboard")}
                    </Link>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center',cursor:"pointer" }}
                        color="inherit"
                        onClick={() => {history.push("/employees")}}
                    >
                        <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {t("route.employees")}
                    </Link>
                </Breadcrumbs>
            </Grid>
            <Grid item>
                <Typography
                    className="alignCenter"
                    color={(theme as any).palette.text.heading}
                    variant="h1">
                    {t("route.employee")} {model.firstName} {model.lastName} {model != undefined && model.archived ? <>
                        <Chip variant="outlined" label={t("general.archived-table")} color="warning" /></> : <></>}
                </Typography>
               
            </Grid>
            <Grid item>
                {model!=undefined? <>
                    <Button disabled={model.archived} sx={{mb : 2}} startIcon={<EditIcon />} onClick={() => {
                     setOpenUpdateEmployee(true);
                    }} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                        {t("general.edit")}
                    </Button>
                    <Button disabled={model.archived} sx={{ mb: 2 }} startIcon={<ContentIcon/>} onClick={() => {
                        history.replace(`/new-evaluation/${model.id}`)
                    }} variant="outlined" style={{ textTransform: "capitalize", marginLeft: "4px", }} color="primary">
                        {t("general.createEvaluation")}
                    </Button>
                    <Button disabled={model.archived} sx={{ mb: 2 }} startIcon={<TaskIcon/>} onClick={() => { history.replace(`/new-action/${model.id}`) }} variant="outlined" style={{ marginLeft: "4px", textTransform: "capitalize" }} color="primary">
                        {t("general.createTask")}
                    </Button>
                </> : <></>}
            </Grid>
        </Grid>
        <Typography
            style={{marginTop:"10px",marginBottom:"10px"}}
            className="alignCenter"
            color={(theme as any).palette.text.heading}
            variant="h3">
            {t("general.evaluations")}        </Typography>
        <Grid container spacing={2}>
            {evaluationsLoading ? <>{renderEvaluationsLoading()}</> : 
            <>
            
            {evaluations.map((el) => {
                return <>
                    <Grid item xs={12}>
                        <OverviewCard onClick={() => {
                            history.push(`/update-evaluation/${el.id}`)
                        }} clickable={true} aside={renderStats(el)} title={el.name} body={createdByText(t, el.created, el.createdByName)} />
                    </Grid>
                </>
            })}
            {evaluations.length==0?<>
                        <Grid item xs={12}><OverviewCard title={''} body={t("general.noEvaluationsForEmployee")} aside={undefined}></OverviewCard></Grid>
            </>:<></>}
            </>}
            
            <Grid item xs={12} >
                <Pagination
                    style={{ float: "right" }}
                    page={getPageSkipTake(evaluationFilterState.skip, evaluationFilterState.take)+1}
                    onChange={(event, page) => { onChangeEvPage(page) }}
                    count={Math.round((entityCount / evaluationFilterState.take))} color="primary" variant="outlined" shape="rounded" />
            </Grid>
        </Grid>
        <Typography
            style={{ marginTop: "10px", marginBottom: "10px" }}
            className="alignCenter"
            color={(theme as any).palette.text.heading}
            variant="h3">
            {t("general.actions")}
        </Typography>
        <Grid container spacing={2}>
            {tasksLoading?<>{renderEvaluationsLoading()}</>:<>
                {tasks.map((el) => {
                    return <>
                        <Grid item xs={12}>
                            <OverviewCard onClick={() => {
                                history.push(`/update-action/${el.id}`);
                            }} clickable={true} title={t(`taskType.${el.taskType}`)} body={createdByText(t, el.created, el.createdByName)} aside={undefined} />
                        </Grid>
                    </>
                })}
                {tasks.length==0? <>
                    <Grid item xs={12}><OverviewCard title={''} body={t("general.noTasksForEmployee")} aside={undefined}></OverviewCard></Grid></> : <></>}
                </>}
            
            <Grid item xs={12} >
                <Pagination
                    style={{ float: "right" }}
                    page={getPageSkipTake(actionFilterState.skip, actionFilterState.take) + 1}
                    onChange={(event, page) => { onChangeActionPage(page) }}
                    count={Math.round((actionEntityCount / actionFilterState.take)) } color="primary" variant="outlined" shape="rounded" />
             
            </Grid>
            <Grid item container justifyContent={"center"}>
                <Grid item md={4}>
                    {employeeGraphData != undefined ? <><GlobisRadarChart data={employeeGraphData} /></> : <></>}

                </Grid>
               
            </Grid>
        </Grid>
    </>
}