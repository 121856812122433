import { useTranslation } from "react-i18next"


export interface MultilineProps{
    text : string
}
export const MultilineText = (props : MultilineProps) => {
    const [t] = useTranslation("common");

    return <>
        {t(props.text).split('\n').map(item => <p>{item}</p>)}</>
}