
import React from "react"
import Loader from "./Loader";
import Button from '@mui/material/Button';


export interface IloadButton {
    buttonText: string
    onClick(event: any);
    isLoading: boolean,
    className?: string,
    startIcon?: any,
    endIcon?:any
    style?: any,
    type?: any,
    color?: any,
    disabled?:boolean
    children?: any,
    variant?: 'text' | 'outlined' | 'contained';
}

export const LoadButton = (props: IloadButton) => {

    const renderIcon = () => {
        if (props.isLoading) {
            return <React.Fragment>

                <Loader altClassName="small-spinner" />
            </React.Fragment>
        } else {
            return <React.Fragment>
                {props.endIcon}
            </React.Fragment>
        }

    }
    return <React.Fragment>
        <Button
            color={props.color ? props.color : "primary"}
            type={props.type}
            style={props.style}
            startIcon={props.startIcon}
            
            className={props.className}
            disabled={props.isLoading || props.disabled}
            endIcon={renderIcon()}
            onClick={(event) => props.onClick(event)}
            variant={props.variant ? props.variant : "contained"}>{props.buttonText}</Button>
    </React.Fragment>
}