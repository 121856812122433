import moment from "moment";
import { PaginationData, EvaluationFicheDto, EvaluationDashboardModel } from '../clients/globis/models';
import { SortOrder, TableFilterState } from "./dcDataTable";
import sffjs from "sffjs";
import { RadarChartPlot } from "./GlobisRadarChart";
import { graphColorMapping } from "./taskTypeMapper";
import { List } from "linqts";
export const rowsForTable = [10, 20, 50, 100, 200];
export const timeoutQuery = 500;
export const getEnumLabels = (enumeration: any): string[] => {
    const vals = Object.keys(enumeration).filter((item) => {
        return isNaN(Number(item));
    });
    return vals;
}

export const createChartTicks = (score : number )=> {
    return  {
        scales: {
            r: {
                min: 0,
                max: score,
                beginAtZero: true,

            }
        }
    }
}
export const createChartData = (evaluationFiche: EvaluationFicheDto, dashboardScores : EvaluationDashboardModel[]) => {
    if (evaluationFiche != undefined && dashboardScores.length > 0) {
        var labels = [];
        var plots = [] as RadarChartPlot[];
        dashboardScores.forEach((el, index) => {
            var config = graphColorMapping.length - 1 > index ? graphColorMapping[index] : { border: graphColorMapping[0].border, background: graphColorMapping[0].background };
            plots.push({ label: el.evaluation.name, data: [], backgroundColor: config.background, borderColor: config.border, borderWidth: 1 });
        })
        for (var i = 0; i < evaluationFiche.questions.length; i++) {
            for (var j = 0; j < evaluationFiche.questions[i].children.length; j++) {
                var cat = evaluationFiche.questions[i].children[j];
                labels.push(cat.question);

                for (var k = 0; k < dashboardScores.length; k++) {
                    var plot = plots[k];
                    var score = new List(dashboardScores[k].scores).FirstOrDefault(w => w.ogQuestionId == cat.id);
                    if (score != null) {
                        plot.data.push(score.score);
                    } else {
                        plot.data.push(0);
                    }

                }
            }
        }
        return { labels: labels, datasets: plots, options: createChartTicks(evaluationFiche.score)}
    }
    return undefined;
}
export const ellipsis = (txt : string,length : number) => {
    if(txt == null) return "";

    if(txt.length>=length){
        return `${txt.substring(0,length-1)} ...`
    }else{
        return txt;
    }
}
export const beautifyDate = (date?: Date) => {
    if (date == null || date == undefined) return null;

    return moment(date).format("DD/MM/yyyy HH:mm")
}
export const swapElements = (array, index1, index2) => {
    [array[index1], array[index2]] = [array[index2], array[index1]];
};
export const beautifyDateWoTime = (date?: Date) => {
    if (date == null || date == undefined) return null;

    return moment(date).format("DD/MM/yyyy")
}
export const getPageSkipTake = (skip: number, take: number): number => {
    return getPagination(skip, take);
}
export const getPage = (paginationData: PaginationData): number => {

    return getPagination(paginationData.skip, paginationData.take);
}
export const getPagination = (skip: number, step: number): number => {

    if (skip == 0) return 0;

    return (skip / step);
}
export const getSkip = (page: number, rowsPerPage: number): number => {
    if (page == 0) return 0;

    return rowsPerPage * (page);
}
export const sortOnFilter = (filter: TableFilterState, colClicked: string) => {
    var copy = { ...filter };
    copy.sortColumn = colClicked;
    if (copy.sortOrder == null) {
        copy.sortOrder = SortOrder.DESC;
    } else {
        if (copy.sortOrder == SortOrder.ASC) {
            copy.sortOrder = SortOrder.DESC;
        } else {
            copy.sortOrder = SortOrder.ASC
        }
    }
    return copy;
}
export const defaultFilter = {
    search: "",
    skip: 0,
    take: 20,
    sortOrder: SortOrder.DESC,
    sortColumn: "Updated"
} as TableFilterState;

export const parseAxiosError = (err  : any) => {
    var error = err.response.data.TranslationKey;
    if(error == null) return err.response.data.Message;
    else return error;
}
export const lastUpdated = (t: any, created: Date, createdBy?: string) => {
    return sffjs(t("general.updatedBy"), beautifyDateWoTime(created), createdBy);
}
export const createdByText = (t: any,created : Date,createdBy?:string) => {
   return sffjs(t("general.createdText"), beautifyDateWoTime(created),createdBy); 
}

export function shadeColor(color, percent) {

    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent) / 100) as any);
    G = parseInt(G * (100 + percent) / 100 as any);
    B = parseInt(B * (100 + percent) / 100 as any);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    R = Math.round(R)
    G = Math.round(G)
    B = Math.round(B)

    var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

    return "#" + RR + GG + BB;
}

export const firework1 = {
    fpsLimit: 60,
    particles: {
        number: {
            value: 0
        },
        color: {
            value: "#ffffff"
        },
        shape: {
            type: ["circle", "square", "polygon"],
            options: {
                polygon: {
                    sides: 6
                }
            }
        },
        opacity: {
            value: { min: 0, max: 1 },
            animation: {
                enable: true,
                speed: 1,
                startValue: "max",
                destroy: "min"
            }
        },
        size: {
            value: { min: 2, max: 5 }
        },
        life: {
            duration: {
                sync: true,
                value: 7
            },
            count: 1
        },
        move: {
            enable: true,
            gravity: {
                enable: true
            },
            drift: {
                min: -2,
                max: 2
            },
            speed: { min: 10, max: 30 },
            decay: 0.1,
            direction: "none",
            random: false,
            straight: false,
            outModes: {
                default: "destroy",
                top: "none"
            }
        },
        rotate: {
            value: {
                min: 0,
                max: 360
            },
            direction: "random",
            move: true,
            animation: {
                enable: true,
                speed: 60
            }
        },
        tilt: {
            direction: "random",
            enable: true,
            move: true,
            value: {
                min: 0,
                max: 360
            },
            animation: {
                enable: true,
                speed: 60
            }
        },
        roll: {
            darken: {
                enable: true,
                value: 25
            },
            enable: true,
            speed: {
                min: 15,
                max: 25
            }
        },
        wobble: {
            distance: 30,
            enable: true,
            move: true,
            speed: {
                min: -15,
                max: 15
            }
        }
    },
    detectRetina: true,
    emitters: {
        direction: "none",
        spawnColor: {
            value: ["#8D75FF", "#F053BB", "#1B87EC", "#A98FFF"],
            animation: {
                h: {
                    enable: true,
                    offset: {
                        min: -1.4,
                        max: 1.4
                    },
                    speed: 0.1,
                    sync: false
                },
                l: {
                    enable: true,
                    offset: {
                        min: 20,
                        max: 80
                    },
                    speed: 0,
                    sync: false
                }
            }
        },
        life: {
            count: 6,
            duration: 0.2,
            delay: 0.2
        },
        rate: {
            delay: 0.1,
            quantity: 100
        },
        size: {
            width: 0,
            height: 0
        }
    }
}