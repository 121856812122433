import React from "react";
import { IContentClient } from "../clients/globis/contentClient";
import { useInjection } from "../di/ioc-provider";
import { TYPES } from "../di/types";
import { useOrganisation } from "../hooks/useOrganisation";
import { EvaluationFicheListDto } from "../clients/globis/models";
import { SortOrder } from "./dcDataTable";
import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { useTranslation } from "react-i18next";
import MenuItem from '@mui/material/MenuItem';


export interface EvDropdownProps{
    defaultEvFicheId? : number
    onChange : (id : number) => void;
}

export const EvDropdown = (props : EvDropdownProps) => {
    const _contentClient = useInjection<IContentClient>(TYPES.IContentClient);
    const [, organisationId] = useOrganisation();
    const [fiches, setFiches] = React.useState<EvaluationFicheListDto[]>([]);
    const [t] = useTranslation("common");
    const [evFiche,setEvFiche] = React.useState<number |undefined>(props.defaultEvFicheId);
    const [loading,setLoading] = React.useState(false);
    React.useEffect(() => {
        setLoading(true);
        _contentClient.searchEvaluationFiche({
            skip: 0,
            take: 1000,
            sortColumn: "Name",
            sortOrder: SortOrder.ASC,
            payload: { organisation: organisationId, search: "", team: null, includeForeignFiches: false }
        }).then((res) => {
            setFiches(res.data.data);
        }).finally(() => {
            setLoading(false);
        })
    },[])
    return <>
        <Grid item xs={12}>
            <FormControl fullWidth>
                <InputLabel>{t("content.choiceFiche")}</InputLabel>
                <Select
                    label={t("content.choiceFiche")}
                    disabled={loading}
                    onChange={(ev) => {
                        setEvFiche(ev.target.value as number);
                        props.onChange(ev.target.value as number)
                    }}
                    value={evFiche}
                >
                    {fiches.map((el) => {
                        return <MenuItem value={el.id}>{el.name}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Grid>
    </>
}