import { useRef } from "react";
import { CreateOrganisationDto, InsertTaskDto, TaskType, EmployeeDto } from '../clients/globis/models';
import React from "react";
import { Grid, Typography, useTheme } from '@mui/material';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { LoadButton } from "../components/loadButton";
import { useInjection } from '../di/ioc-provider';
import { IOrganisationClient } from '../clients/globis/organisationClient';
import { TYPES } from "../di/types";
import Alert from '@mui/material/Alert';
import { parseAxiosError, timeoutQuery } from '../components/helpers';
import { ITaskClient } from '../clients/globis/taskClient';
import { IEmployeeClient } from '../clients/globis/employeeClient';
import { useOrganisation } from '../hooks/useOrganisation';
import { CustomSelectList } from "../components/customSelectList";
import FormControl from '@mui/material/FormControl';
import { List } from "linqts";
import { useHistory } from 'react-router-dom';
import Loader from "../components/Loader";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import Add from "@mui/icons-material/AddCircle"
import WandIcon from "@mui/icons-material/AutoFixHigh"
import GeneralDialog from "../components/generalDialog";
import { WorkTaskPicker } from "../components/WorkTaskPicker";
import SearchIcon from '@mui/icons-material/Search';
import ExpandMore from '@mui/icons-material/ExpandMore';
export interface CreateTaskFormProps {
    employee : number;
}

export const CreateTaskForm = (props: CreateTaskFormProps) => {
    const employee = (props as any).match.params.id;
    const theme = useTheme();
    const formRef = useRef(null);
    const [err, setErr] = React.useState("");
    const [formModel, setModel] = React.useState<InsertTaskDto>({
        text:"",
        taskType: TaskType.TalkInOffice,
        employee: employee
    });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [t] = useTranslation("common");
    const _employeeClient = useInjection<IEmployeeClient>(TYPES.IEmployeeClient);
    const _taskClient = useInjection<ITaskClient>(TYPES.ITaskClient);
    const [employeeDto,setEmployee] = React.useState<EmployeeDto | undefined>(undefined);
    const [,organisationId] = useOrganisation();
    const [success,setSuccess] = React.useState(false);
    const [suggestions,setSuggestions] = React.useState(false);
    const history = useHistory();
    React.useEffect(() => {
        fetchEmployee();
    }, [organisationId])
    const fetchEmployee = () => {
        _employeeClient.get(employee).then((res) => {
            setEmployee(res.data);
        })
    }
    const handleFormChanged = (col: string, value: string) => {
        var copy = { ...formModel };
        copy[col] = value;
        setModel(copy);
    };
    const saveBtnCalled = () => {
        setLoading(true);
        _taskClient.insertTask(formModel)
        .takeAtLeast(timeoutQuery)
        .then((res) => {
            setSuccess(true);
            setTimeout(() => {
                history.push("/employee/" + employee);
            },timeoutQuery*2)
         
        }).catch((err) => {
            setErr(parseAxiosError(err));
        }).finally(() => {
            setLoading(false)
        })
      
    }
    const saveValidate = () => {
        if (formRef != null && formRef.current != null) {
            var testForm = formRef.current as any;
            testForm
                .isFormValid()
                .then((result: boolean) => {
                    if (result) {
                        saveBtnCalled();
                    }
                })
                .catch((err: any) => { });
        }
    };

    const renderPersonName = () => {
        if (employeeDto == undefined) return "";
        else {
            return `${employeeDto.firstName} ${employeeDto.lastName}`;
        }
    }
    if (formModel == null || employee == null) return <Loader></Loader>
    return <>
        {err != "" ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="error">{t(err)}</Alert> : null}
        {success ? <><Alert sx={{ mt: "4px", mb: "4px" }} severity="success">{t("general.createTaskSuccess")}</Alert></> : <></>}
        <GeneralDialog open={suggestions} onClose={() => {}}>
            <WorkTaskPicker onSelected={(el) => {
                var model = {...formModel};
                model.text = el;
                setModel(model);
                setSuggestions(false)
            }} onCancel={() => {setSuggestions(undefined)}}/>
        </GeneralDialog>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                    color="inherit"
                    onClick={() => { history.push("/") }}
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {t("route.dashboard")}
                </Link>
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                    color="inherit"
                    onClick={() => { history.push("/employees") }}
                >
                    <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {t("route.employees")}
                </Link>

                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                    color="inherit"
                    onClick={() => { history.push(`/employee/${employee}`) }}
                >
                    <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {renderPersonName()}
                </Link>
            </Breadcrumbs>
       
        <Typography
            className="alignCenter"
            color={(theme as any).palette.text.heading}
            variant="h1" style={{ marginBottom: "20px" }}>
            {t("general.createTaskFor")} {renderPersonName()}
        </Typography>
        <ValidatorForm ref={formRef} onSubmit={() => { }}>
            <Grid container spacing={2}>
                <Grid item xs={1}>
                        <span>{t("task.type")}</span>
                </Grid>
                <Grid item xs={11}>
                    <CustomSelectList 
                        startIcon={<ExpandMore/>}
                    defaultText={t("taskType.0")}
                    items={new List(Object.keys(TaskType)).Where(w=>!isNaN(Number(w))).ToArray().map((el) => { return { id: Number.parseInt(el), val: t(`taskType.${el}`)}})}
                    onItemClicked={(item) => { 
                        var copy = {...formModel};
                        copy.taskType = item.id;
                        
                        setModel(copy);
                    }} />
                </Grid>
                {formModel.taskType == TaskType.OfficeTask ? <>
                    <Grid item xs={1}>
                        <span>{t("task.needSuggestion")}</span>
                    </Grid>
                    <Grid item xs={11}>
                        <Button startIcon={<SearchIcon />} onClick={() => { setSuggestions(true);}} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                            {t("general.workTaskInspirationBtn")}
                        </Button>
                        {/* <TextValidator style={{ backgroundColor: "white" }} disabled={loading} minRows={4} multiline onChange={(event) => { handleFormChanged("text", event.target.value) }}
                            id="outlined-basic" value={formModel.text} className={"app-input-full-width"} variant="outlined" size="small"
                            validators={["required"]}
                            errorMessages={[t("validation.required")]}
                        /> */}
                    </Grid>

                </> : <></>}
                <Grid item xs={1}>
                    <span>{t("task.description")}</span>
                </Grid>
                <Grid item xs={11}>
                    <TextValidator style={{ backgroundColor: "white" }} disabled={loading} minRows={4} multiline onChange={(event) => { handleFormChanged("text", event.target.value) }}
                        id="outlined-basic" value={formModel.text} className={"app-input-full-width"} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
               
                <Grid item xs={12} container justifyContent={"flex-end"}>
                    <Button onClick={() => { history.push(`/employee/${employee}`) }} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                        {t("general.cancel-btn")}
                    </Button>
                    <LoadButton isLoading={loading} disabled={employee.archived}
                        startIcon={<Add/>}
                        type="submit"
                        onClick={saveValidate}
                        className="no-box-shadow"
                        style={{ textTransform: "capitalize", marginLeft: "10px" }}
                        buttonText={t("general.create-btn")} />

                </Grid>
            </Grid>
        </ValidatorForm>

    </>
}