import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DcDataTable, { DcTableHeader, SortOrder, TableFilterState } from '../components/dcDataTable';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import OverviewBlock from '../components/overviewBlock';
import Avatar from '@mui/material/Avatar';
import TablePagination from '@mui/material/TablePagination';
import { beautifyDate, defaultFilter, getPageSkipTake, getSkip, rowsForTable, sortOnFilter } from '../components/helpers';
import { useInjection } from '../di/ioc-provider';
import { IUserApiClient } from '../clients/globis/userApiClient';
import { TYPES } from '../di/types';
import { UserListDto } from '../clients/globis/models';
import { useOrganisation } from '../hooks/useOrganisation';
import Button from '@mui/material/Button';
import Add from "@mui/icons-material/AddCircle"
import GeneralDialog from '../components/generalDialog';
import { CreateOrganisationForm } from '../forms/organisation/createOrganisationForm';
import { CreateUserForm } from '../forms/users/createUser';
import { UpdateUserForm } from '../forms/users/updateUser';
export interface IUsersPage {


}

export const UsersPage = (props: IUsersPage) => {

    const theme = useTheme();
    const [t] = useTranslation("common");
    const [loading, setLoading] = React.useState(false);
    const [filterState, setFilter] = React.useState<TableFilterState>(defaultFilter);
    const [entityCount, setEntityCount] = React.useState<number>(0);
    const _userClient = useInjection<IUserApiClient>(TYPES.IUserClient);
    const [users, setUsers] = React.useState<UserListDto[]>([]);
    const [openCreateUserDialog, setOpenCreateUserDialog] = React.useState(false);
    const [userToUpdate,setUserToUpdate] = React.useState<number | undefined>(undefined);
    const [, organisationId] = useOrganisation();
    const headers = [
        { id: "LastName", name: t("employee.name") },
        { id: "UserName", name: t("general.email") },
        { id: "Updated", name: t("general.updated") },
        { id: "CreatedByName", name: t("general.createdBy") },
        { id: "Role", name: t("user.role") },
        { id: "Archived", name: t("general.archived-table") }
    ] as DcTableHeader[]
    function stringToColor(string: string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }
    function stringAvatar(name: string) {
        return {
            sx: {
                bgcolor: stringToColor(name),
                color: "white"
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
    React.useEffect(() => {
        if (organisationId != null) {
            fetchData();
        }

    }, [filterState, organisationId])
    React.useEffect(() => {

    }, [organisationId])
    const onChangePage = (page: number) => {
        var skip = getSkip(page, filterState.take);
        var copy = { ...filterState };
        copy.skip = skip;
        setFilter(copy);
    }
    const fetchData = () => {
        setLoading(true);
        _userClient.fetchUsers(
            {
                skip: filterState.skip,
                take: filterState.take,
                sortColumn: filterState.sortColumn,
                sortOrder: filterState.sortOrder,
                payload: {
                    organisation: organisationId,
                    search: ""
                }
            }
        )
            .then((res) => {
                setEntityCount(res.data.entityCount);
                setUsers(res.data.data);
            })
    }
    return <>
    {userToUpdate!=undefined ? <>
            <GeneralDialog onClose={() => { setUserToUpdate(undefined) }} title={t("general.updateUser")} open={userToUpdate!=undefined}>
                <>
                    <UpdateUserForm id={userToUpdate} onSaved={() => {
                        setFilter({ ...defaultFilter });
                        setUserToUpdate(undefined);
                    }} onCancel={() => { setUserToUpdate(undefined) }}></UpdateUserForm>
                </>
            </GeneralDialog>
    </>:<></>}
        <GeneralDialog onClose={() => { setOpenCreateUserDialog(false) }} title={t("general.createUser")} open={openCreateUserDialog}>
            <>
                <CreateUserForm onSaved={() => {
                    setFilter({ ...defaultFilter });
                    setOpenCreateUserDialog(false);
                }} onCancel={() => { setOpenCreateUserDialog(false) }}></CreateUserForm>
            </>
        </GeneralDialog>
        <Grid container justifyContent={"space-between"}>
            <Grid item>
                <Typography
                    className="alignCenter"
                    color={(theme as any).palette.text.heading}
                    variant="h1">
                    {t("route.users")}
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    startIcon={<Add/>}
                    className="no-box-shadow full-width"
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                    onClick={(e) => { setOpenCreateUserDialog(true) }}
                    color="primary">
                    
                    {t("general.createUser")}
                </Button>
            </Grid>
        </Grid>
        <Grid container sx={{ mt: "8px" }}>
            <Grid item xs={12}>
                <OverviewBlock style={{ display: "table" }}>
                    <DcDataTable
                        headers={headers}
                        onFilterClicked={(el) => { setFilter(sortOnFilter(filterState, el)) }}
                        filters={filterState}>
                        {users.map((el) => {

                            return <React.Fragment>
                                <TableRow key={el.id} hover onClick={() => {setUserToUpdate(el.id) }}>
                                    {/* <TableCell><Avatar {...stringAvatar(el.test)} /></TableCell> */}
                                    <TableCell>{el.firstName} {el.lastName}</TableCell>
                                    <TableCell>{el.userName}</TableCell>
                                    <TableCell>{beautifyDate(el.updated)}</TableCell>
                                    <TableCell>{el.createdByName}</TableCell>
                                    <TableCell>{t(`role.${el.role}`)}</TableCell>
                                    <TableCell>{el.archived ? t("general.yes") : t("general.no")}</TableCell>
                                </TableRow>
                            </React.Fragment>
                        })}
                    </DcDataTable>
                    <TablePagination
                        labelRowsPerPage={t("general.rowsPerPage")}
                        style={{ marginLeft: "auto" }}
                        rowsPerPageOptions={rowsForTable}
                        component="div"
                        count={entityCount}
                        rowsPerPage={filterState.take}
                        page={getPageSkipTake(filterState.skip, filterState.take)}
                        onPageChange={(event, page) => { onChangePage(page) }}
                        onRowsPerPageChange={(event) => {
                            var copy = { ...filterState };
                            copy.take = Number.parseInt(event.target.value);
                            setFilter(copy);
                        }}
                    />
                </OverviewBlock>

            </Grid>
        </Grid>
    </>
}