import DcDataTable, { DcTableHeader, SortOrder, TableFilterState } from "../components/dcDataTable"
import { Grid, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import OverviewBlock from "../components/overviewBlock";
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import React from "react";
import { useInjection } from '../di/ioc-provider';
import { IOrganisationClient } from '../clients/globis/organisationClient';
import { TYPES } from "../di/types";
import { OrganisationListDto } from '../clients/globis/models';
import TableCell from '@mui/material/TableCell';
import { beautifyDate, defaultFilter, getPageSkipTake, getSkip, rowsForTable, sortOnFilter } from '../components/helpers';
import Button from '@mui/material/Button';
import GeneralDialog from "../components/generalDialog";
import { CreateOrganisationForm } from "../forms/organisation/createOrganisationForm";
import { UpdateOrganisationForm } from "../forms/organisation/updateOrganisation";
import Add from "@mui/icons-material/AddCircle"
import ExportIcon from "@mui/icons-material/GetApp"
import SearchField from "../components/searchField";
import { AuthStore } from '../stores/AuthStore';
import { Roles } from "../config/roles";
import { Export } from "../forms/content/export";

export interface IDepartmentPageProps { }


export const OrganisationsPage = (props: IDepartmentPageProps) => {

    const theme = useTheme();
    const [t] = useTranslation("common");
    const [openOrganisation, setOpenOrganisation] = React.useState(false);
    const [openExport,setOpenExport] = React.useState(false);
    const headers = [
        { id: "Name", name: "name" },
        { id: "Updated", name: "Laatst gewijzigd" },
        { id: "CreatedByName", name: "Aangemaakt door" }
    ] as DcTableHeader[]
    const [loading, setLoading] = React.useState(false);
    const _organisationClient = useInjection<IOrganisationClient>(TYPES.IOrganisationClient);
    const [onUpdateDepartmentId,setUpdateDepartmentId] = React.useState<number | undefined>(undefined);
    const [filterState, setFilter] = React.useState<TableFilterState>(defaultFilter);
    const [orgs, setOrgs] = React.useState<OrganisationListDto[]>([]);
    const [entityCount, setEntityCount] = React.useState<number>(0);
    const authStore = AuthStore.useState(w=>w.authSettings);
    const fetchData = () => {
        setLoading(true);
        _organisationClient.fetchOrganisations(filterState)
            .then((res) => {
                setEntityCount(res.data.entityCount);
                setOrgs(res.data.data);
            })
    }
    React.useEffect(() => {
        fetchData();
    }, [filterState])
    const onChangePage = (page: number) => {
        var skip = getSkip(page, filterState.take);
        var copy = { ...filterState };
        copy.skip = skip;
        setFilter(copy);
    }
    return <>
    {onUpdateDepartmentId !=undefined ? <>
            <GeneralDialog onClose={() => { setUpdateDepartmentId(undefined) }} title={t("general.updateOrganisation")} open={onUpdateDepartmentId != undefined}>
                <>
                    <UpdateOrganisationForm id={onUpdateDepartmentId} onSaved={() => {
                        setUpdateDepartmentId(undefined);
                        setFilter({...defaultFilter});
                    }} onCancel={() => { setUpdateDepartmentId(undefined) }}></UpdateOrganisationForm>
                </>
            </GeneralDialog></> : null}
    
        <GeneralDialog onClose={() => { setOpenOrganisation(false)}} title={t("general.createOrganisation")} open={openOrganisation}>
            <>
                <CreateOrganisationForm onSaved={() => {
                    setFilter({...defaultFilter});
                    setOpenOrganisation(false)
                }} onCancel={() => {setOpenOrganisation(false)}}></CreateOrganisationForm>
            </>
        </GeneralDialog>
        {authStore.role == Roles.Admin ? <>  <GeneralDialog onClose={() => { setOpenExport(true) }} title={t("general.export")} open={openExport}>
            <>
                <Export onCancel={() => setOpenExport(false)} onSave={() => {setOpenExport(false)}} />
            </>
        </GeneralDialog></> : <></>}
      
        <Grid container justifyContent={"space-between"}>
            <Grid item xs={4}>
                <Typography
                    className="alignCenter"
                    color={(theme as any).palette.text.heading}
                    variant="h1">
                    {t("route.departments")}
                </Typography>
            </Grid>
          
            <Grid item container xs={8} spacing={1} justifyContent={"flex-end"}>
                {authStore.role == Roles.Admin ? <><Grid item>
                    <Button startIcon={<ExportIcon />}
                        className="no-box-shadow full-width"
                        style={{ textTransform: "capitalize" }}
                        variant="contained"
                        onClick={(e) => { setOpenExport(true) }}
                        color="primary">
                        {t("general.exportApp")}

                    </Button>
                </Grid></> : <></>}
                
                <Grid item>
                    <Button
                        startIcon={<Add />}
                        className="no-box-shadow full-width"
                        style={{ textTransform: "capitalize" }}
                        variant="contained"
                        onClick={(e) => { setOpenOrganisation(true) }}
                        color="primary">
                        {t("general.createOrganisation")}
                    </Button>
                </Grid>
               
            </Grid>
        </Grid>
        <Grid container sx={{ mt: "8px" }}>
            <Grid item xs={12}>
                <OverviewBlock style={{ display: "table" }}>
                    <DcDataTable
                        headers={headers}
                        onFilterClicked={(el) => { setFilter(sortOnFilter(filterState, el)) }}
                        filters={filterState}>
                        {orgs.map((el) => {
                            return <>
                                <TableRow key={el.id} hover onClick={() => { setUpdateDepartmentId(el.id)}}>
                                    <TableCell>{el.name}</TableCell>
                                    <TableCell>{beautifyDate(el.updated)}</TableCell>
                                    <TableCell>{el.createdByName}</TableCell>
                                </TableRow>
                            </>
                        })}
                    </DcDataTable>
                    <TablePagination
                        labelRowsPerPage={t("general.rowsPerPage")}
                        style={{ marginLeft: "auto", color: "black!important" }}
                        rowsPerPageOptions={rowsForTable}
                        component="div"
                        count={entityCount}
                        rowsPerPage={filterState.take}
                        page={getPageSkipTake(filterState.skip, filterState.take)}
                        onPageChange={(event, page) => { onChangePage(page) }}
                        onRowsPerPageChange={(event) => {
                            var copy = { ...filterState };
                            copy.take = Number.parseInt(event.target.value);
                            setFilter(copy);
                        }}
                    />
                </OverviewBlock>

            </Grid>
        </Grid>
    </>
}

export interface IDepartmentListProps{
    onRowClick?: (record: OrganisationListDto) => void
}
export const DepartmentList = (props : IDepartmentListProps) => {
    const [t] = useTranslation("common");
    const headers = [
        { id: "Name", name: "name" },
        { id: "Updated", name: "Laatst gewijzigd" },
        { id: "CreatedByName", name: "Aangemaakt door" }
    ] as DcTableHeader[]
    const [loading, setLoading] = React.useState(false);
    const _organisationClient = useInjection<IOrganisationClient>(TYPES.IOrganisationClient);
    const [openOrganisation, setOpenOrganisation] = React.useState(false);

    const [filterState, setFilter] = React.useState<TableFilterState>(defaultFilter);
    const [orgs, setOrgs] = React.useState<OrganisationListDto[]>([]);
    const [entityCount, setEntityCount] = React.useState<number>(0);

    const fetchData = () => {
        setLoading(true);
        _organisationClient.fetchOrganisations({
            skip : filterState.skip,
            take : filterState.take,
            sortColumn : filterState.sortColumn,
            sortOrder : filterState.sortOrder,
            payload : {search : filterState.search}
        })
            .then((res) => {
                setEntityCount(res.data.entityCount);
                setOrgs(res.data.data);
            })
    }
    React.useEffect(() => {
        fetchData();
    }, [filterState])
    const onChangePage = (page: number) => {
        var skip = getSkip(page, filterState.take);
        var copy = { ...filterState };
        copy.skip = skip;
        setFilter(copy);
    }
    const onRowClicked = (list: OrganisationListDto) => {
        if(props.onRowClick!=undefined){
            props.onRowClick(list);
        }
    }
    return <>
        <SearchField 
        onChange={() => {}} 
        id="search-departments" 
        debouncedWaitTime={500} 
        label={t("general.search")} 
        onDebouncedSearch={(val) => {
            var copy = {...filterState};
            copy.search = val;
            setFilter(copy);
        }}/>
        <DcDataTable
            headers={headers}
            onFilterClicked={(el) => { setFilter(sortOnFilter(filterState, el)) }}
            filters={filterState}>
            {orgs.map((el) => {
                return <>
                    <TableRow className="clickable" key={el.id} hover onClick={() => { onRowClicked(el)}}>
                        <TableCell>{el.name}</TableCell>
                        <TableCell>{beautifyDate(el.updated)}</TableCell>
                        <TableCell>{el.createdByName}</TableCell>
                    </TableRow>
                </>
            })}
        </DcDataTable>
        <TablePagination
            style={{ marginLeft: "auto", color: "black!important" }}
            rowsPerPageOptions={rowsForTable}
            component="div"
            count={entityCount}
            rowsPerPage={filterState.take}
            page={getPageSkipTake(filterState.skip, filterState.take)}
            onPageChange={(event, page) => { onChangePage(page) }}
            onRowsPerPageChange={(event) => {
                var copy = { ...filterState };
                copy.take = Number.parseInt(event.target.value);
                setFilter(copy);
            }}
        />
    
    </>
}