import * as React from "react";

interface Loader {
    styles?: Object,
    altClassName?: string
}

const Loader = (props: Loader) => {
    return (
        <React.Fragment>
            <div className={props.altClassName ? `${props.altClassName} spinner` : "spinner"} style={props.styles}>
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div>
            </div>
        </React.Fragment>
    );
};

export default Loader;

export interface PreLoaderProps extends Loader {
    loading: boolean,
    children: any,
}
export const PreLoader = (props: PreLoaderProps) => {

    return props.loading ? <Loader {...props}

    ></Loader> :
        <React.Fragment>
            {props.children}
        </React.Fragment>
}