import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { CreateTeamDto, CreateUserDto, CreateEmployeeDto, UpdateEmployeeDto } from '../../clients/globis/models';
import { useInjection } from '../../di/ioc-provider';
import { ITeamsClient } from '../../clients/globis/teamsClient';
import { TYPES } from "../../di/types";
import { parseAxiosError, timeoutQuery } from "../../components/helpers";
import Alert from '@mui/material/Alert';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { LoadButton } from "../../components/loadButton";
import { useOrganisation } from "../../hooks/useOrganisation";
import { useTeam } from "../../hooks/useTeam";
import { IEmployeeClient } from '../../clients/globis/employeeClient';
import { useImmer } from "use-immer";
import Add from "@mui/icons-material/AddCircle"
import { TeamPicker } from "../../components/teamPicker";
import Loader from "../../components/Loader";
import Save from "@mui/icons-material/Save"
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
export interface updateEmployeeProps {
    onCancel: () => void;
    onSaved: () => void;
    id: number,
}


export const UpdateEmployee = (props: updateEmployeeProps) => {
    const formRef = useRef(null);
    const [err, setErr] = React.useState("");
    const [t] = useTranslation("common");
    const [, organisationId] = useOrganisation();
    const _employeeClient = useInjection<IEmployeeClient>(TYPES.IEmployeeClient);
    const [renderTeam, teamId] = useTeam({ selectFirstTeam: true });
    const [formModel, setModel] = useImmer<UpdateEmployeeDto | undefined>(undefined);

    const [saving, setSaving] = React.useState(false);
    const [loading,setLoading] = React.useState(true);

    React.useEffect(() => {
        fetchData();
    }, [])

    const handleFormChanged = (col: string, value: string) => {
        var copy = { ...formModel };
        copy[col] = value;
        setModel(copy);
    };
    const fetchData = () => {
        setLoading(true);
        _employeeClient.get(props.id).then((res) => {
            setModel(res.data);
        }).catch((err) => {
            setErr(parseAxiosError(err));
        }).finally(() => {
            setLoading(false);
        })
    }
    const saveBtnCalled = () => {
        setErr("");
        setSaving(true);
        _employeeClient.update(formModel)
            .takeAtLeast(timeoutQuery)
            .then((res) => {
                props.onSaved();
            }).catch((err) => {
                setErr(parseAxiosError(err));
            })
            .finally(() => {
                setSaving(false);
            })
    }
    const saveValidate = () => {
        if (formRef != null && formRef.current != null) {
            var testForm = formRef.current as any;
            testForm
                .isFormValid()
                .then((result: boolean) => {
                    if (result) {
                        saveBtnCalled();
                    }
                })
                .catch((err: any) => { });
        }
    };
    const disableInput = React.useMemo(() => {
        return saving || teamId == null || formModel == undefined;
    }, [saving, teamId])
    
   
    return <>
        {teamId == null ? <><Alert sx={{ mt: "4px", mb: "4px" }} severity="warning">{t("error.noTeamForEmployee")}</Alert></> : <></>}
        {err != "" ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="warning">{t(err)}</Alert> : null}
        {formModel == undefined || loading ? <><Loader /></> : <> <ValidatorForm ref={formRef} onSubmit={() => { }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextValidator disabled={disableInput} onChange={(event) => { handleFormChanged("firstName", event.target.value) }}
                        id="outlined-basic" value={formModel.firstName} className={"app-input-full-width"} label={t("user.firstName")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator disabled={disableInput} onChange={(event) => { handleFormChanged("lastName", event.target.value) }}
                        id="outlined-basic" value={formModel.lastName} className={"app-input-full-width"} label={t("user.lastName")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TeamPicker selectFirst={false} onTeamPicked={(team) => {
                        var copy = { ...formModel };
                        copy.team = team;
                        setModel(copy);
                    }} />
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch checked={formModel.archived} color="warning" onChange={(ev) => { var copy = { ...formModel }; copy.archived = ev.target.checked; setModel(copy); }} />
                                }
                                label={t("general.archived")}
                            />
                          
                        </FormGroup>
                    </FormControl>
                </Grid>
                {formModel.archived ? <Grid item xs={12}><Alert severity="warning" >{t("error.archivedEmployee")}</Alert></Grid> : null}
                <Grid item xs={12} container justifyContent={"flex-end"}>
                    <Button onClick={props.onCancel} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                        {t("general.cancel-btn")}
                    </Button>
                    <LoadButton
                        startIcon={<Save />}
                        disabled={disableInput}
                        isLoading={saving}
                        type="submit"
                        onClick={saveValidate}
                        className="no-box-shadow"
                        style={{ textTransform: "capitalize", marginLeft: "10px" }}
                        buttonText={t("general.update-btn")} />

                </Grid>
            </Grid>
        </ValidatorForm></>}
       
    </>
}