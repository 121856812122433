import { useRef } from "react";
import { CreateOrganisationDto } from "../../clients/globis/models";
import React from "react";
import { Grid } from '@mui/material';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { LoadButton } from "../../components/loadButton";
import { useInjection } from '../../di/ioc-provider';
import { IOrganisationClient } from '../../clients/globis/organisationClient';
import { TYPES } from "../../di/types";
import Alert from '@mui/material/Alert';
import { parseAxiosError, timeoutQuery } from '../../components/helpers';
import Add from "@mui/icons-material/AddCircle"
export interface CreateOrganisationFormProps {
    onCancel: () => void;
    onSaved: () => void;
}

export const CreateOrganisationForm = (props: CreateOrganisationFormProps) => {
    const formRef = useRef(null);
    const [err,setErr] = React.useState("");
    const [formModel, setModel] = React.useState<CreateOrganisationDto>({
        userFirstName:"",
        userName:"",
        sendPwdForgotMail:false,
        userLastName:"",
        name:""
    });
    const [loading,setLoading] = React.useState<boolean>(false);
    const [t] = useTranslation("common");
    const _organisationClient = useInjection<IOrganisationClient>(TYPES.IOrganisationClient);
    const handleFormChanged = (col: string, value: string) => {
        var copy = { ...formModel };
        copy[col] = value;
        setModel(copy);
    };
    const saveBtnCalled = () => {
        setLoading(true);
        _organisationClient.createOrganisation(formModel).takeAtLeast(timeoutQuery).then((res) => {
           
            props.onSaved();
        }).finally(() => {
            setLoading(false);
        }).catch((err) => {
            setErr(parseAxiosError(err));
        })
    }
    const saveValidate = () => {
        if (formRef != null && formRef.current != null) {
            var testForm = formRef.current as any;
            testForm
                .isFormValid()
                .then((result: boolean) => {
                    if (result) {
                        saveBtnCalled();
                    }
                })
                .catch((err: any) => { });
        }
    };
    return <>
        {err != "" ? <Alert sx={{mt:"4px",mb:"4px"}} severity="error">{t(err)}</Alert> : null}
        <ValidatorForm ref={formRef} onSubmit={() => { }}>
            
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextValidator onChange={(event) => { handleFormChanged("name", event.target.value) }}
                        id="outlined-basic" value={formModel.name} className={"app-input-full-width"} label={t("organisation.name")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator onChange={(event) => { handleFormChanged("userName", event.target.value) }}
                        id="outlined-basic" value={formModel.userName} className={"app-input-full-width"} label={t("general.email")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator onChange={(event) => { handleFormChanged("userFirstName", event.target.value) }}
                        id="outlined-basic" value={formModel.userFirstName} className={"app-input-full-width"} label={t("user.firstName")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator onChange={(event) => { handleFormChanged("userLastName", event.target.value) }}
                        id="outlined-basic" value={formModel.userLastName} className={"app-input-full-width"} label={t("user.lastName")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
                <Grid item xs={12} container justifyContent={"flex-end"}>
                    <Button  onClick={props.onCancel} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                        {t("general.cancel-btn")}
                    </Button>
                    <LoadButton isLoading={loading}
                        startIcon={<Add/>}
                        type="submit"
                        onClick={saveValidate}
                        className="no-box-shadow"
                        style={{ textTransform: "capitalize", marginLeft: "10px" }}
                        buttonText={t("general.create-btn")} />

                </Grid>
            </Grid>
        </ValidatorForm>

    </>
}