
import { SearchOutlined } from "@mui/icons-material";
import { debounce } from "lodash";
import React from "react";
import { TextField,InputAdornment } from '@mui/material';
export interface SearchFieldProps {
    id: string,
    label: string,
    debouncedWaitTime: number,
    onChange: (value: string) => void,
    onDebouncedSearch: (value: string) => void,
    onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    disableEndOrnement?: boolean,
    ref?: any
    value?: string,
    className?: string,
    error?: boolean
    errorMessage?: string,
    renderStartAdornement?: () => any;
    defaultValue?: string,
    disabled?: boolean
}
const SearchField = (props: SearchFieldProps) => {

    const [searchField, setSearchField] = React.useState(props.defaultValue == null ? "" : props.defaultValue);
    const [debouncedSearch] = React.useState<any>((q: any) => debounce((q) => props.onDebouncedSearch(q), props.debouncedWaitTime));

    const handleSearchFieldChange = (q: string) => {
        setSearchField(q);
        props.onChange(q);
        debouncedSearch(q);
    }
    const onFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (props.onFocus != null) {
            props.onFocus(event);
        }
    }
    const renderTextField = () => {

        return <TextField autoFocus disabled={props.disabled} defaultValue={searchField} error={props.error} helperText={props.errorMessage} value={props.value} onFocus={onFocus} onClick={props.onClick} ref={props.ref} className={props.className}
            id={props.id} onChange={(
                event: React.ChangeEvent<HTMLInputElement>
            ) => handleSearchFieldChange(event.target.value)} label={props.label} variant="outlined" size="small"

            InputProps={{
                endAdornment: renderEndOrnement(),
                startAdornment: renderStartAdornment()
            }}
        />
        // }
    }
    const renderEndOrnement = () => {
        if (props.disableEndOrnement != null && props.disableEndOrnement)
            return null;
        else {
            return <InputAdornment position="start"><SearchOutlined /></InputAdornment>;
        }
    }
    const renderStartAdornment = () => {
        if (props.renderStartAdornement == null) return null;

        return props.renderStartAdornement()
    }
    return (
        renderTextField())
}

export default SearchField;