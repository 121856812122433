import OverviewBlock from "./overviewBlock"
import { Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';


export interface IOverviewCardProps {
    title: string,
    body: string,
    aside: any,
    clickable?: boolean
    onClick?: (ev: any) => void
}
export const OverviewCardLoading = () => {
    return <>
        <OverviewBlock >
            <Grid container justifyContent={"space-between"}>
                <Grid item xs={8} md={5}>
                    <Skeleton>  <Typography
                        variant="body1">
                            Frank lampard 1
                    </Typography>
                    </Skeleton>
                    <Skeleton>
                        <Typography
                            style={{ fontSize: "0.8rem", marginTop: "10px" }}
                            variant="body2">
                            Aangemaakt 14/02/2023 door Arne Home
                        </Typography>
                    </Skeleton>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "end", display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <Skeleton>
                        <Typography
                            variant="body2">
                            2.5/2.5/2.5
                        </Typography>
                    </Skeleton>
                </Grid>
            </Grid>

        </OverviewBlock></>
}
export const OverviewCard = (props: IOverviewCardProps) => {

    return <>
        <OverviewBlock enableHoverAnimation={props.clickable} onClick={(ev) => { if (props.onClick != undefined) props.onClick(ev); }}>
            <Grid container justifyContent={"space-between"}>
                <Grid item xs={8} md={5}>
                    <Typography
                        variant="body1">
                        {props.title}
                    </Typography>

                    <Typography
                        style={{ fontSize: "0.8rem" }}
                        variant="body2">
                        {props.body}
                    </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "end", display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <Typography
                        variant="body2">
                        {props.aside}
                    </Typography>
                </Grid>
            </Grid>

        </OverviewBlock>
    </>
}