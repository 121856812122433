import { Route, useHistory } from 'react-router-dom';
import { Roles } from '../config/roles';
import { AuthStore } from '../stores/AuthStore';
import React from 'react';
import { NotFoundPage } from '../pages/notFound';
import MainLayout from '../layouts/mainLayout';

//Extend this route and add it to the DI to add a route
export interface IRoute {
    routeKey: string,
    path: string,
    registerRoute: () => JSX.Element
    role: Roles,
    allowInNavigation?:boolean,
    icon?:any
}


export default function RouteWrapper({ component: Component, route: Role, layout: Layout, ...rest }) {
    const _auth = AuthStore.useState(w=>w.authSettings);
    var history = useHistory();
    const isAuthorized = React.useMemo(() => {
        if(_auth == null)return true; // login catched inside mainlayout
        if(Role == null)return true;
        if(Role.role == Roles.None) return true;

        var role = Role.role;
        var auth = _auth.role;
        if (_auth.role == Roles.User && _auth.team == null) return false;
        return Role.role <= _auth.role
    }, [_auth,Role])
    if(!isAuthorized){
        console.log("wtf");
        return <Layout {...rest}><NotFoundPage /></Layout>
    }
    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout {...props} >
                    <Component {...props} />
                </Layout>
            )
            }
        />
    );
}