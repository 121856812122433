import { injectable } from "inversify";
import { Roles } from "../config/roles";
import MainLayout from "../layouts/mainLayout";
import { UnauthorizedLayout } from "../layouts/unauthorized";
import { DashboardPage } from "../pages/dashboard";
import RouteWrapper, { IRoute } from "./routeInterface";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import ContentIcon from "@mui/icons-material/ContentPaste";
import CorpoIcon from "@mui/icons-material/CorporateFare";
import { TeamsPage } from "../pages/teams";
import { CreateEvaluation } from '../pages/forms/createEvaluation';
import { EmployeePage } from "../pages/employee";
import { ContentPages } from "../pages/content";
import LoginPage from "../pages/login";
import { UsersPage } from "../pages/usersPage";
import { OrganisationsPage } from "../pages/departmentPages";
import { SideLogginLayout } from "../layouts/sideLogginLayout";
import PasswordForgotPage from '../pages/passwordForgot';
import ChangePasswordPage from '../pages/changePassword';
import { EmployeesPage } from "../pages/employeesPage";
import { UpdateEvaluation } from '../pages/forms/updateEvaluation';
import { CreateTaskForm } from '../pages/createTaskForm';
import { UpdateTaskForm } from "../pages/updateTaskForm";
@injectable()
export class DashboardRoute implements IRoute {
    role= Roles.User;
    routeKey = "dashboard";
    path = "/";
    allowInNavigation =true;
    icon = <DashboardIcon color={"secondary"} />
    registerRoute = (): JSX.Element => {
        return <RouteWrapper 
        key={this.routeKey}
            route={this}
        exact path={this.path}
        layout={MainLayout}
        component={DashboardPage}></RouteWrapper>
    }
}
@injectable()
export class TeamsRoute implements IRoute {
    role= Roles.SuperVisor;
    routeKey = "teams";
    path = "/teams";
    allowInNavigation = true;
    icon = <GroupsIcon color={"secondary"} />
    registerRoute = (): JSX.Element => {
        return <RouteWrapper
            key={this.routeKey}
            route={this}
            exact path={this.path}
            layout={MainLayout}
            component={TeamsPage}></RouteWrapper>
    }
}
@injectable()
export class EmployeesRoute implements IRoute {
    role= Roles.User;
    routeKey = "employees";
    path = "/employees";
    allowInNavigation = true;
    icon = <PersonIcon color={"secondary"} />
    registerRoute = (): JSX.Element => {
        return <RouteWrapper
            route={this}
            key={this.routeKey}
            exact path={this.path}
            layout={MainLayout}
            component={EmployeesPage}></RouteWrapper>
    }
}
@injectable()
export class EmployeeRoute implements IRoute {
    role= Roles.User;
    routeKey = "employee";
    path = "/employee/:id";
    allowInNavigation = false;
    icon = <PersonIcon color={"secondary"} />
    registerRoute = (): JSX.Element => {
        return <RouteWrapper
            route={this}
            key={this.routeKey}
            exact path={this.path}
            layout={MainLayout}
            component={EmployeePage}></RouteWrapper>
    }
}

@injectable()
export class ContentRoute implements IRoute {
    role= Roles.SuperVisor;
    routeKey = "content";
    path = "/content";
    allowInNavigation = true;
    icon = <ContentIcon color={"secondary"} />
    registerRoute = (): JSX.Element => {
        return <RouteWrapper
            route={this}
            key={this.routeKey}
            exact path={this.path}
            layout={MainLayout}
            component={ContentPages}></RouteWrapper>
    }
}
@injectable()
export class UsersRoute implements IRoute {
    role= Roles.SuperVisor;
    routeKey = "users";
    path = "/users";
    allowInNavigation = true;
    icon = <PeopleIcon color={"secondary"} />
    registerRoute = (): JSX.Element => {
        return <RouteWrapper
            route={this}
            key={this.routeKey}
            exact path={this.path}
            layout={MainLayout}
            component={UsersPage}></RouteWrapper>
    }
}
@injectable()
export class OrganisationsRoute implements IRoute {
    role= Roles.Admin;
    routeKey = "departments";
    path = "/organisations";
    allowInNavigation = true;
    icon = <CorpoIcon color={"secondary"} />
    registerRoute = (): JSX.Element => {
        return <RouteWrapper
            route={this}
            key={this.routeKey}
            exact path={this.path}
            layout={MainLayout}
            component={OrganisationsPage}></RouteWrapper>
    }
}
@injectable()
export class CreateTaskRoute implements IRoute {
    role= Roles.User;
    routeKey = "new-action";
    path = "/new-action/:id";
    allowInNavigation = false;
    icon = <CorpoIcon color={"secondary"} />
    registerRoute = (): JSX.Element => {
        return <RouteWrapper
            route={this}
            key={this.routeKey}
            exact path={this.path}
            layout={MainLayout}
            component={CreateTaskForm}></RouteWrapper>
    }
}
@injectable()
export class UpdateTaskRoute implements IRoute {
    role= Roles.User;
    routeKey = "update-action";
    path = "/update-action/:id";
    allowInNavigation = false;
    icon = <CorpoIcon color={"secondary"} />
    registerRoute = (): JSX.Element => {
        return <RouteWrapper
            route={this}
            key={this.routeKey}
            exact path={this.path}
            layout={MainLayout}
            component={UpdateTaskForm}></RouteWrapper>
    }
}

@injectable()
export class CreateEvaluationRoute implements IRoute {
    role= Roles.User;
    routeKey = "new-evaluation";
    path = "/new-evaluation/:id";
    allowInNavigation = false;
    icon = <CorpoIcon color={"secondary"} />
    registerRoute = (): JSX.Element => {
        return <RouteWrapper
            route={this}
            key={this.routeKey}
            exact path={this.path}
            layout={MainLayout}
            component={CreateEvaluation}></RouteWrapper>
    }
}
@injectable()
export class UpdateEvaluationRoute implements IRoute {
    role= Roles.User;
    routeKey = "update-evaluation";
    path = "/update-evaluation/:id";
    allowInNavigation = false;
    icon = <CorpoIcon color={"secondary"} />
    registerRoute = (): JSX.Element => {
        return <RouteWrapper
            route={this}
            key={this.routeKey}
            exact path={this.path}
            layout={MainLayout}
            component={UpdateEvaluation}></RouteWrapper>
    }
}
@injectable()
export class LoginRoute implements IRoute {
    role= Roles.None;
    routeKey = "login";
    path = "/login";
    allowInNavigation = false;
    icon = <CorpoIcon color={"secondary"} />
    registerRoute = (): JSX.Element => {
        return <RouteWrapper
            route={this}
            key={this.routeKey}
            exact path={this.path}
            layout={SideLogginLayout}
            component={LoginPage}></RouteWrapper>
    }
}
@injectable()
export class PasswordForgotRoute implements IRoute {
    role = Roles.None;
    routeKey = "pwdForgot";
    path = "/pwdResetAttempt";
    allowInNavigation = false;
    icon = <CorpoIcon color={"secondary"} />
    registerRoute = (): JSX.Element => {
        return <RouteWrapper
            route={this}
            key={this.routeKey}
            exact path={this.path}
            layout={SideLogginLayout}
            component={PasswordForgotPage}></RouteWrapper>
    }
}
@injectable()
export class ResetPasswordRoute implements IRoute {
    role= Roles.None;
    routeKey = "changePwd";
    path = "/changePwd/:token";
    allowInNavigation = false;
    icon = <CorpoIcon color={"secondary"} />
    registerRoute = (): JSX.Element => {
        return <RouteWrapper
            route={this}
            key={this.routeKey}
            exact path={this.path}
            layout={SideLogginLayout}
            component={ChangePasswordPage}></RouteWrapper>
    }
}

