import { Typography, useTheme, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import OverviewBlock from '../../components/overviewBlock';
import { cyan } from '@mui/material/colors';
import Radio from '@mui/material/Radio';
import GroupsIcon from "@mui/icons-material/Groups";
import Button from '@mui/material/Button';
import { LoadButton } from '../../components/loadButton';
import { useInjection } from '../../di/ioc-provider';
import { IEmployeeClient } from '../../clients/globis/employeeClient';
import { TYPES } from '../../di/types';
import { useEffect, useState } from 'react';
import { EmployeeDto, EvaluationFicheDto, EvaluationQuestionDto, EvaluationScore, PerformEvaluationDto, PerformedEvaluationDetailDto } from '../../clients/globis/models';
import React from 'react';
import { IContentClient } from '../../clients/globis/contentClient';
import { createdByText, lastUpdated, parseAxiosError, timeoutQuery } from '../../components/helpers';
import Alert from '@mui/material/Alert';
import { PreLoader } from '../../components/Loader';
import { useImmer } from 'use-immer';
import { List } from 'linqts';
import { useOrganisation } from '../../hooks/useOrganisation';
import { EvaluationFiche } from '../../components/EvaluationFiche';
import sffjs from 'sffjs';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import { useHistory } from 'react-router-dom';
import Save from "@mui/icons-material/Save"
import Print from "@mui/icons-material/Print"
import ReactToPrint from 'react-to-print';
import { AuthStore } from '../../stores/AuthStore';
type TParams = { id?: string };
export interface UpdateEvaluationProps {

}

export const UpdateEvaluation = (props: UpdateEvaluationProps) => {
    const id = React.useMemo(() => {
        var id = (props as any).match.params.id;
        if (id != undefined) {
            try {
                var id_ = Number.parseInt((props as any).match.params.id);
                return id_;
            } catch { }
        }
        return undefined;
    }, [(props as any).match.params.id]);

    useEffect(() => {
        if (id != undefined) {
            fetchContent(id);
        }
    }, [id])

    const history = useHistory();
    const theme = useTheme();
    const _employeeClient = useInjection<IEmployeeClient>(TYPES.IEmployeeClient);
    const _contentClient = useInjection<IContentClient>(TYPES.IContentClient);
    const [employee, setEmployee] = useState<EmployeeDto | undefined>(undefined);
    const [performedEval, setPerformedEval] = React.useState<PerformedEvaluationDetailDto | undefined>(undefined);
    const [templateLoading, setTemplateLoading] = React.useState(false);
    const _loggedInUser = AuthStore.useState(w=>w.authSettings);
    const [scores, setScores] = useImmer<EvaluationScore[]>([]);
    const [err, setErr] = React.useState("");
    const [isSaving, setSaving] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [t] = useTranslation("common");
    const [, organisation] = useOrganisation();
    let componentRef = React.useRef() as any;
    useEffect(() => {
        if (performedEval != null) {
            fetchEmployee(performedEval.employeeId);
        }

    }, [performedEval])
    const fetchContent = (id: number) => {
        _contentClient.getPerformedEvaluation(id)
            .then((res) => {
                setPerformedEval(res.data);
                setScores(res.data.scores);
            })
    }
    const fetchEmployee = (id: number) => {
        _employeeClient.get(id)
            .then((res) => {
                setEmployee(res.data);
            })
    }
    const saveEvaluation = () => {
        setSaving(true);
        setErr("");
        _contentClient.updatePerformedEvaluation({
            id: id,
            scores: scores
        }).takeAtLeast(timeoutQuery).then((res) => {
            setSuccess(true);
        }).finally(() => {
            fetchContent(id);
            setSaving(false);
        }).catch((err) => {
            setErr(parseAxiosError(err));
        })
    }

    const markScore = (ev: EvaluationQuestionDto, index: number) => {
        setScores((draft) => {
            var obj = new List(draft).FirstOrDefault(w => w.id == ev.id);
            if (obj == null) {
                draft.push({ id: ev.id, score: index + 1 });
            } else {
                obj.score = index + 1;
            }
        })
    }
    const getScore = (q: EvaluationQuestionDto) => {
        var scoreNr = 1;
        var score = new List(scores).FirstOrDefault(w => w.id == q.id);
        if (score != null) {
            scoreNr = score.score
        }

        return scoreNr;
    }
    const renderScore = (q: EvaluationQuestionDto) => {
        var scoreNr = getScore(q);
        return [...Array(performedEval.fiche.score)].map((elementInArray, index) => {
            return <><Radio
                onClick={() => {
                    markScore(q, index);
                }}
                disabled={isSaving}
                checked={scoreNr > index}
                value="a"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
            /></>
        }
        )
    }
    const renderQuestion = (question: EvaluationQuestionDto) => {
        return <>
            <Grid item >
                {renderScore(question)}
                <span
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                    {`${getScore(question)}.0`}
                </span>
                <GroupsIcon style={{ position: "relative", top: "4px" }} />
                <span style={{ marginLeft: "4px" }}
                >
                    2.0
                </span>
            </Grid>
        </>
    }

    const renderPersonName = () => {
        if (employee == undefined) return "";
        else {
            return `${employee.firstName} ${employee.lastName}`;
        }
    }

    return <div ref={el => (componentRef = el)}>
        {err != "" ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="warning">{t(err)}</Alert> : null}
        {success ? <><Alert sx={{ mt: "4px", mb: "4px" }} severity="success">{t("general.UpdateEvaluationSuccess")}</Alert></> : <></>}
        <Grid container justifyContent="space-between" sx={{ mt: "20px", mb: "20px" }}>

            {performedEval != undefined ? <>
                <Grid item xs={12} >
                    <Breadcrumbs className='noprint' aria-label="breadcrumb" sx={{ mb: 2 }}>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                            color="inherit"
                            onClick={() => { history.push("/") }}
                        >
                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            {t("route.dashboard")}
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                            color="inherit"
                            onClick={() => { history.push("/employees") }}
                        >
                            <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            {t("route.employees")}
                        </Link>

                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                            color="inherit"
                            onClick={() => { history.push(`/employee/${performedEval.employeeId}`) }}
                        >
                            <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            {renderPersonName()}
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid container justifyContent={"space-between"}>
                    <Grid item >
                        <Typography
                            className="alignCenter"
                            color={(theme as any).palette.text.heading}
                            variant="h1" style={{ marginBottom: "20px" }}>
                            {sffjs(t("general.updateEvaluationForPrint"), performedEval.number, renderPersonName())}

                        </Typography>
                    </Grid>
                    <Grid item className="noprint">
                        <ReactToPrint
                        
                            trigger={() => <Button  startIcon={<Print />}>{t("general.print")}</Button>}
                            content={() => componentRef}
                            bodyClass="print-container"
                            removeAfterPrint={true}
                            copyStyles={true}
                        />
                    </Grid>
                </Grid>
              
                </> : <></>}
         

            <PreLoader loading={templateLoading}>
                {performedEval != undefined && performedEval.fiche != undefined ? <>
                    <EvaluationFiche renderQuestion={renderQuestion} template={performedEval.fiche} />
                </> : <></>}
            </PreLoader>
            <Grid item xs={12}>
                {performedEval != undefined ? <>  {lastUpdated(t, performedEval.updated, performedEval.updatedByName)}</> : <></>}
            </Grid>
            <Grid className="only-print" item xs={12} container justifyContent={"space-between"} style={{marginTop:"8px"}}>
                <Grid item>
                    {t("general.signature")} - {_loggedInUser.name}
                </Grid>
                <Grid item>
                    {t("general.signature")} - {renderPersonName()}
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" sx={{ mt: "20px", mb: "20px" }}>
                <Grid item>
                    {/* 
                 */}
                </Grid>
                <Grid item className="noprint">
                    <LoadButton
                        startIcon={<Save />}
                        className="no-box-shadow full-width"
                        style={{ textTransform: "capitalize" }}
                        variant="contained"
                        color="primary"
                        buttonText={t("general.save")}
                        onClick={() => { saveEvaluation() }}
                        isLoading={isSaving}>
                    </LoadButton>
                </Grid>
            </Grid>
           
        </Grid>
    </div>
}