import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material';
import { IocProvider } from './di/ioc-provider';
import { iocContainer } from './di/inversify.config';
import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from "react-i18next";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'nl',
  debug: false,
  resources: {
    en: { common: require('./translations/en/translate.json') },
    fr: { common: require('./translations/fr/translate.json') },
    nl: { common: require('./translations/nl/translate.json') }
  }                      // language to use

});
const theme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#0D47A1",
          color: "#90CAF9",
        }
      }
    },
    MuiSelect:{
      styleOverrides: {
        select:{
         
        }
      }
    }
  },
  palette: {
    mode: 'light',
    text: {
      primary: "#000",
      heading:"#0D47A1",
      secondary: "rgba(69, 77, 82, 0.8)",
      primaryContrast:"#90CAF9"
    },
    primary: {
      main: "#0D47A1",
      contrastText: "white",
    },
    secondary:{
      main: "#6094E6",
    },
    background:{
      default:"#1C1C27",
      paper:"#FFF", //nav,
      overviewBlock:"#1C1C27"
    },
  },
  typography: {
    h1: {
      fontSize: "2.2rem",
      fontWeight:"400"
    },
    h2: {
      fontSize: "2.1rem"
    },
    h3: {
      fontSize: "1.5rem",
    },
    h4: {
      fontSize: "1.3rem"
    },
    h5: {
      fontSize: "1.1rem"
    },
    body2:{
      fontSize:"1rem", 
      color:"#707070"
    }
  },
  background: {
    primary: "#F6FAFF",
    contrast: "#28293D"
  },
} as any);
root.render(
    <IocProvider container={iocContainer}>
      <I18nextProvider i18n={i18next}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </I18nextProvider>
    </IocProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
