import { getEnumLabels } from './helpers';
import { WorkAssigmentDto, WorkTaskType } from '../clients/globis/models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, useTheme } from '@mui/material';
import OverviewBlock from './overviewBlock';
import { List } from 'linqts';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { useInjection } from '../di/ioc-provider';
import { ITaskClient } from '../clients/globis/taskClient';
import { TYPES } from '../di/types';
import { useOrganisation } from '../hooks/useOrganisation';
import GeneralDialog from './generalDialog';
import { CreateWorkAssignment } from '../forms/task/createWorkTask';
import { LoadButton } from './loadButton';
import Add from '@mui/icons-material/AddCircle';
import { UpdateWorkAssignment } from '../forms/task/updateWorkTask';
import Select from '@mui/material/Select';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export interface IWorkTaskPickerPros{
    onCancel : () => void;
    onSelected : (txt : string) => void;
}
export interface WorkTaskPickerObj{
    display : string,
    type? : WorkTaskType | null;
    typeString : string,
    id?:number
}
export const WorkTaskPicker = (props : IWorkTaskPickerPros) => {
    const _taskClient = useInjection<ITaskClient>(TYPES.ITaskClient);
    const [workAssignments, setWorkAssigments] = React.useState<WorkAssigmentDto[]>([]);
    const [, orgId] = useOrganisation();
    const [createNewDialogOpen,setCreateNewDialogOpen] = React.useState(false);
    const fetchWorkAssignments = () => {
        _taskClient.fetchWorkAssignments(orgId).then((res) => {
            setWorkAssigments(res.data);
        })
    }
    React.useEffect(() => {
        if (orgId != null) {
            fetchWorkAssignments();
        }

    }, [orgId])
    var allLabels = getEnumLabels(WorkTaskType);
    const [t] = useTranslation("common");
    const theme = useTheme();
    const [taskSelected, setTaskSelected] = React.useState<WorkTaskPickerObj | undefined>(undefined);
    
    const entities = React.useMemo(() => {
       
        var allCustomGroups2 = new List(workAssignments)
            .Where(w => w.type == undefined)
            .Select((el, index) => { return { display: el.group, typeString: el.group, id: el.id, type: el.type } as WorkTaskPickerObj })
            .GroupBy(w => w.display);

        var allCustomGroups = new List(Object.keys(allCustomGroups2)).Select(w=>allCustomGroups2[w][0]).ToArray();
        var allDefaultSuggestions= new List(allLabels).Select((el, index) => { return { display: t("WorkTaskType." + index), type: WorkTaskType[el],typeString:el } as WorkTaskPickerObj }).OrderBy(w => w.display).ToArray();
   
        return [...allCustomGroups,...allDefaultSuggestions];
    }, [workAssignments])
    
    const allCustomWorkAssignments = React.useMemo(() => {
        if(taskSelected==undefined) return [];
        if(taskSelected.type == null) return new List(workAssignments).Where(w=>w.group == taskSelected.typeString).ToArray();
        return new List(workAssignments).Where(w=>w.type == taskSelected.type).ToArray();
    },[taskSelected])

    return <>
        <GeneralDialog open={createNewDialogOpen} onClose={() => { }}>
            <CreateWorkAssignment onCancel={() => {setCreateNewDialogOpen(false)}} onSaved={(dto) => {setCreateNewDialogOpen(false);props.onSelected(dto.text)}}/>
        </GeneralDialog>
    <Grid container spacing={1}>
        <Grid item xs={12}>
                <Typography
                    className="alignCenter"
                    color={(theme as any).palette.text.heading}
                    variant="h1">
                    {t("route.workTaskOptionTitle")}
                </Typography>
        </Grid>
            {taskSelected == undefined ? <> <Grid item>
                <LoadButton isLoading={false} disabled={false}
                    startIcon={<Add />}
                    onClick={() => {setCreateNewDialogOpen(true)}}
                    className="no-box-shadow"
                    style={{ textTransform: "capitalize", marginLeft: "10px",height:"100%" }}
                    buttonText={t("general.create-btn")} />
                    </Grid> {entities.map((el) => {
                return <>
                    <Grid item >
                        <OverviewBlock onClick={() => { setTaskSelected(el) }} enableHoverAnimation={true} style={{ textAlign: "center" }}>
                            <Grid item xs={12}>{el.display}</Grid>
                        </OverviewBlock>
                    </Grid>
                </>
            })}</> : <><WorkTaskPickerTemplate customAssignments={allCustomWorkAssignments} onSelected={(el) => {props.onSelected(el)}} cancel={props.onCancel} goBack={() => {setTaskSelected(undefined)}} selected={taskSelected}/></>}
            {taskSelected == undefined ? <><Grid item xs={12} container justifyContent={"flex-end"}>

                <Button onClick={() => { props.onCancel() }} variant="outlined" style={{ textTransform: "capitalize", marginLeft: "4px" }} color="primary">
                    {t("general.cancel-btn")}
                </Button>
            </Grid></> : <> </>}
           
    </Grid>
    </>
}
export interface WorkTaskPickerTemplateOption{
    selected: WorkTaskPickerObj,
    onSelected : (text : string) => void;
    customAssignments : WorkAssigmentDto[],
    goBack : () => void
    cancel : () => void,
}
export const WorkTaskPickerTemplate = (props: WorkTaskPickerTemplateOption) => {

    const [t] = useTranslation("common");
 
    const options = React.useMemo(() => {
        var array = t("workTaskTypeContent."+props.selected.type, { returnObjects: true }) as any
        var customs = new List(props.customAssignments)
        if (array == undefined || !Array.isArray(array)) array = [];
        return [...customs.Select(w=>{return {text:w.text,custom:true,id:w.id}}).ToArray(),...new List(array).Select(w=>{return {text:w,custom:false}}).ToArray()];
    },[props.selected,props.customAssignments]) as any[];

    const [createNewDialogOpen, setCreateNewDialogOpen] = React.useState(false);
    const [editElement,setEditElement] = React.useState<any | undefined>(undefined);
    return <>
        <GeneralDialog title={t("general.createWorkAssignment")} open={createNewDialogOpen} onClose={() => { }}>
            <CreateWorkAssignment fromType={props.selected.type} onCancel={() => { setCreateNewDialogOpen(false) }} onSaved={(dto) => {props.onSelected(dto.text); setCreateNewDialogOpen(false); }} />
        </GeneralDialog>
        {editElement!=undefined? <>
            <GeneralDialog title={t("general.updateWorkAssignment")} open={editElement != undefined} onClose={() => { setEditElement(undefined)}}>
                <UpdateWorkAssignment id={editElement.id} text={editElement.text} onCancel={() => { setEditElement(undefined) }} onSaved={(dto) => { props.onSelected(dto.text); setCreateNewDialogOpen(false); }} />
            </GeneralDialog>
        </> : <></>}
        
        <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                        color="inherit"
                        onClick={() => { props.goBack() }}
                    >
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {t("route.workTaskOptionTitle")}
                    </Link>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                        color="inherit"
                        onClick={() => {}}
                    >
                       {props.selected.display}
                    </Link>
                </Breadcrumbs>
    </Grid>
    <Grid item xs={12}>
            <LoadButton isLoading={false} disabled={false}
                startIcon={<Add />}
                onClick={() => { setCreateNewDialogOpen(true) }}
                className="no-box-shadow"
                style={{ textTransform: "capitalize", marginLeft: "10px", height: "100%" }}
                buttonText={t("general.create-btn")} />
    </Grid>
        {options.map((el) => {
            return <Grid item xs={12}>
                <OverviewBlock onClick={() => { props.onSelected(el.text) }} enableHoverAnimation={true} style={{  }}>
                    <Grid item container justifyContent={"space-between"}>
                        <Grid item>{el.text}</Grid>
                        {el.custom ? <><IconButton onClick={(ev) => {
                            ev.stopPropagation();
                            ev.preventDefault();
                            setEditElement(el);
                        }} aria-label="delete" size="small">
                            <EditIcon color="primary" />
                        </IconButton></> : <></>}
                    </Grid>
                    
                </OverviewBlock>
            </Grid>
        })}
            <Grid item xs={12} container justifyContent={"flex-end"}>
                <Button startIcon={<ArrowBack/>} onClick={props.goBack} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                    {t("general.goback-btn")}
                </Button>
                <Button onClick={props.cancel} variant="outlined" style={{ textTransform: "capitalize",marginLeft:"4px" }} color="primary">
                    {t("general.cancel-btn")}
                </Button>
        </Grid>
    </>
}