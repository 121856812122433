import { Container } from "inversify";
import "reflect-metadata";
import IAppConfig, { DefaultAppConfig } from "../config/config";
import { IRoute } from "../routing/routeInterface";
import { ContentRoute, CreateEvaluationRoute, DashboardRoute, EmployeeRoute, EmployeesRoute, LoginRoute, OrganisationsRoute, TeamsRoute, UsersRoute, PasswordForgotRoute, ResetPasswordRoute, UpdateEvaluationRoute, CreateTaskRoute, UpdateTaskRoute } from '../routing/routes';
import { TYPES } from "./types";
import { IUserApiClient, UserApiClient } from "../clients/globis/userApiClient";
import { IOrganisationClient, OrganisationClient } from '../clients/globis/organisationClient';
import { ITeamsClient ,TeamsClient} from "../clients/globis/teamsClient";
import { IContentClient, ContentClient } from '../clients/globis/contentClient';
import { EmployeeClient, IEmployeeClient } from '../clients/globis/employeeClient';
import { ITaskClient, TaskClient } from '../clients/globis/taskClient';
const iocContainer = new Container();

const RegisterRoutes = () => {
    iocContainer.bind<IRoute>(TYPES.IRoute).to(DashboardRoute);
    iocContainer.bind<IRoute>(TYPES.IRoute).to(TeamsRoute);
    iocContainer.bind<IRoute>(TYPES.IRoute).to(EmployeesRoute);
    iocContainer.bind<IRoute>(TYPES.IRoute).to(ContentRoute);
    iocContainer.bind<IRoute>(TYPES.IRoute).to(UsersRoute);
    iocContainer.bind<IRoute>(TYPES.IRoute).to(OrganisationsRoute);
    iocContainer.bind<IRoute>(TYPES.IRoute).to(CreateEvaluationRoute);
    iocContainer.bind<IRoute>(TYPES.IRoute).to(EmployeeRoute);
    iocContainer.bind<IRoute>(TYPES.IRoute).to(LoginRoute);
    iocContainer.bind<IRoute>(TYPES.IRoute).to(ResetPasswordRoute);
    iocContainer.bind<IRoute>(TYPES.IRoute).to(PasswordForgotRoute);
    iocContainer.bind<IRoute>(TYPES.IRoute).to(UpdateEvaluationRoute);
    iocContainer.bind<IRoute>(TYPES.IRoute).to(CreateTaskRoute);
    iocContainer.bind<IRoute>(TYPES.IRoute).to(UpdateTaskRoute);
    
    iocContainer.bind<IAppConfig>(TYPES.IAppConfig).to(DefaultAppConfig);
    iocContainer.bind<IUserApiClient>(TYPES.IUserClient).to(UserApiClient);
    iocContainer.bind<IOrganisationClient>(TYPES.IOrganisationClient).to(OrganisationClient);
    iocContainer.bind<ITeamsClient>(TYPES.ITeamsClient).to(TeamsClient)
    iocContainer.bind<IContentClient>(TYPES.IContentClient).to(ContentClient);
    iocContainer.bind<IEmployeeClient>(TYPES.IEmployeeClient).to(EmployeeClient);
    iocContainer.bind<ITaskClient>(TYPES.ITaskClient).to(TaskClient);
}
RegisterRoutes();
export { iocContainer };



