import { useRef } from "react";
import { CreateOrganisationDto, InsertTaskDto, TaskType, EmployeeDto, TaskDto } from '../clients/globis/models';
import React from "react";
import { Grid, Typography, useTheme } from '@mui/material';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { LoadButton } from "../components/loadButton";
import { useInjection } from '../di/ioc-provider';
import { IOrganisationClient } from '../clients/globis/organisationClient';
import { TYPES } from "../di/types";
import Alert from '@mui/material/Alert';
import { createdByText, lastUpdated, parseAxiosError, timeoutQuery } from '../components/helpers';
import { ITaskClient } from '../clients/globis/taskClient';
import { IEmployeeClient } from '../clients/globis/employeeClient';
import { useOrganisation } from '../hooks/useOrganisation';
import { CustomSelectList } from "../components/customSelectList";
import FormControl from '@mui/material/FormControl';
import { List } from "linqts";
import { useHistory } from 'react-router-dom';
import Loader from "../components/Loader";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import Save from "@mui/icons-material/Save"
import ReactToPrint from "react-to-print";
import Print from '@mui/icons-material/Print';
import { AuthStore } from "../stores/AuthStore";
export interface UpdateTaskFormProps {
    employee: number;
}

export const UpdateTaskForm = (props: UpdateTaskFormProps) => {
    const taskId = (props as any).match.params.id;
    const theme = useTheme();
    const formRef = useRef(null);
    const [err, setErr] = React.useState("");
    let componentRef = useRef() as any;
    const [formModel, setModel] = React.useState<TaskDto | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [t] = useTranslation("common");
    const _employeeClient = useInjection<IEmployeeClient>(TYPES.IEmployeeClient);
    const _taskClient = useInjection<ITaskClient>(TYPES.ITaskClient);
    const [employeeDto, setEmployee] = React.useState<EmployeeDto | undefined>(undefined);
    const _loggedInUser = AuthStore.useState(w => w.authSettings);
    const [, organisationId] = useOrganisation();
    const [success, setSuccess] = React.useState(false);
    const history = useHistory();
    React.useEffect(() => {
        setModel(undefined)
        setEmployee(undefined);
        fetchTask();
    }, [organisationId])
    React.useEffect(() => {
        if(formModel!= undefined){
            fetchEmployee();
        }
    },[formModel])
    const fetchEmployee = () => {
        _employeeClient.get(formModel.employee).then((res) => {
            setEmployee(res.data);
        })
    }
    const fetchTask = () => {
        _taskClient.getTask(taskId).then((res) => {
            setModel(res.data);
        })
    }
    const handleFormChanged = (col: string, value: string) => {
        var copy = { ...formModel };
        copy[col] = value;
        setModel(copy);
    };
    const saveBtnCalled = () => {
        setLoading(true);
        setSuccess(false);
        _taskClient.updateTask({
            id : formModel.id,
            text : formModel.text,
            taskType : formModel.taskType
        }).takeAtLeast(timeoutQuery).then((res) => {
            setSuccess(true);
        }).catch((err) => {
            setErr(parseAxiosError(err));
        }).finally(() => {
            setLoading(false)
        })
    
    }
    const saveValidate = () => {
        if (formRef != null && formRef.current != null) {
            var testForm = formRef.current as any;
            testForm
                .isFormValid()
                .then((result: boolean) => {
                    if (result) {
                        saveBtnCalled();
                    }
                })
                .catch((err: any) => { });
        }
    };

    const renderPersonName = () => {
        if (employeeDto == undefined) return "";
        else {
            return `${employeeDto.firstName} ${employeeDto.lastName}`;
        }
    }
    if (formModel == null || employeeDto == null) return <Loader></Loader>
    return <div ref={el => (componentRef = el)}>
        {success ? <><Alert sx={{ mt: "4px", mb: "4px" }} severity="success">{t("general.UpdateTaskSuccess")}</Alert></> : <></>}
        {err != "" ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="error">{t(err)}</Alert> : null}
        <Breadcrumbs className="noprint" aria-label="breadcrumb" sx={{ mb: 2 }}>
            <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                color="inherit"
                onClick={() => { history.push("/") }}
            >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {t("route.dashboard")}
            </Link>
            <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                color="inherit"
                onClick={() => { history.push("/employees") }}
            >
                <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {t("route.employees")}
            </Link>

            <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                color="inherit"
                onClick={() => { history.push(`/employee/${employeeDto.id}`) }}
            >
                <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {renderPersonName()}
            </Link>
        </Breadcrumbs>
        <Grid container justifyContent={"space-between"}>
            <Grid item >
                <Typography
                    className="alignCenter"
                    color={(theme as any).palette.text.heading}
                    variant="h1" style={{ marginBottom: "20px" }}>
                    {t("general.updateTaskFor")} {t(`taskType.${formModel.taskType}`)} - {renderPersonName()}
                </Typography>
            </Grid>
            <Grid item className="noprint">
                <ReactToPrint

                    trigger={() => <Button startIcon={<Print />}>{t("general.print")}</Button>}
                    content={() => componentRef}
                    bodyClass="print-container"
                    removeAfterPrint={true}
                    copyStyles={true}
                />
            </Grid>
        </Grid>
       
        <ValidatorForm ref={formRef} onSubmit={() => { }}>
            <Grid container spacing={2}>
                <Grid item xs={1} className="noprint">
                    <span>{t("task.type")}</span>
                </Grid>
                <Grid item xs={11}>
                    <CustomSelectList
                        defaultText={t(`taskType.${formModel.taskType}`)}
                        items={new List(Object.keys(TaskType)).Where(w => !isNaN(Number(w))).ToArray().map((el) => { return { id: Number.parseInt(el), val: t(`taskType.${el}`) } })}
                        onItemClicked={(item) => {
                            var copy = { ...formModel };
                            copy.taskType = item.id;

                            setModel(copy);
                        }} />
                </Grid>
                <Grid item xs={1} className="noprint">
                    <span>{t("task.description")}</span>
                </Grid>
                <Grid item xs={11}>
                    <TextValidator style={{backgroundColor:"white"}} minRows={4} multiline onChange={(event) => { handleFormChanged("text", event.target.value) }}
                        id="outlined-basic" value={formModel.text} className={"app-input-full-width noprint"} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                    <span className="only-print">{formModel.text}</span>
                </Grid>
                <Grid item xs={12}>
                        {formModel != undefined ? <>  {createdByText(t, formModel.updated, formModel.createdByName)}</> : <></>}
            
                </Grid>
                <Grid className="only-print" item xs={12} container justifyContent={"space-between"} style={{ marginTop: "8px" }}>
                    <Grid item>
                        {t("general.signature")} - {_loggedInUser.name}
                    </Grid>
                    <Grid item>
                        {t("general.signature")} - {renderPersonName()}
                    </Grid>
                </Grid>
                <Grid className="noprint" item xs={12} container justifyContent={"space-between"}>
                    <Grid item>
                    
                    </Grid>
                  
                   <Grid item>
                        <Button onClick={() => { }} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                            {t("general.cancel-btn")}
                        </Button>
                        <LoadButton isLoading={loading}
                            startIcon={<Save/>}
                            type="submit"
                            onClick={saveValidate}
                            className="no-box-shadow"
                            style={{ textTransform: "capitalize", marginLeft: "10px" }}
                            buttonText={t("general.update-btn")} />
                   </Grid>
                   

                </Grid>
            </Grid>
        </ValidatorForm>

    </div>
}