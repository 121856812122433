import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { useInjection } from '../di/ioc-provider';
import { IUserApiClient } from '../clients/globis/userApiClient';
import { TYPES } from '../di/types';
import { LoginModel, Roles } from '../clients/globis/models';
import { LoadButton } from '../components/loadButton';
import { useTranslation } from 'react-i18next';
import { AuthStore } from '../stores/AuthStore';
import { useHistory } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { timeoutQuery } from '../components/helpers';
import Alert from '@mui/material/Alert';
import img from "../assets/feedback.png";
export interface SideLogginLayoutProps {
    children?: any
}

export const SideLogginLayout = (props : SideLogginLayoutProps) => {
    const theme = useTheme();

    return <>
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: `url(${img})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar> */}
                        <Typography component="h1" variant="h1" color={(theme as any).palette.text.heading}>
                            Globis coachingstool
                        </Typography>
                        <>{props.children}</>
                        <Typography variant="body2" color="text.secondary" align="center" {...props}>
                            {'Copyright © '}
                            <Link color="inherit" href="https://globis-consulting.be/">
                                Globis
                            </Link>{' '}
                            {new Date().getFullYear()}
                            {'.'}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    
    </>
}