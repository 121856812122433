import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DcDataTable, { DcTableHeader, SortOrder, TableFilterState } from '../components/dcDataTable';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import OverviewBlock from '../components/overviewBlock';
import Avatar from '@mui/material/Avatar';
import TablePagination from '@mui/material/TablePagination';
import { beautifyDate, defaultFilter, getPageSkipTake, getSkip, rowsForTable, sortOnFilter, timeoutQuery } from '../components/helpers';
import { useInjection } from '../di/ioc-provider';
import { IUserApiClient } from '../clients/globis/userApiClient';
import { TYPES } from '../di/types';
import { UserListDto, EmployeeDto, TeamListDto } from '../clients/globis/models';
import { useOrganisation } from '../hooks/useOrganisation';
import Button from '@mui/material/Button';
import GeneralDialog from '../components/generalDialog';
import { CreateOrganisationForm } from '../forms/organisation/createOrganisationForm';
import { CreateUserForm } from '../forms/users/createUser';
import { IEmployeeClient } from '../clients/globis/employeeClient';
import { CreateEmployee } from '../forms/employees/createEmployee';
import { ITeamsClient } from '../clients/globis/teamsClient';
import { useTeam } from '../hooks/useTeam';
import { useHistory } from 'react-router-dom';
import Add from "@mui/icons-material/AddCircle"
import SearchField from '../components/searchField';
import Loader from '../components/Loader';
export interface IEmployeePage {
    onClick : (employee : number) => void

}

export const EmployeesPage = (props?: IEmployeePage) => {

    const theme = useTheme();
    const [t] = useTranslation("common");
    const [loading, setLoading] = React.useState(false);
    const [filterState, setFilter] = React.useState<TableFilterState>(defaultFilter);
    const [entityCount, setEntityCount] = React.useState<number>(0);
    const _employeeClient = useInjection<IEmployeeClient>(TYPES.IEmployeeClient);
    const [employees, setEmployees] = React.useState<EmployeeDto[]>([]);
    const history = useHistory();
    const [openCreateUserDialog, setOpenCreateUserDialog] = React.useState(false);
    const usedOutsidePage = React.useMemo(() => {
        if(props?.onClick != undefined)return true;
        return false;
    },[props]);
    const [, organisationId] = useOrganisation();
    const headers = [
        { id: "LastName", name: t("employee.name") },
        { id: "TeamName", name: t("employee.team") },
        { id: "Updated", name: t("general.updated") },
        { id: "CreatedByName", name: t("general.createdBy") },
        { id: "Archived", name: t("general.archived-table") }
    ] as DcTableHeader[]
    React.useEffect(() => {
        setEmployees([]);
        if (organisationId != null) {
            fetchData();
        }
    },[organisationId])
    React.useEffect(() => {
        if (organisationId != null) {
            fetchData();
        }

    }, [filterState])
    const onChangePage = (page: number) => {
        var skip = getSkip(page, filterState.take);
        var copy = { ...filterState };
        copy.skip = skip;
        setFilter(copy);
    }
    const fetchData = () => {
        setLoading(true);
        
        _employeeClient.search(
            {
                skip: filterState.skip,
                take: filterState.take,
                sortColumn: filterState.sortColumn,
                sortOrder: filterState.sortOrder,
                payload: {
                    organisation: organisationId,
                    search: filterState.search,
                    
                }
            }
        )
            .then((res) => {
                setEntityCount(res.data.entityCount);
                setEmployees(res.data.data);
            }).finally(() => {
                setLoading(false);
            })
    }
    const onEmployeeClicked = (dto : EmployeeDto) => {
        history.push(`/employee/${dto.id}`);
    }
    return <>
        <GeneralDialog onClose={() => { setOpenCreateUserDialog(false) }} title={t("general.createEmployee")} open={openCreateUserDialog}>
            <>
                <CreateEmployee onSaved={() => {
                    setFilter({ ...defaultFilter });
                    setOpenCreateUserDialog(false);
                }} onCancel={() => { setOpenCreateUserDialog(false) }}></CreateEmployee>
            </>
        </GeneralDialog>
        <Grid container justifyContent={"space-between"} spacing={2}>
            {usedOutsidePage ? <></> : <><Grid item>
                <Typography
                    className="alignCenter"
                    color={(theme as any).palette.text.heading}
                    variant="h1">
                    {t("route.employees")}
                </Typography>
            </Grid></>}
            
            <Grid item>
                <SearchField debouncedWaitTime={500} id="employeeSearch" label={t("general.search")} onChange={() => { }} onDebouncedSearch={(val) => {
                    var copy = { ...filterState };
                    copy.search = val;
                    setFilter(copy);
                }}></SearchField>
            </Grid>
            {usedOutsidePage ? <></> : <> 
            <Grid item>
                <Button
                    startIcon={<Add />}
                    className="no-box-shadow full-width"
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                    onClick={(e) => { setOpenCreateUserDialog(true) }}
                    color="primary">
                    {t("general.createEmployee")}
                </Button>
            </Grid></>}
           
        </Grid>
        <Grid container sx={{ mt: "8px" }}>
            <Grid item xs={12}>
                <OverviewBlock style={{display:"table"}}>
                    <DcDataTable
                        headers={headers}
                        onFilterClicked={(el) => { setFilter(sortOnFilter(filterState, el)) }}
                        filters={filterState}>
                        {employees.map((el) => {

                            return <React.Fragment>
                                <TableRow key={el.id} hover onClick={() => { !usedOutsidePage ? onEmployeeClicked(el) : props.onClick(el.id) }}>
                                    {/* <TableCell><Avatar {...stringAvatar(el.test)} /></TableCell> */}
                                    <TableCell>{el.firstName} {el.lastName}</TableCell>
                                    <TableCell>{el.teamName}</TableCell>
                                    <TableCell>{beautifyDate(el.updated)}</TableCell>
                                    <TableCell>{el.createdByName}</TableCell>
                                    <TableCell>{el.archived?t("general.yes") : t("general.no")}</TableCell>
                                </TableRow>
                            </React.Fragment>
                        })}
                    </DcDataTable>
                    {loading?<><Loader/></> : <></>}
                    <TablePagination
                        labelRowsPerPage={t("general.rowsPerPage")}
                        style={{ marginLeft: "auto" }}
                        rowsPerPageOptions={rowsForTable}
                        component="div"
                        count={entityCount}
                        rowsPerPage={filterState.take}
                        
                        page={getPageSkipTake(filterState.skip, filterState.take)}
                        onPageChange={(event, page) => { onChangePage(page) }}
                        onRowsPerPageChange={(event) => {
                            var copy = { ...filterState };
                            copy.take = Number.parseInt(event.target.value);
                            setFilter(copy);
                        }}
                    />
                </OverviewBlock>

            </Grid>
        </Grid>
    </>
}