import { IEmployeeClient } from '../clients/globis/employeeClient';
const TYPES = {
    //EXAMPLE DI
    IRoute : Symbol.for("IRoute"),
    IAppConfig : Symbol.for("IAppConfig"),
    IUserClient: Symbol.for("IUserClient"),
    IOrganisationClient: Symbol.for("IOrganisationClient"),
    ITeamsClient : Symbol.for("ITeamsClient"),
    IContentClient : Symbol.for("IContentClient"),
    IEmployeeClient : Symbol.for("IEmployeeClient"),
    ITaskClient : Symbol.for("ITaskClient")
};

export { TYPES };