import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { CustomSelectList } from "../components/customSelectList";
import { useInjection } from '../di/ioc-provider';
import { ITeamsClient } from '../clients/globis/teamsClient';
import { TYPES } from "../di/types";
import { TeamListDto } from "../clients/globis/models";
import React from "react";
import { SortOrder } from "../components/dcDataTable";
import { useOrganisation } from './useOrganisation';
import { List } from "linqts";
import GroupsIcon from "@mui/icons-material/Groups";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export interface useTeamProps{
    selectFirstTeam : boolean,
    defaultSelectedTeam? : number | undefined,
}
export const useTeam = (props : useTeamProps): [(team? : number | undefined) => JSX.Element,number | null] => {
    const [teams, setTeams] = React.useState<TeamListDto[]>(undefined);
    const [selectedTeam,setSelectedTeam] = React.useState<TeamListDto | undefined>(undefined);
    const _teamClient = useInjection<ITeamsClient>(TYPES.ITeamsClient);
    const [,organisationId] = useOrganisation();

   
    React.useEffect(() => {
        setTeams(undefined);
        if(organisationId!=null){
            fetchTeams();
        }
     
        
    },[organisationId])

    var selectedTeamId = React.useMemo(() => {
        if(selectedTeam == undefined) return null;
        else return selectedTeam.id;
    },[selectedTeam])
    const fetchTeams = () => {
        _teamClient.fetchTeams({ payload:{organisation:organisationId,search:""},skip: 0, take: 10000, sortColumn: "Name", sortOrder: SortOrder.ASC }).then((res) => {
            setTeams(res.data.data);
            if(props.defaultSelectedTeam != null){
                var team = new List(res.data.data).FirstOrDefault(w=>w.id == props.defaultSelectedTeam);
                if(team != undefined){
                    setSelectedTeam(team);
                    return;
                }
            }
            if(res.data.entityCount>0 && props.selectFirstTeam){
                setSelectedTeam(res.data.data[0]);
            }
        })
    }
    const [t] = useTranslation("common");
    const renderTeamPicker = (team? : number|null) => {
        if(team != null){
            var team_ = new List(teams).FirstOrDefault(w => w.id == team);
            if (team_ != undefined) {
                setSelectedTeam(team_);
            }
        }
        return <>
            {teams != undefined ? <> <CustomSelectList startIcon={<GroupsIcon fontSize="large"/>}
                items={new List(teams).Select(w => { return { id: w.id, val: w.name } }).ToArray()}
                defaultText={selectedTeam!=null ? selectedTeam.name : t("team.selectTeam")} 
                onItemClicked={(item) => {
                    var team = new List(teams).FirstOrDefault(w=>w.id == item.id);
                    setSelectedTeam(team);
                 }} /></>:<></>}
           
           
        </>
    }

    return [renderTeamPicker, selectedTeamId]; 
}