import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { CreateTeamDto, ExportDto, AppExportInvoiceDto } from '../../clients/globis/models';
import { useInjection } from '../../di/ioc-provider';
import { ITeamsClient } from '../../clients/globis/teamsClient';
import { TYPES } from "../../di/types";
import { parseAxiosError, timeoutQuery } from "../../components/helpers";
import Alert from '@mui/material/Alert';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { LoadButton } from "../../components/loadButton";
import { useOrganisation } from "../../hooks/useOrganisation";
import Add from "@mui/icons-material/AddCircle"
import moment from 'moment';
import { IContentClient } from '../../clients/globis/contentClient';
import { CSVLink, CSVDownload } from "react-csv";
import Loader from "../../components/Loader";

export interface ExportProps{
    onCancel : () => void
    onSave : () => void
}
export const Export = (props : ExportProps) => {

    const formRef = useRef(null);
    const _contentClient = useInjection<IContentClient>(TYPES.IContentClient);
    const [exportData,setExportData] = React.useState<AppExportInvoiceDto[]>([])
    const [formModel, setModel] = React.useState<ExportDto>({date : moment().startOf("month").toDate()});
    const [t] = useTranslation("common");
    const [loading,setLoading] = React.useState(false);
    const handleDateChanged = (val  : string) => {

    }
    React.useEffect(() => {
        fetch();
    },[])
    const fetch = () => {
        _contentClient.exportInvoice(formModel)
        .then((res) => {
            setExportData(res.data);
        }).finally(() => {
            setLoading(false);
        })
    }
    // const saveValidate = () => {
    //     if (formRef != null && formRef.current != null) {
    //         var testForm = formRef.current as any;
    //         testForm
    //             .isFormValid()
    //             .then((result: boolean) => {
    //                 if (result) {
    //                     saveBtnCalled();
    //                 }
    //             })
    //             .catch((err: any) => { });
    //     }
    // }; 
    return <>
        <ValidatorForm ref={formRef} onSubmit={() => { }}>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{textAlign:"center"}}>
                    {loading ? <><Loader /></> : <><CSVLink  filename={"exportGlobis.csv"} seperator=";" data={exportData}>{t("general.downloadExport")}</CSVLink>
                        {/* <TextValidator onChange={(event) => { handleDateChanged(event.target.value) }}
                        id="outlined-basic" value={formModel.date} className={"app-input-full-width"} label={t("team.name")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    /> */}</>}
                 
                </Grid>
                <Grid item xs={12} container justifyContent={"flex-end"}>
                    <Button onClick={props.onCancel} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                        {t("general.cancel-btn")}
                    </Button>
                </Grid>
            </Grid>
        </ValidatorForm>
    </>
}