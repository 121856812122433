import React from "react";
import { AuthStore } from '../stores/AuthStore';
import { Roles } from "../config/roles";
import { useInjection } from '../di/ioc-provider';
import { IOrganisationClient } from '../clients/globis/organisationClient';
import { TYPES } from "../di/types";
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { LoadButton } from '../components/loadButton';
import GeneralDialog from "../components/generalDialog";
import { DepartmentList } from "../pages/departmentPages";
import ListItemButton from '@mui/material/ListItemButton';
import Corpo from '@mui/icons-material/MapsHomeWork';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Grid } from '@mui/material';
import CorpoIcon from "@mui/icons-material/CorporateFare";
import { SortOrder } from "../components/dcDataTable";

export const useOrganisation = (): [() => JSX.Element, number | null, () => JSX.Element] => {

    const [loading, setLoading] = React.useState(false);
    const [t] = useTranslation("common");
    const _organisationClient = useInjection<IOrganisationClient>(TYPES.IOrganisationClient);
    var authSettings = AuthStore.useState(w => w.authSettings);
    var orgSettings = AuthStore.useState(w => w.organisation);
    const [renderOrgPicker, setRenderOrgPicker] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (authSettings.role == Roles.Admin && orgSettings == null) {
            fetchFirstOrg();
        }
    }, [authSettings, orgSettings]);

    const organisationId = React.useMemo(() => {
        if (authSettings.role == Roles.Admin) {
            return orgSettings?.id;
        }
        return authSettings.organisation;

    }, [orgSettings, authSettings])
    const fetchFirstOrg = () => {
        setLoading(true);
        _organisationClient.fetchOrganisations({
            skip: 0,
            take: 1,
            sortColumn:"id",
            sortOrder:SortOrder.ASC
        }).then((res) => {
            AuthStore.update(w => { w.organisation = { id: res.data.data[0].id, name: res.data.data[0].name } });
        }).finally(() => {
            setLoading(false)
        })

    }

    const renderOrganisationPicker = () => {
        return <>
            <GeneralDialog actions={<><Grid  item xs={12} container justifyContent={"center"}>
                <Button onClick={() => { setRenderOrgPicker(false) }} variant="outlined" style={{ textTransform: "capitalize"  }} color="primary">
                    {t("general.cancel-btn")}
                </Button>
            </Grid></>} open={renderOrgPicker} onClose={() => { setRenderOrgPicker(false) }}>
                <DepartmentList onRowClick={(el) => {
                    AuthStore.update(w => { w.organisation = { id: el.id, name: el.name } });
                    setRenderOrgPicker(false);
                }} />
            </GeneralDialog>
            <LoadButton style={{ fontSize: "1.1em"}}  startIcon={<CorpoIcon fontSize='large' />} onClick={() => { setRenderOrgPicker(true) }} buttonText={orgSettings?.name} isLoading={loading} color="secondary" variant='contained' ></LoadButton>
        </>
    }
    const renderOrganisationPickerMobile = () => {
        return <><GeneralDialog actions={<><Grid item xs={12} container justifyContent={"center"}>
            <Button onClick={() => { setRenderOrgPicker(false) }} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                {t("general.cancel-btn")}
            </Button>
        </Grid></>} open={renderOrgPicker} onClose={() => { }}>
            <DepartmentList onRowClick={(el) => {
                AuthStore.update(w => { w.organisation = { id: el.id, name: el.name } });
                setRenderOrgPicker(false);
            }} />
        </GeneralDialog> <ListItemButton onClick={() => { setRenderOrgPicker(true) }}>
                <ListItemIcon >
                    <Corpo color={"secondary"} />
                </ListItemIcon>
            </ListItemButton></>
    }
    return [renderOrganisationPicker, organisationId, renderOrganisationPickerMobile];
}