import { Store } from "pullstate";
import { Roles } from "../config/roles";
export interface IActiveOrganisation {
    id: number,
    name: string
}

export const AuthStore = new Store({
    authSettings: {
        jwtToken: window.localStorage.getItem("jwt"),
        email: "",
        name: "",
        organisation: null ,
        role : Roles.User,
        newUser : false,
    } as AuthObj,
    organisation : null as IActiveOrganisation
});

export interface AuthObj{
    jwtToken: string,
    email: string,
    name: string,
    organisation?:number|null
    role : Roles,
    team?:number|null,
    newUser : boolean,
}