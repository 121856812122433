import { inject, injectable } from 'inversify';
import "reflect-metadata";
import { PageDataRequest, PagedData, SearchModel, UserListDto, CreateUserDto, LoginModel, OrganisationListDto, CreateOrganisationDto, OrganisationSearchModel, CreateTeamDto, TeamListDto, CreateEvaluationFicheDto, EvaluationFicheDto, EvaluationFicheListDto, EvaluationFicheSearchModel, PerformEvaluationDto, PerformedEvaluationDetailDto, UpdateEvaluationDto, EvaluationDto, DashboardEvaluationSearchModel, EvaluationDashboardModel, AvgScoreByTeamReq, AvgScoresByTeamResponse, ScoresByEvIdReq, UpdateEvaluationFiche, ExportDto, AppExportInvoiceDto, RemoveEvaluationFiche } from './models';
import Axios, { AxiosResponse } from "axios";
import IAppConfig from '../../config/config';
import { TYPES } from '../../di/types';


export interface IContentClient {
    getScoresFromEvaluations(req: ScoresByEvIdReq): Promise<AxiosResponse<EvaluationDashboardModel[]>>
    getAvgScoresByTeams(req: AvgScoreByTeamReq): Promise<AxiosResponse<AvgScoresByTeamResponse>>
    getFicheByTeamId(id: number): Promise<AxiosResponse<EvaluationFicheDto>>;
    dashboardEmployeeStats(req: PageDataRequest<DashboardEvaluationSearchModel>): Promise<AxiosResponse<PagedData<EvaluationDashboardModel>>>
    updatePerformedEvaluation(req: UpdateEvaluationDto): Promise<AxiosResponse>
    insertEvaluationFiche(req: CreateEvaluationFicheDto): Promise<AxiosResponse<number>>
    getEvaluationFiche(id: number): Promise<AxiosResponse<EvaluationFicheDto>>;
    searchEvaluationFiche(req: PageDataRequest<EvaluationFicheSearchModel>): Promise<AxiosResponse<PagedData<EvaluationFicheListDto>>>
    getFicheByEmployee(id : number) : Promise<AxiosResponse<EvaluationFicheDto>>;
    performNewEvaluation(req: PerformEvaluationDto): Promise<AxiosResponse<number>>;
    getPerformedEvaluation(id: number): Promise<AxiosResponse<PerformedEvaluationDetailDto>>;
    fetchEvaluationsForEmployee(id: number, skip: number, take: number): Promise<AxiosResponse<PagedData<EvaluationDto>>>
    getAvgScoreOnNewEvaluation(employeeId: number): Promise<AxiosResponse<AvgScoresByTeamResponse>>
    updateEvaluationFiche(req: UpdateEvaluationFiche): Promise<AxiosResponse>
    exportInvoice(dto: ExportDto): Promise<AxiosResponse<AppExportInvoiceDto[]>>
    removeEvaluationFiche(req: RemoveEvaluationFiche): Promise<AxiosResponse>
}
@injectable()
export class ContentClient implements IContentClient {
   
    apiUrl = process.env.REACT_APP_BFF_URL
    apiPrefix = "api/Content";

    @inject(TYPES.IAppConfig) private _config: IAppConfig;
    exportInvoice(dto: ExportDto): Promise<AxiosResponse<AppExportInvoiceDto[]>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/ExportInvoice`, dto);
    }
    updateEvaluationFiche(req: UpdateEvaluationFiche) : Promise<AxiosResponse>{
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/UpdateEvaluationFiche`, req);
    }
    dashboardEmployeeStats(req: PageDataRequest<DashboardEvaluationSearchModel>): Promise<AxiosResponse<PagedData<EvaluationDashboardModel>>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/DashboardEmployeeStats`, req);
    }
    updatePerformedEvaluation(req: UpdateEvaluationDto): Promise<AxiosResponse>{
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/UpdatePerformedEvaluation`, req);
    }
    getPerformedEvaluation(id: number): Promise<AxiosResponse<PerformedEvaluationDetailDto>>{
        return Axios.get(`${this._config.endpointUrl}${this.apiPrefix}/GetPerformedEvaluation?id=${id}`);
    }
    fetchEvaluationsForEmployee(id: number, skip: number, take: number): Promise<AxiosResponse<PagedData<EvaluationDto>>> {
        return Axios.get(`${this._config.endpointUrl}${this.apiPrefix}/FetchEvaluationsByEmployee?id=${id}&skip=${skip}&take=${take}`);
    }
    insertEvaluationFiche(req : CreateEvaluationFicheDto) : Promise<AxiosResponse<number>>{
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/InsertEvaluationFiche`,req);
    }
    getEvaluationFiche(id: number): Promise<AxiosResponse<EvaluationFicheDto>> {
        return Axios.get(`${this._config.endpointUrl}${this.apiPrefix}/GetEvaluationFiche?id=${id}`);
    }
    getFicheByTeamId(id: number): Promise<AxiosResponse<EvaluationFicheDto>> {
        return Axios.get(`${this._config.endpointUrl}${this.apiPrefix}/GetFicheByTeamId?id=${id}`);
    }
    getFicheByEmployee(id: number): Promise<AxiosResponse<EvaluationFicheDto>> {
        return Axios.get(`${this._config.endpointUrl}${this.apiPrefix}/GetFicheByEmployee?id=${id}`);
    }
    searchEvaluationFiche(req : PageDataRequest<EvaluationFicheSearchModel>) : Promise<AxiosResponse<PagedData<EvaluationFicheListDto>>>{
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/SearchEvaluationFiches`, req);
    }
    performNewEvaluation(req: PerformEvaluationDto): Promise<AxiosResponse<number>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/PerformNewEvaluation`, req);
    }
    getAvgScoresByTeams(req: AvgScoreByTeamReq): Promise<AxiosResponse<AvgScoresByTeamResponse>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/GetAvgScoresByTeams`, req);
    }
    getAvgScoreOnNewEvaluation(employeeId: number): Promise<AxiosResponse<AvgScoresByTeamResponse>> {
        return Axios.get(`${this._config.endpointUrl}${this.apiPrefix}/GetAvgScoreOnNewEvaluation?employeeId=${employeeId}`);
    }
    getScoresFromEvaluations(req: ScoresByEvIdReq): Promise<AxiosResponse<EvaluationDashboardModel[]>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/GetScoresFromEvaluations`, req);
    }
    removeEvaluationFiche(req: RemoveEvaluationFiche): Promise<AxiosResponse> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/DeleteEvaluationFiche`, req);
    }
}