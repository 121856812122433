import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { useInjection } from '../di/ioc-provider';
import { IUserApiClient } from '../clients/globis/userApiClient';
import { TYPES } from '../di/types';
import { LoginModel, Roles } from '../clients/globis/models';
import { LoadButton } from '../components/loadButton';
import { useTranslation } from 'react-i18next';
import { AuthStore } from '../stores/AuthStore';
import { useHistory } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { timeoutQuery } from '../components/helpers';
import Alert from '@mui/material/Alert';
import { useRef } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const theme = createTheme();
export interface PwdForgotModel{
    email : string
}
export const PasswordForgotPage = () => {
    const _userClient = useInjection<IUserApiClient>(TYPES.IUserClient);
    const theme = useTheme();
    const formRef = useRef(null);
    const history = useHistory();
    const [errorReceived, setErrorReceived] = React.useState(false);
    const [loginModel, setLoginModel] = React.useState<PwdForgotModel>({
        email: "",
    });
    const [passwordResetSuccess,setPasswordResetSucces]  = React.useState(false);
    const [t] = useTranslation("common");
    const [loading, setLoading] = React.useState(false);
    const saveValidate = () => {
        if (formRef != null && formRef.current != null) {
            var testForm = formRef.current as any;
            testForm
                .isFormValid()
                .then((result: boolean) => {
                    if (result) {
                        resetPassword();
                    }
                })
                .catch((err: any) => { });
        }
    };
    const resetPassword = () => {
        setLoading(true);
        setErrorReceived(false);
        _userClient.passwordForgot(loginModel).takeAtLeast(1000)
        .then((res) => {
            setErrorReceived(false);
            setPasswordResetSucces(true);
        }).catch((err) => {
            setErrorReceived(true);
        })
        .finally(() => {
            setLoading(false)
        })
       
    }
    return <>
        <ValidatorForm style={{ width: "100%" }} ref={formRef} onSubmit={() => { }}>
            <TextValidator

                disabled={loading}
                margin="normal"

                fullWidth
                label={t("general.email")}
                name="email"
                value={loginModel.email}
                autoFocus
                validators={["required"]}
                errorMessages={[t("validation.required")]}
                onChange={(ev) => {
                    var copy = { ...loginModel };
                    copy.email = ev.target.value;
                    setLoginModel(copy);
                }}
            />
            
            <LoadButton
                style={{ width: "100%" }}
                isLoading={loading}
                type="submit"
                variant="contained"
                buttonText={t("general.resetPwdButton")}
                onClick={saveValidate}>

            </LoadButton>
            {errorReceived ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="error">{t("general.errorPwdReset")}</Alert> : null}
            {passwordResetSuccess ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="success">{t("general.successPwdReset")}</Alert> : null}
            <Grid container sx={{ mt: "20px" }}>
                <Grid item xs style={{ textAlign: "right" }}>
                    <Link href="#" variant="body2" onClick={() => {
                        history.push("/login")
                    }}>
                        {t("general.backToLogin")}
                    </Link>
                </Grid>
            </Grid>
        </ValidatorForm></>

}

export default PasswordForgotPage