import React, { Suspense } from 'react';
import logo from './logo.svg';
import './App.css';
import { useInjection, useMultipleInjection } from './di/ioc-provider';
import { IRoute } from './routing/routeInterface';
import { TYPES } from './di/types';
import IAppConfig from './config/config';
import { Switch, HashRouter, Route, BrowserRouter } from 'react-router-dom';
import { UnauthorizedLayout } from './layouts/unauthorized';
import { JsStillRendering } from './components/jsStillRendering';
import AxiosErrorInterceptor from './components/axiosInterceptors';
require("./extensions/promiseExtension");
export const App = () => {
  const _routes = useMultipleInjection<IRoute>(TYPES.IRoute);
  return <>
    <AxiosErrorInterceptor />
    <BrowserRouter>
      <Suspense fallback={<JsStillRendering />}>
        <Switch>
          {_routes.map((el) => { return el.registerRoute() })}
        </Switch>
      </Suspense>
    </BrowserRouter>


  </>
}

export default App;
