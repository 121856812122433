import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { useInjection } from '../di/ioc-provider';
import { IUserApiClient } from '../clients/globis/userApiClient';
import { TYPES } from '../di/types';
import { LoginModel, Roles } from '../clients/globis/models';
import { LoadButton } from '../components/loadButton';
import { useTranslation } from 'react-i18next';
import { AuthStore } from '../stores/AuthStore';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { timeoutQuery, parseAxiosError } from '../components/helpers';
import Alert from '@mui/material/Alert';
import { useRef } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const theme = createTheme();
export interface ChangePasswordModel {
    token: string
    password: string,
    repeatPassword: string,
}
type TParams = { token: string };

export interface ChangePasswordPageProps extends RouteComponentProps<TParams> {
}
export const ChangePasswordPage = (props: ChangePasswordPageProps) => {
    const _userClient = useInjection<IUserApiClient>(TYPES.IUserClient);
    const theme = useTheme();
    const formRef = useRef(null);
    const history = useHistory();
    const [errorReceived, setErrorReceived] = React.useState("");
    const [loginModel, setLoginModel] = React.useState<ChangePasswordModel>({
        token: (props as any).match.params.token,
        password: "",
        repeatPassword: ""
    });
    React.useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== loginModel.password) {
                return false;
            }
            return true;
        });
    }, [loginModel])
    const [passwordResetSuccess, setPasswordResetSucces] = React.useState(false);
    const [t] = useTranslation("common");
    const [loading, setLoading] = React.useState(false);
    const saveValidate = () => {
        if (formRef != null && formRef.current != null) {
            var testForm = formRef.current as any;
            testForm
                .isFormValid()
                .then((result: boolean) => {
                    if (result) {
                        resetPassword();
                    }
                })
                .catch((err: any) => { });
        }
    };
    const resetPassword = () => {
        setLoading(true);
        setErrorReceived("");
        _userClient.changePassword(loginModel).takeAtLeast(1000).then((res) => {
            setPasswordResetSucces(true);
            setTimeout(() => {history.push("/login")},1000)
        }).catch((err) => {
            setErrorReceived(parseAxiosError(err));
        }).finally(() => {
            setLoading(false);
        })

       

    }
    return <>
        <ValidatorForm style={{ width: "100%" }} ref={formRef} onSubmit={() => { }}>
            <Alert sx={{ mt: "4px", mb: "4px" }} severity="info">{t("general.changePasswordText")}</Alert>
            <TextValidator
                disabled={loading}
                margin="normal"
                fullWidth
                label={t("general.password")}
                name="password"
                value={loginModel.password}
                autoFocus
                validators={["required"]}
                errorMessages={[t("validation.required")]}
                onChange={(ev) => {
                    var copy = { ...loginModel };
                    copy.password = ev.target.value;
                    setLoginModel(copy);
                }}
                type="password"
            />
            <TextValidator
                type="password"
                disabled={loading}
                margin="normal"

                fullWidth
                label={t("general.passwordRepeat")}
                name="password"
                value={loginModel.repeatPassword}
                autoFocus
                validators={["isPasswordMatch", "required"]}
                errorMessages={[t("validation.passwordMismatch"), t("validation.required")]}
                onChange={(ev) => {
                    var copy = { ...loginModel };
                    copy.repeatPassword = ev.target.value;
                    setLoginModel(copy);
                }}
            />
            <LoadButton
                style={{ width: "100%" }}
                isLoading={loading}
                type="submit"
                variant="contained"
                buttonText={t("general.update-btn")}
                onClick={saveValidate}>

            </LoadButton>
            {errorReceived != "" ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="error">{t("general.errorPwdReset")}</Alert> : null}
            {passwordResetSuccess ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="success">{t("general.successPwdChanged")}</Alert> : null}
            <Grid container sx={{ mt: "20px" }}>
                <Grid item xs style={{ textAlign: "right" }}>
                    <Link href="#" variant="body2" onClick={() => {
                        history.push("/login")
                    }}>
                        {t("general.backToLogin")}
                    </Link>
                </Grid>
            </Grid>
        </ValidatorForm></>

}

export default ChangePasswordPage