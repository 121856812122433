import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useTeam } from '../hooks/useTeam';
import Alert from '@mui/material/Alert';
import { Grid } from '@mui/material';
import React from 'react';
import OverviewBlock from '../components/overviewBlock';
import ContentIcon from "@mui/icons-material/ContentPaste"
import EditIcon from "@mui/icons-material/Edit"
import TaskIcon from "@mui/icons-material/QuestionAnswer"
import { EmployeesPage } from './employeesPage';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import { List } from 'linqts';
export type ActionType = "EVAL" | "TASK"
export interface QuickActionProps{
    onCancel : () => void;
}
var actions = [{ needTeam: true, text: "general.createEvaluation", type: "EVAL" as ActionType, iconSmall: <ContentIcon sx={{ mr: 0.5 }} />, icon: <ContentIcon color="primary" style={{ fontSize: "10vh" }} /> },
    { needTeam: true, text: "general.createTask", type: "TASK" as ActionType, iconSmall: <TaskIcon sx={{ mr: 0.5 }} />, icon: <TaskIcon color="primary" style={{ fontSize: "10vh" }} /> }]

export const QuickAction = (props : QuickActionProps) => {
 
    const [t] = useTranslation("common");
    const [, teamId] = useTeam({ selectFirstTeam  : true});
    const [action,setAction] = React.useState<ActionType | undefined>(undefined);
    var actionProps = React.useMemo(() => {
        if(action == undefined) return null;
        return new List(actions).FirstOrDefault(w=>w.type == action);
    },[action])
    const history = useHistory(); 

    const employeeClicked = (el : number) => {
        switch(action){
            case "EVAL" : 
                history.push(`/new-evaluation/${el}`)
                break;
            case "TASK":
                history.push(`/new-action/${el}`)
                break;
        }
        props.onCancel()
    } 
    return <>
        {teamId == null ? <><Alert sx={{ mt: "4px", mb: "4px" }} severity="warning">{t("error.noTeamOnStart")}</Alert></> : <></>}
        {action == undefined? <>
            <ActionPicker hasTeam={teamId != null} onClick={(el) => {setAction(el);}}/>
        </> : 
            <>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                        color="inherit"
                        onClick={() => { setAction(null) }}
                    >
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {t("route.actions")}
                    </Link>
                    {actionProps != null ? <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                        color="inherit"
                        onClick={() => { history.push("/employees") }}
                    >
                        {actionProps.iconSmall}
                        {t(actionProps.text)}
                    </Link> : null}
                   
                </Breadcrumbs>
                <Alert sx={{ mt: "4px", mb: "4px" }} severity="info">{t(`wizard.selected.${action}`)}</Alert>
                <EmployeesPage onClick={(el) => { employeeClicked(el)} }/>
        </>}
        <Grid sx={{mt:2}} item xs={12} container justifyContent={"center"}>
            <Button onClick={props.onCancel} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                {t("general.cancel-btn")}
            </Button>

        </Grid>
    
    </>
}
export interface ActionPickerProps{
    onClick : (type : ActionType) => void;
    hasTeam : boolean;
}
export const ActionPicker = (props: ActionPickerProps) => {
    const [t] = useTranslation("common");
  


        return <>
        <Grid container spacing={2}>
                {actions.map((el) => {
                    return <>{
                        <Grid item style={el.needTeam && !props.hasTeam ? { opacity: 0.4 } : null }>
                            <OverviewBlock onClick={() => { if (el.needTeam && !props.hasTeam) { } else { props.onClick(el.type) }}} enableHoverAnimation={true} style={{textAlign:"center"}}>
                                <Grid item xs={12}>{el.icon}</Grid>
                                <Grid item xs={12}>{t(el.text)}</Grid>
                            </OverviewBlock>
                        </Grid>
                    }</>
                })}
        </Grid>
           
        </>
}