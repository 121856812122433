import { useRef } from "react";
import { UpdateOrganisationDto } from "../../clients/globis/models";
import React from "react";
import { Grid } from '@mui/material';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { LoadButton } from "../../components/loadButton";
import { useInjection } from '../../di/ioc-provider';
import { IOrganisationClient } from '../../clients/globis/organisationClient';
import { TYPES } from "../../di/types";
import Alert from '@mui/material/Alert';
import { parseAxiosError, timeoutQuery } from '../../components/helpers';
import Save from "@mui/icons-material/Save"
export interface UpdateOrganisationFormProps {
    onCancel: () => void;
    onSaved: () => void;
    id : number;
}

export const UpdateOrganisationForm = (props: UpdateOrganisationFormProps) => {
    const formRef = useRef(null);
    const [err, setErr] = React.useState("");
    const [formModel, setModel] = React.useState<UpdateOrganisationDto | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [t] = useTranslation("common");
    const _organisationClient = useInjection<IOrganisationClient>(TYPES.IOrganisationClient);
    React.useEffect(() => {
        _organisationClient.getOrganisationById(props.id).then((res) => {
            setModel(res.data);
        }).catch((err) => {
            setErr(t("error.orgNotFound"));
        })
    },[])
    const handleFormChanged = (col: string, value: string) => {
        var copy = { ...formModel };
        copy[col] = value;
        setModel(copy);
    };
    const saveBtnCalled = () => {
        setLoading(true);
        _organisationClient.updateOrganisation(formModel).takeAtLeast(timeoutQuery).then((res) => {
            props.onSaved();
        }).catch((err) => {
            setErr(parseAxiosError(err));
        }).finally(() => {
            setLoading(false);
        })
    }
    const saveValidate = () => {
        if (formRef != null && formRef.current != null) {
            var testForm = formRef.current as any;
            testForm
                .isFormValid()
                .then((result: boolean) => {
                    if (result) {
                        saveBtnCalled();
                    }
                })
                .catch((err: any) => { });
        }
    };
    if(formModel == undefined) return <></>
    return <>
        {err != "" ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="error">{t(err)}</Alert> : null}
        <ValidatorForm ref={formRef} onSubmit={() => { }}>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextValidator onChange={(event) => { handleFormChanged("name", event.target.value) }}
                        id="outlined-basic" value={formModel.name} className={"app-input-full-width"} label={t("organisation.name")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>

                <Grid item xs={12} container justifyContent={"flex-end"}>
                    <Button onClick={props.onCancel} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                        {t("general.cancel-btn")}
                    </Button>
                    <LoadButton isLoading={loading}
                        startIcon={<Save/>}
                        type="submit"
                        onClick={saveValidate}
                        className="no-box-shadow"
                        style={{ textTransform: "capitalize", marginLeft: "10px" }}
                        buttonText={t("general.update-btn")} />

                </Grid>
            </Grid>
        </ValidatorForm>

    </>
}