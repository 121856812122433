import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Grid, useTheme, Typography } from '@mui/material';
import Loader from "./Loader";
//SLOTS BASED ON: https://daveceddia.com/pluggable-slots-in-react-components/
export type maxWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
interface Dialog {
    fullWidth?: boolean,
    maxWidth?: maxWidth,
    open: boolean;
    onClose: (event: any) => void;
    title?: any; //TITLE SLOT
    actions?: any;
    children?: any; //NORMAL SLOT
    dialogTitleCssClassName?: string; //Gives the correct styling to the dialog
    dialogCssClassName?: string;
    isLoading?: boolean; //indicates the content is loading
    formInDialog?: boolean; //if a form is in the dialog Grid than containers will stop working. enable if you use grids..;,
    disableEnforceFocus?: boolean,
    transition? : any
}

const GenericDialog = (props: Dialog, ...rest) => {
    const [open, setOpen] = React.useState(props.open);
    const theme = useTheme();
    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    const onCloseDialog = (event: any) => {
        props.onClose(event);
        event.stopPropagation();
    };
    // const titleSlot = props.children.find(child => child.type == Title)

    return (
        <React.Fragment>
            <Dialog
            TransitionComponent={props.transition}
                onClick={(ev) => { ev.stopPropagation(); }}
                disableEnforceFocus={props.disableEnforceFocus}
                maxWidth={props.maxWidth}
                fullWidth={props.fullWidth}
                open={open}
                onClose={(e) => onCloseDialog(e)}
                aria-labelledby="form-dialog-title"
                className={
                    props.formInDialog != null
                        ? `${props.dialogCssClassName} form-dialog`
                        : `${props.dialogCssClassName}`
                }
            >
                <DialogTitle >
                    <Typography
                        className="alignCenter"
                        color={(theme as any).palette.text.heading}
                        variant="h3">
                        {props.title}
                    </Typography>
                </DialogTitle>
                <DialogContent className={props.dialogTitleCssClassName} style={{paddingTop:"5px"}} >
                    {props.isLoading === undefined || props.isLoading === false ? (
                        <React.Fragment>{props.children != null ? <>{props.children}</> : <></>}</React.Fragment>
                    ) : (
                        <Grid container justifyContent="center">
                            <Grid item >
                                <Loader />
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                {props.actions != undefined ? <DialogActions className="generic-dialog-actions">
                    {props.actions}
                </DialogActions> : null }
               
            </Dialog>
        </React.Fragment>
    );
};



export default React.memo(GenericDialog);
