import { inject, injectable } from 'inversify';
import "reflect-metadata";
import { PageDataRequest, PagedData, SearchModel, UserListDto, CreateUserDto, LoginModel, OrganisationListDto, CreateOrganisationDto, OrganisationSearchModel, CreateTeamDto, TeamListDto, UpdateTeamDto, TeamDetailDto, TaskDto, TaskSearchModel, UpdateTaskDto, InsertTaskDto, UpdateWorkAssigment, RemoveWorkAssignment, WorkAssigmentDto, CreateWorkAssigmentDto } from './models';
import Axios, { AxiosResponse } from "axios";
import IAppConfig from '../../config/config';
import { TYPES } from '../../di/types';


export interface ITaskClient {
    fetchTasks(req: PageDataRequest<TaskSearchModel>): Promise<AxiosResponse<PagedData<TaskDto>>>;
    getTask(req: number) : Promise<AxiosResponse<TaskDto>>
    updateTask(req: UpdateTaskDto): Promise<AxiosResponse>;
    insertTask(req: InsertTaskDto): Promise<AxiosResponse<number>>
    fetchWorkAssignments(org: number) : Promise<AxiosResponse<WorkAssigmentDto[]>>;
    updateWorkAssignment(req : UpdateWorkAssigment) : Promise<AxiosResponse<number>>
    deleteWorkAssignment(req: RemoveWorkAssignment) : Promise<AxiosResponse>;
    createWorkAssignment(req: CreateWorkAssigmentDto) : Promise<AxiosResponse>
}
@injectable()
export class TaskClient implements ITaskClient {

    apiUrl = process.env.REACT_APP_BFF_URL
    apiPrefix = "api/Tasks";

    @inject(TYPES.IAppConfig) private _config: IAppConfig;
    getTask(req: number) {
        return Axios.get(`${this._config.endpointUrl}${this.apiPrefix}/FindById/${req}`);
    }
    fetchTasks(req: PageDataRequest<TaskSearchModel>){
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/Search`, req);
    }
    updateTask(req: UpdateTaskDto): Promise<AxiosResponse> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/UpdateTask`, req);
    }
    insertTask(req: InsertTaskDto): Promise<AxiosResponse<number>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/CreateTask`, req);
    }
    fetchWorkAssignments(org : number) {
        return Axios.get(`${this._config.endpointUrl}${this.apiPrefix}/FetchAllWorkAssignments/${org}`);
    }
    updateWorkAssignment(req : UpdateWorkAssigment){
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/UpdateWorkAssignment`, req);
    }
    deleteWorkAssignment(req: RemoveWorkAssignment) {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/DeleteWorkAssignment`, req);
    }
    createWorkAssignment(req: CreateWorkAssigmentDto) {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/CreateWorkAssignment`, req);
    }
}