
import React from "react";
import { CardContent, Chip, Divider, Grid, Paper, PaperClassKey, StandardProps, Typography } from "@mui/material";
export interface IOverviewBlock extends StandardProps<React.HTMLAttributes<HTMLDivElement>, PaperClassKey> {

    title?: string,
    titleSlot?: any,
    children?: any,
    className?: string,
    style?: any,
    enableHoverAnimation?: boolean
    color?:string

}
export const OverviewBlock = (props: IOverviewBlock) => {
    const styling = () => {
        var styling = { boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", width: '100%',height:"100%", padding: "10px", paddingBottom: "10px", } as any;
        if (props.color != null) {
            styling.borderLeft = `10px solid ${props.color}`;
        }
        if (props.style != null) {
            styling = Object.assign(styling, props.style);
        }
        
        
        return styling;
    }
    const getClassName = () => {
        return `cardLight ${props.className ?? ''} ${props.enableHoverAnimation ? 'cardLightHoverEnabled' : ''}`
    }
  
    return (<Paper onClick={props.onClick}  className={getClassName()} style={styling()}>
        {props.title == undefined && props.titleSlot == undefined ? <>
        </> : <>
            <Typography className="subheader-title" variant="h6" component="h1">
                {props.title}
                {props.titleSlot != undefined ? <React.Fragment>{props.titleSlot}</React.Fragment> : null}

                <div style={{ marginBottom: "10px" }}></div>

            </Typography>
        </>}
        <Grid container alignItems={"flex-start"}>
            {props.children}
        </Grid>
    </Paper>)
}

export default OverviewBlock;