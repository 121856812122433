import { inject, injectable } from 'inversify';
 import "reflect-metadata";
import { PageDataRequest, PagedData, SearchModel, UserListDto, CreateUserDto, LoginModel, OrganisationSearchModel, PasswordForgotModel, ChangePasswordDto, UserDetailDto, UpdateUser } from './models';
import Axios, { AxiosResponse } from "axios";
import IAppConfig from '../../config/config';
import { TYPES } from '../../di/types';

export interface IUserApiClient{
    getUser(id: number): Promise<AxiosResponse<UserDetailDto>>
    updateUser(user: UpdateUser): Promise<AxiosResponse>
    fetchUsers(request: PageDataRequest<OrganisationSearchModel>): Promise<AxiosResponse<PagedData<UserListDto>>>;
    createUser(req : CreateUserDto) : Promise<AxiosResponse<number>>
    login(req : LoginModel) : Promise<AxiosResponse<string>>
    passwordForgot(req: PasswordForgotModel) : Promise<AxiosResponse>;
    changePassword(req: ChangePasswordDto): Promise<AxiosResponse<any>>;
}

@injectable()
export class UserApiClient implements IUserApiClient{
  
    apiUrl = process.env.REACT_APP_BFF_URL
    apiPrefix = "api/Users";

    @inject(TYPES.IAppConfig) private _config: IAppConfig;
    
    // public constructor(@inject(TYPES.IAppConfig) appConfig : IAppConfig){
    //     this.apiUrl = appConfig.endpointUrl;
    // }
    getUser(id : number): Promise<AxiosResponse<UserDetailDto>> {
        return Axios.get(`${this._config.endpointUrl}${this.apiPrefix}/getUser?id=${id}`);
    }
    updateUser(user: UpdateUser): Promise<AxiosResponse>{
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/UpdateUser`, user);
    }
    fetchUsers(request: PageDataRequest<OrganisationSearchModel>): Promise<AxiosResponse<PagedData<UserListDto>>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/FetchUsers`,request);
    }
    createUser(req: CreateUserDto): Promise<AxiosResponse<number>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/CreateUser`, req);
    }
    login(req: LoginModel): Promise<AxiosResponse<string>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/Login`, req);
    }
    passwordForgot(req: PasswordForgotModel): Promise<AxiosResponse<any>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/ResetPassword`, req);
    }
    changePassword(req: ChangePasswordDto): Promise<AxiosResponse<any>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/ChangePassword`, req);
    }
}
