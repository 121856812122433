import moment, { unitOfTime } from "moment";
import React, { useRef } from "react"
import { useTranslation } from "react-i18next";
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import ExpandMore from "@mui/icons-material/ExpandMore"
export interface FixedTimeMenuItem {
    amount: number,
    timeUnit: unitOfTime.Diff
}
export interface ICustomSelectFilterItem{
    id : number,
    val :string
}
export interface ICustomSelectFilter {
    defaultText : string,
    items: ICustomSelectFilterItem[],
    onItemClicked(item: ICustomSelectFilterItem) : void 
    startIcon?:any
}

export const CustomSelectList = (props: ICustomSelectFilter) => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | HTMLTextAreaElement>(null);
    const [open, setOpen] = React.useState<boolean>(false);
    const [openDateRangeDialog, setOpenDateRangeDialog] = React.useState(false);
    const [t] = useTranslation("common");
    const [title,setTitle] = React.useState(props.defaultText);
    const onMenuClicked = (item: ICustomSelectFilterItem) => {
        setTitle(item.val);
        setOpen(false)
        props.onItemClicked(item);
    }
    const TeamMenuItems = (item: ICustomSelectFilterItem) => {
        return <MenuItem onClick={() => { onMenuClicked (item)}}>
         {item.val}
        </MenuItem>
    }
    const RenderMenuItems = React.useCallback(() => {
        return props.items.map((el) => {
            return <>{TeamMenuItems(el)}</>
        })
    }, [props.items]);

    return <>
        <Button
        
            startIcon={props.startIcon}
            className="no-box-shadow full-width"
            style={{ textTransform: "none",fontSize:"1.1em" }}
            variant="contained"
            onClick={(e) => { setOpen(true); setAnchorEl(e.currentTarget) }}
            color="primary">
            {title}
        </Button>
        <Popover
            anchorEl={anchorEl}
            onClose={() => { setOpen(false) }}
            open={open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            style={{ maxHeight: "400px" }}
        >
            {RenderMenuItems()}
        </Popover>
    </>
}