import { Typography, useTheme, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import OverviewBlock from '../../components/overviewBlock';
import { cyan } from '@mui/material/colors';
import Radio from '@mui/material/Radio';
import GroupsIcon from "@mui/icons-material/Groups";
import Button from '@mui/material/Button';
import { LoadButton } from '../../components/loadButton';
import { useInjection } from '../../di/ioc-provider';
import { IEmployeeClient } from '../../clients/globis/employeeClient';
import { TYPES } from '../../di/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AvgScoreDto, EmployeeDto, EvaluationFicheDto, EvaluationQuestionDto, EvaluationScore, PerformEvaluationDto } from '../../clients/globis/models';
import React from 'react';
import { IContentClient } from '../../clients/globis/contentClient';
import { firework1, parseAxiosError, timeoutQuery } from '../../components/helpers';
import Alert from '@mui/material/Alert';
import { PreLoader } from '../../components/Loader';
import { useImmer } from 'use-immer';
import { List } from 'linqts';
import { useOrganisation } from '../../hooks/useOrganisation';
import { EvaluationFiche } from '../../components/EvaluationFiche';
import { useHistory } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import { LastEmployeeActions } from '../../components/LastEmployeeActions';
import Add from "@mui/icons-material/AddCircle"
import { UIStore } from '../../stores/UiStore';
import GeneralDialog from '../../components/generalDialog';
import Particles from "react-particles";
import type { Container, Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";
import sffjs from 'sffjs';
import ArrowBack from '@mui/icons-material/ArrowForwardIos';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import ReactToPrint from "react-to-print";
import Print from '@mui/icons-material/Print';
import { AuthStore } from '../../stores/AuthStore';
type TParams = { id?: string };
export interface CreateEvaluationProps {

}

export const CreateEvaluation = React.forwardRef((props: CreateEvaluationProps, ref: any) => {
    const id = React.useMemo(() => {
        var id = (props as any).match.params.id;
        if (id != undefined) {
            try {
                var id_ = Number.parseInt((props as any).match.params.id);
                return id_;
            } catch { }
        }
        return undefined;
    }, [(props as any).match.params.id]);


    useEffect(() => {
        if (id != undefined) {
            fetchEmployee(id);
        }
    }, [id])
    const particlesInit = useCallback(async (engine: Engine) => {

        // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {

    }, []);
    const _loggedInUser = AuthStore.useState(w => w.authSettings);
    const history = useHistory();
    const theme = useTheme();
    const _employeeClient = useInjection<IEmployeeClient>(TYPES.IEmployeeClient);
    const _contentClient = useInjection<IContentClient>(TYPES.IContentClient);
    const [employee, setEmployee] = useState<EmployeeDto | undefined>(undefined);
    const [template, setTemplate] = React.useState<EvaluationFicheDto | undefined>(undefined);
    const [templateLoading, setTemplateLoading] = React.useState(false);
    const [scores, setScores] = useImmer<EvaluationScore[]>([]);
    const [err, setErr] = React.useState("");
    const [isSaving, setSaving] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [t] = useTranslation("common");
    const [, organisation] = useOrganisation();
    const [actionExpanded, setActionExpanded] = React.useState<number | undefined>(undefined);
    const [avgScores, setAvgScores] = React.useState<AvgScoreDto[]>([]);
    let componentRef = useRef() as any;
    useEffect(() => {
        if (employee != null) {
            _contentClient.getFicheByEmployee(id)
                .then((res) => {
                    setTemplate(res.data)
                }).catch((err) => {
                    setErr(parseAxiosError(err));
                })
                .finally(() => {

                })
            _contentClient.getAvgScoreOnNewEvaluation(id).then((res) => {
                setAvgScores(res.data.scores);
            })
        }

    }, [employee])

    const saveEvaluation = () => {
        setSaving(true);
        setErr("");

        _contentClient.performNewEvaluation({
            employeeId: employee.id,
            organisation: organisation,
            ficheId: template.id,
            scores: scores
        }).takeAtLeast(timeoutQuery * 2).then((res) => {
            setSuccess(true);
            // setTimeout((el) => { history.push(`/employee/${employee.id}`) },timeoutQuery*2)

        }).finally(() => {


        }).catch((err) => {
            setErr(parseAxiosError(err));
            setSaving(false);
        })

    }
    const fetchEmployee = (id: number) => {
        _employeeClient.get(id)
            .then((res) => {
                setEmployee(res.data);
            })
    }
    const markScore = (ev: EvaluationQuestionDto, index: number) => {
        setScores((draft) => {
            var obj = new List(draft).FirstOrDefault(w => w.id == ev.id);
            if (obj == null) {
                draft.push({ id: ev.id, score: index + 1 });
            } else {
                obj.score = index + 1;
            }
        })
    }
    const getScore = (q: EvaluationQuestionDto) => {
        var scoreNr = 1;
        var score = new List(scores).FirstOrDefault(w => w.id == q.id);
        if (score != null) {
            scoreNr = score.score
        }

        return scoreNr;
    }
    const renderScore = (q: EvaluationQuestionDto) => {
        var scoreNr = getScore(q);
        return <><span className="noprint">
            {[...Array(template.score)].map((elementInArray, index) => {
                return <><Radio
                    onClick={() => {
                        markScore(q, index);
                    }}
                    disabled={isSaving}
                    checked={scoreNr > index}
                    value="a"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'A' }}
                /></>
            }
            )}
        </span>
           
        </>
    }
    const renderQuestion = (question: EvaluationQuestionDto) => {
        var avgScore = new List(avgScores).FirstOrDefault(w => w.evQuestionId == question.id);

        return <>
            <Grid item style={{ paddingLeft: "8px" }}>
                {renderScore(question)}
                <span className='noprint'
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                    {`${getScore(question)}.0`}
                </span>
                <GroupsIcon className='noprint' style={{ position: "relative", top: "4px" }} />
                <span className='noprint' style={{ marginLeft: "4px" }}
                >
                    {avgScore?.score?.toFixed(1) ?? 'N/A'}

                </span>
                <span className="only-print">
                    {[...Array(template.score)].map((elementInArray, index) => {
                        return <><Radio

                            disabled={false}
                            checked={false}
                            value="a"
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'A' }}
                        /></>
                    }
                    )}
                </span>
            </Grid>
        </>
    }

    const renderPersonName = () => {
        if (employee == undefined) return "";
        else {
            return `${employee.firstName} ${employee.lastName}`;
        }
    }
    interface ExpandMoreProps extends IconButtonProps {
        expand: boolean;
    }
    const ExpandMore = styled((props: ExpandMoreProps) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        position: "relative",
        bottom: "10px",
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    const inputDisabled = React.useMemo(() => {
        return template == null;
    }, [template])
    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    return <div ref={el => (componentRef = el)}>

        {err != "" ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="warning">{t(err)}</Alert> : null}
        {success ? <><Alert sx={{ mt: "4px", mb: "4px" }} severity="success">{t("general.createEvaluationSuccess")}</Alert></> : <></>}
        {employee != undefined ? <>
            <GeneralDialog transition={Transition} title={sffjs(t("general.createEvaluationSuccessDialogTitle"), `${employee.firstName} ${employee.lastName}`)} open={success} onClose={function (event: any): void {
            }}>
                <>
                    <Particles id="tsparticles" options={firework1 as any} init={particlesInit} loaded={particlesLoaded} />
                    <Grid container justifyContent={"end"}>
                        <Grid item>
                            <Button onClick={(el) => { history.push(`/employee/${employee.id}`) }} endIcon={<ArrowBack />} variant="contained">{t("general.createEvaluationSuccessButton")}</Button>
                        </Grid>
                    </Grid>
                </>
            </GeneralDialog></> : <></>}

        <Grid container sx={{ mb: 2 }} spacing={2} >
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                        color="inherit"
                        onClick={() => { history.push("/") }}
                    >
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {t("route.dashboard")}
                    </Link>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                        color="inherit"
                        onClick={() => { history.push("/employees") }}
                    >
                        <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {t("route.employees")}
                    </Link>

                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                        color="inherit"
                        onClick={() => { history.push(`/employee/${id}`) }}
                    >
                        <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {renderPersonName()}
                    </Link>
                </Breadcrumbs>
            </Grid>

            <Grid item container justifyContent="space-between" xs={12}>
                <Grid item>
                    <Typography
                        className="alignCenter"
                        color={(theme as any).palette.text.heading}
                        variant="h1" style={{ marginBottom: "20px" }}>
                        {t("general.createEvaluationFor")} {renderPersonName()}
                    </Typography>
                </Grid>

                <Grid item className="noprint">
                    <ReactToPrint
                        trigger={() => <Button startIcon={<Print />}>{t("general.print")}</Button>}
                        content={() => componentRef}
                        bodyClass="print-container"
                        removeAfterPrint={true}
                        copyStyles={true}
                    />
                </Grid>
            </Grid>
            <Grid item md={12}>
                <LastEmployeeActions employeeId={id} />
            </Grid>

        </Grid>

        <PreLoader loading={templateLoading}>
            <EvaluationFiche renderQuestion={renderQuestion} template={template} />
        </PreLoader>
        <Grid className="only-print" item xs={12} container justifyContent={"space-between"} style={{ marginTop: "8px" }}>
            <Grid item>
                {t("general.signature")} - {_loggedInUser.name}
            </Grid>
            <Grid item>
                {t("general.signature")} - {renderPersonName()}
            </Grid>
        </Grid>
        <Grid className="noprint" container justifyContent="space-between" sx={{ mt: "20px", mb: "20px" }}>
            <Grid item>
                {/* 
                 */}
            </Grid>
            <Grid item>
                <LoadButton
                    startIcon={<Add />}
                    disabled={inputDisabled || employee.archived}
                    className="no-box-shadow full-width"
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                    color="primary"
                    buttonText={t("general.save")}
                    onClick={() => { saveEvaluation() }}
                    isLoading={isSaving}>
                </LoadButton>
            </Grid>
        </Grid>
    </div>
})