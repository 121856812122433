import Save from "@mui/icons-material/Save";
import { Grid, Tooltip, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { UpdateTeamDto } from '../../clients/globis/models';
import { ITeamsClient } from '../../clients/globis/teamsClient';
import { EvDropdown } from "../../components/evaluationFicheDropdown";
import { parseAxiosError, timeoutQuery } from "../../components/helpers";
import { LoadButton } from "../../components/loadButton";
import { useInjection } from '../../di/ioc-provider';
import { TYPES } from "../../di/types";
import { useOrganisation } from "../../hooks/useOrganisation";
import DeleteIcon from '@mui/icons-material/Delete';
import GeneralDialog from "../../components/generalDialog";
export interface UpdateTeamProps {
    id: number,
    onCancel: () => void;
    onSaved: () => void;
    showDeleteButton: boolean
}


export const UpdateTeamForm = (props: UpdateTeamProps) => {
    const formRef = useRef(null);
    const [err, setErr] = React.useState("");
    const [t] = useTranslation("common");
    const [, organisationId] = useOrganisation();
    const [formModel, setModel] = React.useState<UpdateTeamDto | undefined>(undefined);
    const [loading, setLoading] = React.useState(false);
    const _teamClient = useInjection<ITeamsClient>(TYPES.ITeamsClient);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [removingError, setRemovingError] = React.useState(undefined);
    const [removing, setRemoving] = React.useState(false);
    React.useEffect(() => {
        _teamClient.getTeam(props.id).then((res) => {
            setModel({
                name: res.data.name,
                id: res.data.id,
                evaluationFicheId: res.data.evaluationFicheId,
                organisation: res.data.organisation
            })
        })

    }, [])
    const handleFormChanged = (col: string, value: string) => {
        var copy = { ...formModel };
        copy[col] = value;
        setModel(copy);
    };
    const saveBtnCalled = () => {
        setLoading(true);

        _teamClient.updateTeam(formModel).takeAtLeast(timeoutQuery)
            .then((res) => {
                props.onSaved();
            })
            .catch((err) => {
                setErr(parseAxiosError(err));
            }).finally(() => {
                setLoading(false);
            })
    }
    const saveValidate = () => {
        if (formRef != null && formRef.current != null) {
            var testForm = formRef.current as any;
            testForm
                .isFormValid()
                .then((result: boolean) => {
                    if (result) {
                        saveBtnCalled();
                    }
                })
                .catch((err: any) => { });
        }
    };
    const deleteTeam = () => {
        setRemoving(true);
        setRemovingError(undefined);
        _teamClient.deleteTeam({ id: formModel.id }).takeAtLeast(timeoutQuery).then(() => {
            setOpenDeleteDialog(false);
            props.onCancel();
        }).finally(() => {
            setRemoving(false);
        }).catch((err) => {
            setRemovingError(t("general.removingTeamsError"))
        })
    }
    if (formModel == undefined) return <></>
    return <>
        <GeneralDialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
            <Grid container>
                <Grid item xs={12}>
                    <Alert severity="warning" >
                        <Typography
                            variant="body2">
                            {t("general.removeTeamWarning")}
                        </Typography>
                    </Alert>
                    {removingError ? <>
                        <Alert severity="error" >
                            <Typography
                                variant="body2">
                                {removingError}
                            </Typography>
                        </Alert>
                    </> : <></>}
                </Grid>
            </Grid>
            <Grid sx={{ mt: "4px" }} item xs={12} container justifyContent={"flex-end"}>
                <Button onClick={() => setOpenDeleteDialog(false)} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                    {t("general.cancel-btn")}
                </Button>
                <LoadButton color={"error"} startIcon={<DeleteIcon />} isLoading={removing}
                    type="submit"
                    onClick={() => { deleteTeam() }}
                    className="no-box-shadow"
                    style={{ textTransform: "capitalize", marginLeft: "10px" }}
                    buttonText={t("general.delete-btn")} />
            </Grid>

        </GeneralDialog>
        {err != "" ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="warning">{t(err)}</Alert> : null}
        <ValidatorForm ref={formRef} onSubmit={() => { }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextValidator disabled={loading} onChange={(event) => { handleFormChanged("name", event.target.value) }}
                        id="outlined-basic" value={formModel.name} className={"app-input-full-width"} label={t("team.name")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <EvDropdown defaultEvFicheId={formModel.evaluationFicheId} onChange={(id) => { var copy = { ...formModel }; copy.evaluationFicheId = id; setModel(copy); }} />
                </Grid>
                <Grid item xs={12} container justifyContent={"flex-end"}>
                    <Button onClick={props.onCancel} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                        {t("general.cancel-btn")}
                    </Button>

                    <Tooltip title={t("general.deleteTeamDisabled")}>
                        <span><Button disabled={!props.showDeleteButton} startIcon={<DeleteIcon />} sx={{ mr: 2, ml: 2 }} onClick={() => { setOpenDeleteDialog(true) }} variant="outlined" style={{ textTransform: "capitalize" }} color="error">
                            {t("general.delete-btn")}
                        </Button></span>

                    </Tooltip>

                    <LoadButton isLoading={loading}
                        startIcon={<Save />}
                        type="submit"
                        onClick={saveValidate}
                        className="no-box-shadow"
                        style={{ textTransform: "capitalize" }}
                        buttonText={t("general.update-btn")} />

                </Grid>
            </Grid>
        </ValidatorForm>
    </>
}