
import { Container, interfaces } from 'inversify';
import * as React from "react";
import { useContext } from 'react';
const InversifyContext = React.createContext<{ container: Container | null }>({ container: null });

type Props = {
    container: Container;
    children: any;
};

export const IocProvider: React.FC<Props> = (props) => {
    return (
        <InversifyContext.Provider value={{ container: props.container }}>
            {props.children}
        </InversifyContext.Provider>
    );
};
export function useMultipleInjection<T>(identifier : interfaces.ServiceIdentifier<T>){
    const { container } = useContext(InversifyContext); 
    if (!container) { throw new Error(); }

    return container.getAll<T>(identifier);
}
export function useInjection<T>(identifier: interfaces.ServiceIdentifier<T>) {

    const { container } = useContext(InversifyContext); 
    if (!container) { throw new Error(); }

    return container.get<T>(identifier);
};