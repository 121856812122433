import { SortOrder } from "../../components/dcDataTable";

export interface PageDataRequest<T> {
    skip: number,
    take: number,
    payload?: T
    sortColumn?: string;
    sortOrder?: SortOrder;
}
export interface PaginationData {
    skip: number,
    take: number,
    entityCount: number
}
export interface SearchModel {
    search: string;
}
export interface EmployeeSearchModel extends OrganisationSearchModel {
    team?: number | null;
}
export interface OrganisationSearchModel extends SearchModel {
    organisation?: number | null;
}
export interface PagedData<T> extends PaginationData {
    data: T[],

}
export interface UserListDto {
    userName: string;
    firstName: string;
    lastName: string;
    id: number;
    created: string;
    updated: Date;
    createdByName: string;
    createdBy?: Date | null;
    role : Roles,
    team?:number |null
    archived: boolean
}

export interface UserDetailDto extends UserListDto {

}

export interface CreateUserDto {
    userName: string;
    firstName: string;
    lastName: string;
    role : Roles,
    organisation?:number|null
    team?:number|null
}
export interface OrganisationListDto {
    id: number;
    name: string;
    createdBy: number;
    createdByName: string;
    updated: Date;
}

export interface CreateOrganisationDto {
    name: string;
    sendPwdForgotMail: boolean;
    userFirstName: string;
    userName: string;
    userLastName: string;
}
export interface LoginModel {
    userName: string;
    password: string;
}
export interface PasswordForgotModel{
    email : string
}
export interface ChangePasswordDto {
    token: string;
    password: string;
}
export enum Roles {
    User = 1,
    SuperVisor = 2,
    Admin = 3
}
export interface OrganisationableDto {
    organisation: number;
}

export interface CreateTeamDto extends OrganisationableDto {
    name: string;
    evaluationFicheId: number | null;
}
export interface DeleteTeamDto{
    id : number
}
export interface TeamListDto {
    id: number;
    name: string;
    created: Date;
    updated: Date;
    createdBy: number | null;
    createdByName: string;
    lastEvaluationCreated: Date | null;
    lastEvaluationCreatedByName: string;
    amtEmployees:number
}
export interface CreateEvaluationFicheDto extends OrganisationableDto {
    name: string;
    score: number;
    organisation: number;
    team?: number;
    ogFicheId?: number | null;
    questions: CreateEvaluationQuestionDto[];
}
export interface UpdateEvaluationFiche {
    name: string;
    score: number;
    id?: number;
    questions: UpdateOrCreateEvaluationQuestionDto[];
}
export interface RemoveEvaluationFiche {
    id : number
}
export interface UpdateOrCreateEvaluationQuestionDto {
    id?: number | null;
    question?: string | null;
    order: number;
    level: EvaluationQuestionLevel;
    ogQuestionId?: number | null;
    children: UpdateOrCreateEvaluationQuestionDto[];
    color?: string | null;
}
export interface CreateEvaluationQuestionDto {
    question: string;
    order: number;
    level: EvaluationQuestionLevel;
    ogQuestionId?: number | null;
    children: CreateEvaluationQuestionDto[];
}

export interface CreateEvaluationQuestionFormModel {
    id?:number,
    question: string;
    order: number;
    level: EvaluationQuestionLevel;
    ogQuestionId?: number | null;
    children: CreateEvaluationQuestionFormModel[];
    color?:string,
}
export interface CreateUpdateEvaluationFicheFormModel{
    id? : number,
    name: string;
    score: number;
    organisation: number;
    team?: number;
    ogFicheId?: number | null;
    questions: CreateEvaluationQuestionFormModel[];
}
export interface EvaluationFicheListDto extends OrganisationableDto {
    id: number;
    name: string;
    score: number;
    organisation: number;
    team: number;
    ogFicheId: number | null;
    createdBy : number;
    createdByName : string,
    updated:Date
    created:Date
}
export interface EvaluationJsonModel {
    score: number;
    question: string;
    color: string;
}
export interface EvaluationFicheDto extends OrganisationableDto {
    id: number;
    name: string;
    score: number;
    organisation: number;
    team: number;
    ogFicheId: number | null;
    questions: EvaluationQuestionDto[];
}

export interface EvaluationQuestionDto {
    id: number;
    question: string;
    order: number;
    level: EvaluationQuestionLevel;
    ogQuestionId: number | null;
    children: EvaluationQuestionDto[];
    color?:string,
    updated: Date
}
export enum EvaluationQuestionLevel {
    HEADING,
    CATEGORIE,
    QUESTION
}
export interface EvaluationFicheSearchModel extends OrganisationSearchModel {
    team?: number | null;
    includeForeignFiches : boolean;
}
export interface EmployeeDto {
    id : number
    firstName: string;
    lastName: string;
    team: number;
    createdBy: number | null;
    createdByName: string;
    created: Date;
    updated: Date;
    teamName :string
    archived : boolean
}

export interface CreateEmployeeDto {
    firstName: string;
    lastName: string;
    team: number;
}

export interface UpdateEmployeeDto {
    id: number;
    firstName: string;
    lastName: string;
    team: number;
    archived:boolean
}
export interface EvaluationScore {
    score: number,
    id: number,
    ogQuestionId? : number|null
}
export interface PerformEvaluationDto extends OrganisationableDto {
    employeeId: number;
    ficheId: number;
    organisation: number;
    scores: EvaluationScore[];

}
export interface PerformedEvaluationDetailDto extends OrganisationableDto {
    employeeId: number;
    fiche: EvaluationFicheDto;
    organisation: number;
    scores: EvaluationScore[];
    updated : Date,
    createdByName : string,
    updatedByName : string,
    number : number
}
export interface DashboardEvaluationSearchModel extends OrganisationSearchModel {

    teamId : number
}
export interface UpdateEvaluationDto {
    id: number;
    scores: EvaluationScore[];
}
export interface EvaluationDto extends OrganisationableDto {
    id: number;
    name: string;
    createdBy: number | null;
    createdByName: string;
    created: Date;
    organisation: number;
    number: number;
    scoreSummary: EvaluationJsonModel[];
    employee: number;
    empFirstName: string;
    empLastName: string;
}
export interface UpdateTeamDto extends OrganisationableDto {
    id: number;
    name: string;
    evaluationFicheId: number;
    organisation: number;
}
export interface TeamDetailDto {
    id: number;
    name: string;
    createdBy: number | null;
    createdByName: string;
    created: string;
    evaluationFicheId: number | null;
    organisation : number,
}
export interface UserDetailDto extends UserListDto {

}
export interface UpdateUser {
    id: number;
    firstName: string;
    lastName: string;
    userName: string;
    role: Roles;
    team?:number;
    archived:boolean
}
export interface UpdateOrganisationDto {
    id: number;
    name: string;
}
export interface OrganisationDetailDto extends OrganisationListDto {

}
export interface EvaluationDashboardModel {
    evaluation: EvaluationDto;
    scores: EvaluationScore[];
}
export interface TaskDto {
    createdBy: number | null;
    createdByName: string;
    text: string;
    taskType: TaskType;
    created: Date;
    updated: Date;
    employee: number
    id : number
}

export interface InsertTaskDto {
    text: string;
    taskType: TaskType;
    employee: number;
}

export interface UpdateTaskDto {
    id: number;
    text: string;
    taskType: TaskType;
}
export enum TaskType {
    TalkInOffice,
    FeedbackTalk,
    EvaluationTalk,
    PIP,
    TeamTalk,
    OfficeTask,
}
export enum WorkTaskType{
    Assertiviteit,
    Initiatief,
    Besluitvaardigheid,
    Betrokkenheid,
    Creativiteit,
    Delegeren,
    Stressbestendigheid,
    Enthousiasme,
    Flexibiliteit,
    Empathie,
    Integriteit,
    Klantgerichtgerichtheid,
    Leervermogen,
    leidinGeven,
    luisteren,
    MondelingeCom,
    Onafhankelijkheid,
    Coaching,
    Oordeelsvorming,
    Organisatiesensitiviteit,
    Overtuigingskracht,
    plannen,
    presentatievaardigheden,
    prestatiemotivatie,
    probleemanalyse,
    SchriftelijkeCom,
    sociale,
    Samenwerken,
    Vasthoudendheid,
    Zelfinzicht,
    Zelfontplooing,
    Visie,
    Nauwkeurigheid,
    Voorzichtigheid,
    Bedrijfsidentificatie,
    Durf,
    Voortgangscontrole
}
export interface TaskSearchModel extends OrganisationSearchModel {
    employeeId: number | null;
}
export interface AvgScoreByTeamReq {
    teamIds: number[];
}
export interface AvgScoresByTeamResponse {
    scores: AvgScoreDto[];
}
export interface ScoresByEvIdReq {
    evaluations: number[];
}
export interface AvgAnsweredQuestionDto {
    ogQuestionId: number;
    score: number;
    team: number;
}
export interface TeamableDto {
    team: number;
}
export interface AvgScoreDto extends TeamableDto {
    team: number;
    evQuestionId: number;
    level: EvaluationQuestionLevel;
    score: number;
    color: string;
    question: string;
}
export interface AppExportInvoiceDto {
    organisation: string;
    amtTeams: number;
    amtNewTeams: number;
    amtUsers: number;
    amtNewUsers: number;
}
export interface ExportDto {
    date: Date;
}
export interface WorkAssigmentDto {
    id: number;
    text: string;
    group: string;
    createdByName: string;
    created: Date;
    updated: Date;
    type?: WorkTaskType | null;
}

export interface CreateWorkAssigmentDto {
    text: string;
    group: string;
    type?:WorkTaskType|null;
    organisation : number,
}

export interface UpdateWorkAssigment {
    id: number;
    text: string;
}

export interface RemoveWorkAssignment {
    id: number;
}