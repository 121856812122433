import { inject, injectable } from 'inversify';
import "reflect-metadata";
import { PageDataRequest, PagedData, SearchModel, UserListDto, CreateUserDto, LoginModel, OrganisationListDto, CreateOrganisationDto, OrganisationDetailDto, UpdateOrganisationDto as UpdateOrganisationDto } from './models';
import Axios, { AxiosResponse } from "axios";
import IAppConfig from '../../config/config';
import { TYPES } from '../../di/types';
export interface IOrganisationClient{
    getOrganisationById(id: number): Promise<AxiosResponse<OrganisationDetailDto>>;
    updateOrganisation(req: UpdateOrganisationDto): Promise<AxiosResponse>;
    fetchOrganisations(request: PageDataRequest<SearchModel>): Promise<AxiosResponse<PagedData<OrganisationListDto>>>;
    createOrganisation(req: CreateOrganisationDto): Promise<AxiosResponse<number>>
}

@injectable()
export class OrganisationClient implements IOrganisationClient {

    apiUrl = process.env.REACT_APP_BFF_URL
    apiPrefix = "api/Organisations";

    @inject(TYPES.IAppConfig) private _config: IAppConfig;

    getOrganisationById(id: number): Promise<AxiosResponse<OrganisationDetailDto>> {
        return Axios.get(`${this._config.endpointUrl}${this.apiPrefix}/GetById?id=${id}`);
    }
    updateOrganisation(req: UpdateOrganisationDto): Promise<AxiosResponse> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/Update`, req);
    }
    fetchOrganisations(request: PageDataRequest<SearchModel>): Promise<AxiosResponse<PagedData<OrganisationListDto>>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/FetchOrganisations`, request);
    }
    createOrganisation(req: CreateOrganisationDto): Promise<AxiosResponse<number>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/CreateOrganisation`, req);
    }

}