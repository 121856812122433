import { Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import EnhancedTable from "../components/enhancedTable";
import OverviewBlock from "../components/overviewBlock";
import { useTeam } from "../hooks/useTeam";
import Skeleton from '@mui/material/Skeleton';
import { blue, red, purple, cyan, green } from "@mui/material/colors";
import React from "react";
import { RouterLink, useHistory } from 'react-router-dom';
import { useInjection } from '../di/ioc-provider';
import { IContentClient } from '../clients/globis/contentClient';
import { TYPES } from "../di/types";
import { SortOrder, TableFilterState } from "../components/dcDataTable";
import { createChartData, defaultFilter, shadeColor } from "../components/helpers";
import { EvaluationDashboardModel, EvaluationFicheDto, AvgScoreDto } from '../clients/globis/models';
import { List } from "linqts";
import { useOrganisation } from '../hooks/useOrganisation';
import Link from '@mui/material/Link';
import { GlobisRadarChart } from "../components/GlobisRadarChart";
import { scoreColor } from "../components/taskTypeMapper";
import Alert from '@mui/material/Alert';
import { maxWidth } from '../components/generalDialog';
export const DashboardPage = () => {
    const history = useHistory();
    const theme = useTheme();
    const [t] = useTranslation("common");
    const _contentClient = useInjection<IContentClient>(TYPES.IContentClient);
    const [renderTeamPicker, teamId] = useTeam({ selectFirstTeam: true });
    const [, org] = useOrganisation();
    const [filterState, setFilter] = React.useState<TableFilterState>({
        search: "",
        skip: 0,
        take: 100,
        sortOrder: SortOrder.DESC,
        sortColumn: "Updated"
    });
    const [dashboardItems, setDashboardItems] = React.useState<EvaluationDashboardModel[]>([]);
    const [evFiche, setEvFiche] = React.useState<EvaluationFicheDto | undefined>(undefined);
    const [entityCount, setEntityCount] = React.useState<number>(0);
    const [scores, setScores] = React.useState<AvgScoreDto[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [isInit,setIsInit] = React.useState(false);
    React.useEffect(() => {
        setTimeout(() => {
            setIsInit(true);
        },500)
    },[])
    React.useEffect(() => {
        if (teamId != null) {
            setEvFiche(undefined);
            setDashboardItems([]);
            fetchEmployeeData();
            fetchFiche();
            fetchAvgScores();
        }
    }, [teamId, org])
    const fetchAvgScores = () => {
        setScores([]);
        _contentClient.getAvgScoresByTeams({ teamIds: [teamId] }).then((res) => {
            setScores(res.data.scores);
        })
    }
    const fetchFiche = () => {
        _contentClient.getFicheByTeamId(teamId).then((res) => {
            setEvFiche(res.data);
        })
    }
    const fetchEmployeeData = () => {
        _contentClient.dashboardEmployeeStats({
            skip: filterState.skip,
            take: filterState.take,
            payload: {
                teamId: teamId,
                search: "",
                organisation: org
            }
        }).then((res) => {
            setDashboardItems(res.data.data);
        })
    }
    const getScoreByQuestionId = (id: number) => {
        return new List(scores).FirstOrDefault(w => w.evQuestionId == id)?.score ?? 0;
    }
    const employeeGraphData = React.useMemo(() => {
        return createChartData(evFiche, dashboardItems)
    }, [evFiche, dashboardItems])
    return <>
        {teamId == null && isInit ? <><Alert sx={{ mt: "4px", mb: "4px" }} severity="warning">{t("error.noTeamOnDashboard")}</Alert></> : <></>}
        <Grid container justifyContent={"space-between"}>
            <Grid item xs={12} md={8}>
                <Typography
                    className="alignCenter"
                    color={(theme as any).palette.text.heading}
                    variant="h1">
                    {t("route.dashboard")}
                </Typography>
            </Grid>
            <Grid item xs={12} md={4} style={{textAlign:"end"}}>
                {renderTeamPicker()}
            </Grid>
        </Grid>
        <Grid container style={{ paddingTop: "30px" }}>

            <table className="table table-header-rotated">
                <thead>
                    <tr>
                        <th></th>
                        {evFiche != undefined ? <>
                            {evFiche.questions.map((headers, index) => {
                                return headers.children.map((category, index) => {
                                    return <th className="rotate"><div><span>{category.question}</span></div></th>
                                })
                            })}
                        </> : <></>}
                    </tr>
                </thead>
                <tbody>
                    {evFiche != undefined ? <>
                        {dashboardItems.map((el) => {
                            return <><tr>
                                <th  className="row-header"><Link onClick={() => {
                                    history.push(`/employee/${el.evaluation.employee}`);
                                }} href={"#"} underline="hover">
                                    {el.evaluation.empFirstName} {el.evaluation.empLastName}
                                </Link></th>
                                {evFiche.questions.map((headers, index) => {
                                    return headers.children.map((category, index) => {
                                        var score = new List(el.scores).FirstOrDefault(w => w.ogQuestionId == category.id);
                                        if (score == undefined) return <td>n/a</td>
                                        
                                        var scoreStylings = scoreColor(evFiche.score, score.score);
                                        if (score != undefined) return <td style={{ background: scoreStylings?.background, color: scoreStylings?.color  }}>{score.score}</td>
                                        return <td>n/a</td>
                                    })
                                })}
                            </tr>
                            </>
                        })}
                    </> : <></>}
                    <tr>
                        <th></th>
                        {evFiche != undefined ? <>
                            {evFiche.questions.map((header, index) => {
                                return <><td style={{ backgroundColor: header.color, color: header.color != null ? "white" : "black" }} colSpan={header.children.length}>{header.question}</td></>
                            })}</> : <></>}

                    </tr>
                </tbody>
            </table>
        </Grid>
        {loading ? <><DashboardLoading /></> : <>
            <Grid container spacing={2} style={{ paddingTop: "30px" }}>
                {evFiche != null ? <>
                    {evFiche.questions.map((el) => {
                        return <Grid  item xs={12} md={12 / evFiche.questions.length} container spacing={1} style={{height:"fit-content"}}>
                            <Grid item xs={12}>
                                <OverviewBlock style={{ height: "auto" }} color={el.color}>
                                    <SingleDigitWidget score={getScoreByQuestionId(el.id)} title={el.question} alignment="end" />
                                </OverviewBlock>
                            </Grid>
                            <Grid item xs={12} container spacing={1}>
                                {el.children.map((el) => {
                                    return <Grid item   >
                                        <OverviewBlock style={{ height: "auto" }} color={el.color}>
                                            <SingleDigitWidget score={getScoreByQuestionId(el.id)} title={el.question} alignment="center" />
                                        </OverviewBlock>
                                    </Grid>
                                })}
                            </Grid>
                        </Grid>
                    })}
                </> : <>
                </>}
            </Grid>
            {/* <Grid container spacing={2} style={{ paddingTop: "30px" }}>
                {evFiche != null ? <>
                    {evFiche.questions.map((el) => {
                        return <>
                            {el.children.map((el) => {
                                return <Grid item xs={12} md={2} sm={6} lg={1}>
                                    <OverviewBlock color={el.color}>
                                        <SingleDigitWidget score={getScoreByQuestionId(el.id)} title={el.question} alignment="center" />
                                    </OverviewBlock>
                                </Grid>
                            })}
                        </>
                    })}
                </> : <>
                </>}
            </Grid> */}
            <Grid container spacing={2} style={{ paddingTop: "30px" }}>
                <Grid item md={4}>  {employeeGraphData != undefined ? <><GlobisRadarChart data={employeeGraphData} /></> : <></>}</Grid>

            </Grid>
        </>
        }
    </>
}
export interface ISingleDigitWidgetProps {
    title: string,
    alignment: any,
    score?: number | undefined
}
export const SingleDigitLoading = (props: ISingleDigitWidgetProps) => {
    return <><div style={{ width: "100%", textAlign: props.alignment }}>
        <Skeleton width={"100%"}></Skeleton><br />
        <Skeleton style={{ textAlign: "end" }} width={"100%"}></Skeleton>
    </div>

    </>
}
export const SingleDigitWidget = (props: ISingleDigitWidgetProps) => {

    const theme = useTheme();

    return <><div style={{ width: "100%", height: "100%", textAlign: props.alignment }}>
        <span style={{ color: theme.palette.text.secondary, wordBreak: "break-word", fontSize: "0.9rem" }} >{props.title}</span><br />
        <span style={{ fontSize: "2rem" }}>{props.score}</span>
    </div>

    </>
}
export const DashboardLoading = () => {
    return <>
        <Grid container spacing={2} style={{ paddingTop: "30px" }}>
            <Grid item xs={12} md={3}>
                <OverviewBlock color={red[800]}>
                    <SingleDigitLoading score={0} title="Gemiddelde score compententies" alignment="end" />
                </OverviewBlock>
            </Grid>
            <Grid item xs={12} md={3}>
                <OverviewBlock color={cyan[800]}>
                    <SingleDigitLoading title="Gemiddelde score compententies" alignment="end" />
                </OverviewBlock>
            </Grid>
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: "30px" }}>
            <Grid item xs={12} md={1}>
                <OverviewBlock ><SingleDigitLoading title="Werving & selectie" alignment="center" />
                </OverviewBlock>
            </Grid>
            <Grid item xs={12} md={1}>
                <OverviewBlock ><SingleDigitLoading title="Werving & selectie" alignment="center" />
                </OverviewBlock>
            </Grid>
            <Grid item xs={12} md={1}>
                <OverviewBlock ><SingleDigitLoading title="Werving & selectie" alignment="center" />
                </OverviewBlock>
            </Grid>
            <Grid item xs={12} md={1}>
                <OverviewBlock ><SingleDigitLoading title="Werving & selectie" alignment="center" />
                </OverviewBlock>
            </Grid>
            <Grid item xs={12} md={1}>
                <OverviewBlock ><SingleDigitLoading title="Werving & selectie" alignment="center" />
                </OverviewBlock>
            </Grid>
            <Grid item xs={12} md={1}>
                <OverviewBlock ><SingleDigitLoading title="Werving & selectie" alignment="center" />
                </OverviewBlock>
            </Grid>
            <Grid item xs={12} md={1}>
                <OverviewBlock ><SingleDigitLoading title="Werving & selectie" alignment="center" />
                </OverviewBlock>
            </Grid>
            <Grid item xs={12} md={1}>
                <OverviewBlock ><SingleDigitLoading title="Werving & selectie" alignment="center" />
                </OverviewBlock>
            </Grid>
            <Grid item xs={12} md={1}>
                <OverviewBlock ><SingleDigitLoading title="Werving & selectie" alignment="center" />
                </OverviewBlock>
            </Grid>
            <Grid item xs={12} md={1}>
                <OverviewBlock ><SingleDigitLoading title="Werving & selectie" alignment="center" />
                </OverviewBlock>
            </Grid>
            <Grid item xs={12} md={1}>
                <OverviewBlock ><SingleDigitLoading title="Werving & selectie" alignment="center" />
                </OverviewBlock>
            </Grid>
            <Grid item xs={12} md={1}>
                <OverviewBlock ><SingleDigitLoading title="Werving & selectie" alignment="center" />
                </OverviewBlock>
            </Grid>
        </Grid>
    </>
}