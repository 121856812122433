import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';


const ITEM_HEIGHT = 48;
export interface DcMenuItem {
    option : string,
    id : number
}
export interface DcMenuProps{
    options: DcMenuItem[],
    onClick: (id : number) => void
}
export default function DcMenu(props: DcMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 5.5,
                        width: '20ch',
                    },
                }}
            >
                {props.options.map((option) => (
                    <MenuItem key={option.id}  onClick={(ev) => {
                        props.onClick(option.id);
                        handleClose();
                    }}>
                        {option.option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}