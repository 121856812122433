import React, { useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { AuthStore } from '../stores/AuthStore';
import { UIStore } from "../stores/UiStore";
export interface AxiosInterceptorProps {

}

const AxiosErrorInterceptor = (props: AxiosInterceptorProps) => {
    const [t, i18n] = useTranslation("common");
    const accessToken = AuthStore.useState(s => s.authSettings.jwtToken);

    React.useEffect(() => {
        for(var i=0;i<(axios.interceptors.request as any).handlers.length;i++){
            axios.interceptors.request.eject(i);
        }

        axios.interceptors.request.use(
            (config) => {
                var bearer = accessToken;
                if (bearer != "")// if a bearer token is found add it
                    config.headers.authorization = `Bearer ${accessToken}`;

                return config;
            },
            (error) => Promise.reject(error)
        );

        axios.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data

            //Treat no content as undefineds
            if (response.status == 204) {
                response.data = undefined;
            }
            return response;
        }, function (error) {

            UIStore.update(w => { w.globalMessage = { type: "error", message: `${error}` } });

            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
        });
    }, [accessToken])

    return <React.Fragment></React.Fragment>
};

export default React.memo(AxiosErrorInterceptor);
