import { injectable } from "inversify";
import "reflect-metadata";
export default interface IAppConfig {
    endpointUrl: string,
}

@injectable()
export class DefaultAppConfig implements IAppConfig {
    endpointUrl: string;
    constructor() {
    //this.endpointUrl ="http://localhost:5041/";
    this.endpointUrl = "https://globisapi.deercode.be/";
    }
    stationName: string;
}
