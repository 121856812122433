import { inject, injectable } from 'inversify';
import "reflect-metadata";
import { PageDataRequest, PagedData, SearchModel, UserListDto, CreateUserDto, LoginModel, OrganisationListDto, CreateOrganisationDto, OrganisationSearchModel, CreateTeamDto, TeamListDto, UpdateTeamDto, TeamDetailDto, DeleteTeamDto } from './models';
import Axios, { AxiosResponse } from "axios";
import IAppConfig from '../../config/config';
import { TYPES } from '../../di/types';


export interface ITeamsClient{
    fetchTeams(req: PageDataRequest<OrganisationSearchModel>): Promise<AxiosResponse<PagedData<TeamListDto>>>;
    createTeam(req : CreateTeamDto) : Promise<AxiosResponse<number>>
    updateTeam(req : UpdateTeamDto) : Promise<AxiosResponse>;
    getTeam(id : number) : Promise<AxiosResponse<TeamDetailDto>>;
    deleteTeam(req: DeleteTeamDto): Promise<AxiosResponse>;
}
@injectable()
export class TeamsClient implements ITeamsClient{
    
    apiUrl = process.env.REACT_APP_BFF_URL
    apiPrefix = "api/Teams";

    @inject(TYPES.IAppConfig) private _config: IAppConfig;


    updateTeam(req: UpdateTeamDto): Promise<AxiosResponse<any>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/UpdateTeam`, req);
    }
    getTeam(id: number): Promise<AxiosResponse<TeamDetailDto>> {
        return Axios.get(`${this._config.endpointUrl}${this.apiPrefix}/GetTeam?id=${id}`);
    }
    fetchTeams(req: PageDataRequest<OrganisationSearchModel>): Promise<AxiosResponse<PagedData<TeamListDto>>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/FetchTeams`, req);
    }
    createTeam(req: CreateTeamDto): Promise<AxiosResponse<number>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/CreateTeam`, req);
    }
    deleteTeam(req: DeleteTeamDto): Promise<AxiosResponse> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/DeleteTeam`, req);
    }
}