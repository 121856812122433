import React from "react"
import MainLayout from "../layouts/mainLayout"
import Loader from "./Loader"


export const JsStillRendering = () => {

    return <React.Fragment>
        <MainLayout>
            <React.Fragment>
                <Loader />
            </React.Fragment>
        </MainLayout>
    </React.Fragment >
}