import React from "react";
import { useRef } from "react";
import { useInjection } from '../../di/ioc-provider';
import { IUserApiClient } from '../../clients/globis/userApiClient';
import { TYPES } from "../../di/types";
import { CreateUserDto, Roles, UpdateUser as UpdateUserDto } from '../../clients/globis/models';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useOrganisation } from '../../hooks/useOrganisation';
import Button from '@mui/material/Button';
import { LoadButton } from "../../components/loadButton";
import { parseAxiosError, timeoutQuery } from "../../components/helpers";
import Loader from "../../components/Loader";
import { useTeam } from "../../hooks/useTeam";
import { AuthStore } from '../../stores/AuthStore';
import { TeamPicker } from '../../components/teamPicker';
import Save from "@mui/icons-material/Save"
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
export interface UpdateUserProps {
    onCancel: () => void;
    onSaved: () => void;
    id : number
}

export const UpdateUserForm = (props: UpdateUserProps) => {
    const formRef = useRef(null);
    const [err, setErr] = React.useState("");
    const [t] = useTranslation("common");
    const _userClient = useInjection<IUserApiClient>(TYPES.IUserClient);
    const [loading, setLoading] = React.useState(false);
    const [formModel, setModel] = React.useState<UpdateUserDto | undefined>(undefined);
    const _authStore= AuthStore.useState(w=>w.authSettings);
    const [, organisationId] = useOrganisation();
    React.useEffect(() => {
        _userClient.getUser(props.id).then((res) => {
            setModel({
                id : res.data.id,
                userName : res.data.userName,
                firstName : res.data.firstName,
                lastName : res.data.lastName,
                role : res.data.role,
                team:res.data.team,
                archived : res.data.archived
            });
        }).catch((err) => {
            setErr(t("error.userNotFound"));
        })
    },[])
    const handleFormChanged = (col: string, value: string) => {
        var copy = { ...formModel };
        copy[col] = value;
        setModel(copy);
    };
    
    const saveBtnCalled = () => {
        setLoading(true);
        _userClient.updateUser(formModel)
        .takeAtLeast(timeoutQuery*2)
        .then((res) => {
            props.onSaved();
        }).catch((err) => {
            setErr(parseAxiosError(err));
        })
    }
    const saveValidate = () => {
        if (formRef != null && formRef.current != null) {
            var testForm = formRef.current as any;
            testForm
                .isFormValid()
                .then((result: boolean) => {
                    if (result) {
                        saveBtnCalled();
                    }
                })
                .catch((err: any) => { });
        }
    };
    const changeRole = (role: string) => {
        var model = { ...formModel };
        model.role = Roles[role as keyof typeof Roles];
        setModel(model);
    }
    if(formModel == undefined) return <></>
    return <>
    {formModel == undefined ? <Loader/> : null}
        {err != "" ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="warning">{t(err)}</Alert> : null}
        <ValidatorForm ref={formRef} onSubmit={() => { }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextValidator onChange={(event) => { handleFormChanged("userName", event.target.value) }}
                        id="outlined-basic" value={formModel.userName} className={"app-input-full-width"} label={t("user.userName")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator onChange={(event) => { handleFormChanged("firstName", event.target.value) }}
                        id="outlined-basic" value={formModel.firstName} className={"app-input-full-width"} label={t("user.firstName")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator onChange={(event) => { handleFormChanged("lastName", event.target.value) }}
                        id="outlined-basic" value={formModel.lastName} className={"app-input-full-width"} label={t("user.lastName")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">{t("user.role")}</FormLabel>
                        <RadioGroup
                            onChange={(ev) => { changeRole(ev.target.value) }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel checked={formModel.role == Roles.User} value="User" control={<Radio />} label="User" />
                            <FormControlLabel checked={formModel.role == Roles.SuperVisor} value="SuperVisor" control={<Radio />} label="Supervisor" />
                            {_authStore.role == Roles.Admin && organisationId == 1 ?
                                <><FormControlLabel checked={formModel.role == Roles.Admin} value="Admin" control={<Radio />} label="Admin" /></>
                                : <></>}


                        </RadioGroup>
                    </FormControl>
                </Grid>
                {formModel.role == Roles.User ? <>
                    <Grid item xs={12}>
                       <TeamPicker selectFirst={false} onTeamPicked={(team) => {
                        var copy = {...formModel};
                        copy.team = team;
                        setModel(copy);
                       }} defaultTeam={formModel.team}/>
                    </Grid>
                </> : <></>}
                <Grid item xs={12}>
                    <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch checked={formModel.archived} color="warning" onChange={(ev) => { var copy = { ...formModel }; copy.archived = ev.target.checked; setModel(copy); }} />
                                }
                                label={t("general.archived")}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                {formModel.archived ? <Grid item xs={12}><Alert severity="warning" >{t("error.archivedUser")}</Alert></Grid> : null}
                <Grid item xs={12} container justifyContent={"flex-end"}>
                    <Button onClick={props.onCancel} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                        {t("general.cancel-btn")}
                    </Button>
                    <LoadButton isLoading={loading} disabled={formModel.archived}
                        startIcon={<Save/>}
                        type="submit"
                        onClick={saveValidate}
                        className="no-box-shadow"
                        style={{ textTransform: "capitalize", marginLeft: "10px" }}
                        buttonText={t("general.update-btn")} />

                </Grid>
            </Grid>
        </ValidatorForm>
    </>
}