import { TaskType } from '../clients/globis/models';
import { cyan, yellow, grey, orange, red, green, purple } from '@mui/material/colors';
import FeedbackItem from '@mui/icons-material/Feedback';
import ChatItem from '@mui/icons-material/Chat';
import Diversity from '@mui/icons-material/Diversity1';
import Forum from '@mui/icons-material/Forum';
import AddComment from '@mui/icons-material/AddComment';
export interface TaskStyleProps{
    icon : any,
    color : string,
    textColor : string
    task : TaskType
}

export const taskMapping = [
    { color: yellow[900], textColor: yellow[100], task: TaskType.PIP, icon: FeedbackItem },
    { color: cyan[900], textColor: cyan[100], task: TaskType.TeamTalk, icon: Diversity },
    { color: grey[900], textColor: grey[100], task: TaskType.EvaluationTalk, icon: Forum },
    { color: orange[900], textColor: orange[100], task: TaskType.FeedbackTalk, icon: AddComment },
    { color: grey[900], textColor: grey[100], task: TaskType.TalkInOffice, icon: ChatItem },
    { color: purple[900], textColor: purple[100], task: TaskType.OfficeTask, icon: ChatItem },
] as TaskStyleProps[]

export const scoreColor = (maxScore: number,score:number) => {
    var percentage = (score / maxScore) * 100;
    if(percentage < 35 ){
        return { background: red[100], color : red[900]}
    }else if(percentage <50){
        return { background: orange[100], color: orange[900] }
    }else if(percentage >80){
        return { background: green[100], color: green[900] }
    }
}
export const graphColorMapping = [
    { border: 'rgba(255, 99, 132, 1)', background: 'rgba(255, 99, 132, 0.2)' },
    { border: 'rgba(13, 71, 161,1)', background: 'rgba(13, 71, 161,0.2)' },
    { border: 'rgba(179, 157, 219,1)', background: 'rgba(179, 157, 219,0.2)' },
    { border: 'rgba(128, 203, 196,1)', background: 'rgba(128, 203, 196,0.2)' },
    { border: 'rgba(255, 204, 128,1)', background: 'rgba(255, 204, 128,0.2)' },
    { border: 'rgba(15, 89, 53,1)', background:"rgba(15, 89, 53,0.2)"},
    { border: 'rgba(128, 48, 21,1)', background: "rgba(128, 48, 21,0.2)" },
    { border: 'rgba(87, 196, 229, 1)', background:"rgba(87, 196, 229, 0.2)"},
    { border: 'rgba(243, 66, 19, 1)', background: "rgba(243, 66, 19, 0.2)" },
    { border: 'rgba(88, 125, 113, 1)', background: "rgba(88, 125, 113, 0.2)" },
    { border: 'rgba(243, 222, 138, 1)', background: "rgba(243, 222, 138, 0.2)" },
    { border: 'rgba(145, 145, 233, 1)', background: "rgba(145, 145, 233, 0.2)" },
    { border: 'rgba(237, 246, 125, 1)', background: "rgba(237, 246, 125, 0.2)" },
    { border: 'rgba(86, 65, 56, 1)', background: "rgba(86, 65, 56, 0.2)" },
    { border: 'rgba(104, 195, 212, 1)', background: "rgba(104, 195, 212, 0.2)" }
]