import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { CreateTeamDto, CreateUserDto, CreateEmployeeDto } from '../../clients/globis/models';
import { useInjection } from '../../di/ioc-provider';
import { ITeamsClient } from '../../clients/globis/teamsClient';
import { TYPES } from "../../di/types";
import { parseAxiosError, timeoutQuery } from "../../components/helpers";
import Alert from '@mui/material/Alert';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { LoadButton } from "../../components/loadButton";
import { useOrganisation } from "../../hooks/useOrganisation";
import { useTeam } from "../../hooks/useTeam";
import { IEmployeeClient } from '../../clients/globis/employeeClient';
import { useImmer } from "use-immer";
import Add from "@mui/icons-material/AddCircle"
import { TeamPicker } from "../../components/teamPicker";
export interface CreateTeamProps {
    onCancel: () => void;
    onSaved: () => void;
}


export const CreateEmployee = (props: CreateTeamProps) => {
    const formRef = useRef(null);
    const [err, setErr] = React.useState("");
    const [t] = useTranslation("common");
    const [, organisationId] = useOrganisation();
    const _employeeClient = useInjection<IEmployeeClient>(TYPES.IEmployeeClient);
    const [renderTeam, teamId] = useTeam({ selectFirstTeam: true });
    const [formModel, setModel] = useImmer<CreateEmployeeDto>({
        firstName: "",
        lastName: "",
        team: teamId,
    });
    const [loading, setLoading] = React.useState(false);

    const handleFormChanged = (col: string, value: string) => {
        var copy = { ...formModel };
        copy[col] = value;
        setModel(copy);
    };
    const saveBtnCalled = () => {
        setErr("");
        setLoading(true);
        _employeeClient.create(formModel)
            .takeAtLeast(timeoutQuery)
            .then((res) => {
                props.onSaved();
            }).catch((err) => {
                setErr(parseAxiosError(err));
            })
            .finally(() => {
                setLoading(false);
            })
        // _teamClient.createTeam(formModel).takeAtLeast(timeoutQuery)
        //     .then((res) => {
        //         props.onSaved();
        //     })
        //     .catch((err) => {
        //         setErr(parseAxiosError(err));
        //     }).finally(() => {
        //         setLoading(false);
        //     })
    }
    const saveValidate = () => {
        if (formRef != null && formRef.current != null) {
            var testForm = formRef.current as any;
            testForm
                .isFormValid()
                .then((result: boolean) => {
                    if (result) {
                        saveBtnCalled();
                    }
                })
                .catch((err: any) => { });
        }
    };
    const disableInput = React.useMemo(() => {
        return loading || teamId == null;
    },[loading,teamId])
    return <>
        {teamId == null ? <><Alert sx={{ mt: "4px", mb: "4px" }} severity="warning">{t("error.noTeamForEmployee")}</Alert></>:<></>} 
        {err != "" ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="warning">{t(err)}</Alert> : null}
        <ValidatorForm ref={formRef} onSubmit={() => { }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextValidator disabled={disableInput} onChange={(event) => { handleFormChanged("firstName", event.target.value) }}
                        id="outlined-basic" value={formModel.firstName} className={"app-input-full-width"} label={t("user.firstName")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator disabled={disableInput} onChange={(event) => { handleFormChanged("lastName", event.target.value) }}
                        id="outlined-basic" value={formModel.lastName} className={"app-input-full-width"} label={t("user.lastName")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TeamPicker selectFirst={true} onTeamPicked={(team) => {
                        var copy = { ...formModel };
                        copy.team = team;
                        setModel(copy);
                    }} />
                </Grid>
                <Grid item xs={12} container justifyContent={"flex-end"}>
                    <Button onClick={props.onCancel} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                        {t("general.cancel-btn")}
                    </Button>
                    <LoadButton
                        startIcon={<Add/>}
                        disabled={disableInput}
                    isLoading={loading}
                        type="submit"
                        onClick={saveValidate}
                        className="no-box-shadow"
                        style={{ textTransform: "capitalize", marginLeft: "10px" }}
                        buttonText={t("general.create-btn")} />

                </Grid>
            </Grid>
        </ValidatorForm>
    </>
}