import { EvaluationFicheDto, EvaluationQuestionDto } from "../clients/globis/models"
import { Typography, Grid } from '@mui/material';
import OverviewBlock from "./overviewBlock";
import React from "react";


export interface IEvaluationFicheProps{
    template?: EvaluationFicheDto | undefined;
    renderQuestion? : (question: EvaluationQuestionDto) => any;
}
export const EvaluationFiche = React.forwardRef((props: IEvaluationFicheProps,ref:any) => {

    const renderGroupHeading = (question: EvaluationQuestionDto) => {
        return <>
            <Typography
                style={{ paddingLeft: "20px", marginBottom: "20px" }}
                className="strike"
                variant="h4">
                <span>{question.question}</span>
            </Typography>
            {question.children.map((el) => { return renderQuestion(el) })}
        </>
    }
    const renderRootHeading = (question: EvaluationQuestionDto) => {
        return <> <OverviewBlock color={question.color} style={{ marginBottom: "20px" }}>
            <Typography
                className="alignCenter"
                variant="h3">
                {question.question}
            </Typography>
        </OverviewBlock>
            {question.children.map((cat) => {
                return renderGroupHeading(cat)
            })}
        </>
    }
  
    const renderTemplate = () => {
        if (props.template == undefined) return undefined;
        return <>{props.template.questions.map((el) => {
            return renderRootHeading(el);
        })}</> 
    }
    const renderQuestion = (question: EvaluationQuestionDto) => {
        return <>
            <Grid container style={{ marginBottom: "10px" }}>
                <Grid item md={4} xs={12}>
                    <Typography
                        style={{ paddingLeft: "20px" }}

                        variant="body2">
                        {question.question}
                    </Typography>
                </Grid>
                {props.renderQuestion != undefined ?  props.renderQuestion(question) : <></> }
               
            </Grid>
        </>
    }
    return <>
        {renderTemplate()}
    </>
})