
import {TwitterPicker} from "react-color";
import SketchExample from "./SketchExample";
export interface RgbaColor {
    r: number,
    g : number,
    b : number,
    a : number,
}
export interface ColorPickerProps{
    onChange: (color: string) => void;
    defaultColor? : string
}


export const ColorPicker = (props: ColorPickerProps) => {

    return <>
        <SketchExample defaultColor={props.defaultColor} onChange={(color) => {props.onChange(color )}}></SketchExample>
    </>
}