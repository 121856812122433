import { useInjection } from '../di/ioc-provider';
import { IContentClient } from '../clients/globis/contentClient';
import { TYPES } from '../di/types';
import React from 'react';
import { EvaluationFicheDto, EvaluationQuestionDto, EvaluationQuestionLevel } from '../clients/globis/models';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { updateTypePredicateNodeWithModifier } from 'typescript';
import { EvaluationFicheList } from './evaluationFicheList';
import Typography from '@mui/material/Typography';
import { useTheme, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import { List as linqList } from "linqts";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { LoadButton } from './loadButton';
import Alert from '@mui/material/Alert';

export interface IMergeEvaluationFicheProps {
    onCancel: () => void
    onSave: (res: EvaluationQuestionDto[]) => void
}

export const MergeEvaluationFiche = (props: IMergeEvaluationFicheProps) => {

    const _contentClient = useInjection<IContentClient>(TYPES.IContentClient);
    const [fiche, setFiche] = React.useState<EvaluationFicheDto>();
    const [evId, setEvId] = React.useState<number | undefined>(undefined);
    const [selected, setSelected] = useImmer<number[]>([]);
    const theme = useTheme();
    const [t] = useTranslation("common");
    React.useEffect(() => {
        if (evId != undefined) {
            _contentClient.getEvaluationFiche(evId).then((res) => {
                setFiche(res.data);
            })
        } else {
            setFiche(undefined);
        }

    }, [evId])
    const toggleItem = (item: EvaluationQuestionDto) => {
        setSelected((draft) => {
            var exist = new linqList(draft).Contains(item.id);
            if (exist) {
                return new linqList(draft).Where(w => w != item.id).ToArray();
            } else {
                draft.push(item.id);
            }
        });
    }
    const getHeaderFromQuestion = (questionId : number) => {
        if (fiche == null) return null;
        var id = null;
        fiche.questions.forEach((header) => {
            header.children.forEach((cat) => {
                if(new linqList(cat.children).Any(w=>w.id == questionId)){
                    id = header.id;
                } 
            })
        });
        if(id==null) return id;
        return new linqList(fiche.questions).FirstOrDefault(w=>w.id == id);
    }
    const selectItem = (item: EvaluationQuestionDto, parent: EvaluationQuestionDto) => {

        toggleItem(item);
        if (item.level == EvaluationQuestionLevel.CATEGORIE) {
            catIsClicked(item);
        } else if (item.level == EvaluationQuestionLevel.HEADING) {
            catIsClicked(item);
            for (var i = 0; i < item.children.length; i++) {
                forceActivateChildren(item.children[i], !itemSelected(item))

            }
        }
        else if (item.level == EvaluationQuestionLevel.QUESTION) {
            var header = getHeaderFromQuestion(item.id);
            if (header != null && !itemSelected(header)){
                toggleItem(header);
            }
        }
        if (parent != undefined && !itemSelected(parent))
            toggleItem(parent);
    }
    const forceActivateChildren = (item: EvaluationQuestionDto, activate: boolean) => {

        setSelected((draft) => {
            var list = new linqList(draft);
            if (activate) {
                list.AddRange(new linqList(item.children).Select(w => w.id).ToArray());

            } else {
                return list.Where(w => new linqList(item.children).FirstOrDefault(x => x.id == w) == null).ToArray()
            }

        })
    }
    const itemSelected = (item: EvaluationQuestionDto) => {
        return new linqList(selected).Contains(item.id);

    }
    const catIsClicked = (item: EvaluationQuestionDto) => {
        forceActivateChildren(item, !itemSelected(item))
    }
    const renderItem = (item: EvaluationQuestionDto, parent: EvaluationQuestionDto) => {
        return <ListItem onClick={() => { itemSelected(item) }}
            key={item.id}
            disablePadding
        >
            <ListItemButton role={undefined} style={{ marginLeft: `${item.level * 30}px` }} dense>
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={itemSelected(item)}
                        onChange={() => { selectItem(item, parent) }}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': item.id + "" }}
                    />
                </ListItemIcon>
                <ListItemText id={item.id + ""} primary={item.question} />
            </ListItemButton>
        </ListItem>
    }
    const merge = () => {
        var copy = new linqList([...fiche.questions]);
        var selected_ = new linqList(selected);

        copy = copy.Where(w => selected_.Contains(w.id));

        for (var i = 0; i < copy.Count(); i++) {
            var header = copy.ElementAt(i);
        
            header.children = new linqList(header.children).Where(w => selected_.Contains(w.id)).ToArray();
            for (var j = 0; i < header.children.length; i++) {
                var cat = header.children[j];
                cat.children = new linqList(cat.children).Where(w => selected_.Contains(w.id)).ToArray();
            }
        }
        props.onSave(copy.ToArray());
    }
    return <>
        {fiche == undefined ? <>
            <Alert sx={{ mt: "4px", mb: "4px" }} severity="info">{<Typography
                variant="body2">
                {t("content.mergeSelectOrigin")}
            </Typography>}</Alert>
      
            <EvaluationFicheList includeForeignFiches={true} onRowClick={(el) => { setEvId(el.id) }}></EvaluationFicheList></>
            :
            <>
                <Chip
                    label={`${fiche == undefined ? "" : `${fiche.name}`}`}
                    variant="outlined"
                    onDelete={() => { setEvId(undefined) }}
                />
                <Alert sx={{ mt: "4px", mb: "4px" }} severity="info">{<Typography
                    variant="body2">
                    {t("content.selectItemsToMerge")}
                </Typography>}</Alert>
                <Typography
                    variant="body2">
                    
                </Typography>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {fiche.questions.map((header, headerIndex) => {
                        return <>
                            {renderItem(header, null)}
                            {header.children.map((cat, catIndex) => {

                                return <>{renderItem(cat, header)}
                                    {cat.children.map((question, qIndex) => {

                                        return <>  {renderItem(question, cat)}</>
                                    })}</>
                            })}
                        </>
                    })}
                </List>
            </>}
        <Grid item xs={12} container justifyContent={"flex-end"}>
            <Button onClick={props.onCancel} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                {t("general.cancel-btn")}
            </Button>
            <LoadButton isLoading={false}
                type="submit"
                onClick={() => { merge(); }}
                className="no-box-shadow"
                style={{ textTransform: "capitalize", marginLeft: "10px" }}
                buttonText={t("general.merge-btn")} />

        </Grid>
    </>
}