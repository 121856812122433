import React from "react"
import OverviewBlock from './overviewBlock';
import { Grid, useTheme, Typography, Divider } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useInjection } from '../di/ioc-provider';
import { ITaskClient } from '../clients/globis/taskClient';
import { TYPES } from "../di/types";
import { SortOrder } from "./dcDataTable";
import { TaskDto } from "../clients/globis/models";
import sffjs from 'sffjs';
import { useTranslation } from 'react-i18next';
import { beautifyDate, createdByText, ellipsis } from './helpers';
import HomeIcon from '@mui/icons-material/Home';
import { TaskStyleProps, taskMapping } from './taskTypeMapper';
import { List } from "linqts";
export interface LastEmployeeActionProps {
    task: TaskDto
    enabled : boolean,
    setExpanded : (active : boolean) => void
}
export interface LastEmployeeActionsProps {
    employeeId: number
}
export const LastEmployeeActions = (props: LastEmployeeActionsProps) => {

    const _taskClient = useInjection<ITaskClient>(TYPES.ITaskClient);
    const [tasks, setTasks] = React.useState<TaskDto[]>([]);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    React.useEffect(() => {
        fetchData();
    }, [props.employeeId])

    const fetchData = () => {
        _taskClient.fetchTasks({
            skip: 0,
            take: 3,
            sortColumn: "Created",
            sortOrder: SortOrder.DESC,
            payload: {
                employeeId: props.employeeId,
                search: ""
            }
        }).then((res) => {
            setTasks(res.data.data);
        })
    }

    return <>
        <Grid container spacing={2}>
            {tasks.map((el) => {
                return <><Grid item xs={4}><LastEmployeeAction setExpanded={(active) => {setExpanded(active)}} enabled={expanded} task={el} /></Grid></>
            })}
        </Grid>

    </>
}
export const LastEmployeeAction = (props: LastEmployeeActionProps) => {

    const [t] = useTranslation("common");
    interface ExpandMoreProps extends IconButtonProps {
        expand: boolean;
    }
    const mapped = React.useMemo(() => {
        return new List(taskMapping).FirstOrDefault(w => w.task == props.task.taskType);
    }, [props]);
    const ExpandMore = styled((props: ExpandMoreProps) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        position: "relative",
        bottom: "10px",
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));
    return <>
        <OverviewBlock style={{backgroundColor:mapped.textColor}}>
            <Grid item xs={12} sx={{ mb: 2 }} style={{ minHeight: "3em" }}>
                <mapped.icon fontSize="large" style={{color : mapped.color,marginRight:"4px"}} />
                <b style={{ color: mapped.color,position:"relative",bottom:"12px" }}>{sffjs(t("general.lastActionTitle"), t(`taskType.${props.task.taskType}`))}</b>
            </Grid>
            <Grid container item xs={11} justifyContent={"space-between"}>
                <Grid item xs={12}>
                    <Typography
                     
                        variant="body1">
                        {!props.enabled ? <>   {ellipsis(props.task.text, 50)}</> : <></>}
                        <Collapse in={props.enabled} timeout="auto" unmountOnExit >
                            <span>{props.task.text}</span>
                        </Collapse>
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 1 }}>
                    <Typography
                        variant="body2">
                        {createdByText(t, props.task.created, props.task.createdByName)}
                    </Typography>
                </Grid>

            </Grid>
            <Grid item xs={1}>
                {props.task.text.length > 50 ? <>  <ExpandMore
                    expand={props.enabled}
                    onClick={() => { props.setExpanded(!props.enabled) }}
                    aria-expanded={props.enabled}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore></> : <></>}

            </Grid>
        </OverviewBlock></>
}
