import React from "react";
import DcDataTable, { DcTableHeader, TableFilterState } from "./dcDataTable";
import { useInjection } from "../di/ioc-provider";
import { IContentClient } from '../clients/globis/contentClient';
import { TYPES } from "../di/types";
import { beautifyDate, defaultFilter, getPageSkipTake, getSkip, rowsForTable, sortOnFilter } from "./helpers";
import { EvaluationFicheDto, EvaluationFicheListDto, OrganisationListDto } from '../clients/globis/models';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import { useTranslation } from 'react-i18next';
import { useOrganisation } from '../hooks/useOrganisation';

export interface IValuationFicheProps{
    onRowClick: (dto: EvaluationFicheListDto) => void;
    includeForeignFiches?:boolean
}

export const EvaluationFicheList = (props: IValuationFicheProps) => {
    const [t] = useTranslation("common");
    const headers = [
        { id: "Name", name: t("content.templateName") },
        { id: "Updated", name: t("general.updated") },
        { id: "CreatedByName", name: t("general.createdBy") }
    ] as DcTableHeader[]
    const [loading, setLoading] = React.useState(false);
    const _contentClient = useInjection<IContentClient>(TYPES.IContentClient);
    const [openOrganisation, setOpenOrganisation] = React.useState(false);

    const [filterState, setFilter] = React.useState<TableFilterState>(defaultFilter);
    const [evaluationFiche, setEvaluationFiches] = React.useState<EvaluationFicheListDto[]>([]);
    const [entityCount, setEntityCount] = React.useState<number>(0);
    const [,organisationId] = useOrganisation();
    const fetchData = () => {
        setLoading(true);
        _contentClient.searchEvaluationFiche({
            skip : filterState.skip,
            take : filterState.take,
            sortColumn : filterState.sortColumn,
            sortOrder : filterState.sortOrder,
            payload: { organisation: organisationId, search: "", includeForeignFiches: props.includeForeignFiches?true:false}
        })
            .then((res) => {
                setEntityCount(res.data.entityCount);
                setEvaluationFiches(res.data.data);
            })
    }
    React.useEffect(() => {
        fetchData();
    }, [filterState])
    const onChangePage = (page: number) => {
        var skip = getSkip(page, filterState.take);
        var copy = { ...filterState };
        copy.skip = skip;
        setFilter(copy);
    }
    const onRowClicked = (list: EvaluationFicheListDto) => {
        if (props.onRowClick != undefined) {
            props.onRowClick(list);
        }
    }
    return <>
        <DcDataTable
            headers={headers}
            onFilterClicked={(el) => { setFilter(sortOnFilter(filterState, el)) }}
            filters={filterState}>
            {evaluationFiche.map((el) => {
                return <>
                    <TableRow className="clickable" key={el.id} hover onClick={() => { onRowClicked(el) }}>
                        <TableCell>{el.name}</TableCell>
                        <TableCell>{beautifyDate(el.updated)}</TableCell>
                        <TableCell>{el.createdByName}</TableCell>
                    </TableRow>
                </>
            })}
        </DcDataTable>
        <TablePagination
            labelRowsPerPage={t("general.rowsPerPage")}
            style={{ marginLeft: "auto", color: "black!important" }}
            rowsPerPageOptions={rowsForTable}
            component="div"
            count={entityCount}
            rowsPerPage={filterState.take}
            page={getPageSkipTake(filterState.skip, filterState.take)}
            onPageChange={(event, page) => { onChangePage(page) }}
            onRowsPerPageChange={(event) => {
                var copy = { ...filterState };
                copy.take = Number.parseInt(event.target.value);
                setFilter(copy);
            }}
        />

    </>
}