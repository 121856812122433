import { TableSortLabel } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
export enum SortOrder {
    ASC, DESC
}
export interface TableFilterState {
    search: string,
    skip: number,
    take: number,
    sortOrder?: SortOrder,
    sortColumn?: string
}
export interface DcTableHeader {
    id: string,
    name: string
}
export interface IDcTableProps {
    headers: DcTableHeader[],
    children: any;
    filters?: TableFilterState,
    onFilterClicked?: (id: string) => void;
}

export const DcDataTable = (props: IDcTableProps) => {
    return (<Table size="small">
        <TableHead>
            <TableRow >
                {props.headers.map((el) =>
                (
                    <TableCell onClick={() => { if (props.onFilterClicked != null) props.onFilterClicked(el.id) }} key={el.name}>
                        {props.filters != null && props.filters.sortOrder != null ?
                            <TableSortLabel
                                active={props.filters.sortColumn === el.id}
                                direction={props.filters.sortOrder == SortOrder.ASC ? "asc" : "desc"}
                                onClick={() => { if (props.onFilterClicked != null) props.onFilterClicked(el.id) }}
                            >

                                {el.name}
                            </TableSortLabel> : el.name}

                    </TableCell>
                ))}

            </TableRow>
        </TableHead>
        <TableBody >
            {props.children}
        </TableBody>
    </Table>)
}

export default DcDataTable;