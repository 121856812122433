import React from "react";
import { useRef } from "react";
import { useInjection } from '../../di/ioc-provider';
import { IUserApiClient } from '../../clients/globis/userApiClient';
import { TYPES } from "../../di/types";
import { CreateUserDto, Roles } from "../../clients/globis/models";
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useOrganisation } from '../../hooks/useOrganisation';
import Button from '@mui/material/Button';
import { LoadButton } from "../../components/loadButton";
import { parseAxiosError, timeoutQuery } from "../../components/helpers";
import { useTeam } from "../../hooks/useTeam";
import Add from "@mui/icons-material/AddCircle"
import { TeamPicker } from "../../components/teamPicker";
import { AuthStore } from "../../stores/AuthStore";
export interface CreateUserProps {
    onCancel: () => void;
    onSaved: () => void;
}

export const CreateUserForm = (props: CreateUserProps) => {
    const formRef = useRef(null);
    const [err, setErr] = React.useState("");
    const [t] = useTranslation("common");
    const _userClient = useInjection<IUserApiClient>(TYPES.IUserClient);
    const [, organisationId] = useOrganisation();
    const [loading, setLoading] = React.useState(false);
    const authStore = AuthStore.useState(w => w.authSettings);
    const [formModel, setModel] = React.useState<CreateUserDto>(
        {
            userName: "",
            firstName: "",
            lastName: "",
            role: Roles.User,
            organisation: organisationId,
        }
    );

    const handleFormChanged = (col: string, value: string) => {
        var copy = { ...formModel };
        copy[col] = value;
        setModel(copy);
    };
    const saveBtnCalled = () => {
        setLoading(true);
        _userClient.createUser(formModel)
            .takeAtLeast(timeoutQuery)
            .then((res) => {
                props.onSaved();
            })
            .catch((err) => {
                setErr(parseAxiosError(err));
            }).finally(() => {
                setLoading(false);
            })
    }
    const saveValidate = () => {
        if (formRef != null && formRef.current != null) {
            var testForm = formRef.current as any;
            testForm
                .isFormValid()
                .then((result: boolean) => {
                    if (result) {
                        saveBtnCalled();
                    }
                })
                .catch((err: any) => { });
        }
    };
    const changeRole = (role: string) => {
        var model = { ...formModel };
        model.role = Roles[role as keyof typeof Roles];
        setModel(model);
    }
    return <>
        {err != "" ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="warning">{t(err)}</Alert> : null}
        <ValidatorForm ref={formRef} onSubmit={() => { }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextValidator onChange={(event) => { handleFormChanged("userName", event.target.value) }}
                        id="outlined-basic" value={formModel.userName} className={"app-input-full-width"} label={t("user.userName")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator onChange={(event) => { handleFormChanged("firstName", event.target.value) }}
                        id="outlined-basic" value={formModel.firstName} className={"app-input-full-width"} label={t("user.firstName")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator onChange={(event) => { handleFormChanged("lastName", event.target.value) }}
                        id="outlined-basic" value={formModel.lastName} className={"app-input-full-width"} label={t("user.lastName")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">{t("user.role")}</FormLabel>
                        <RadioGroup
                            onChange={(ev) => { changeRole(ev.target.value) }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel checked={formModel.role == Roles.User} value="User" control={<Radio />} label="User" />
                            <FormControlLabel checked={formModel.role == Roles.SuperVisor} value="SuperVisor" control={<Radio />} label="Supervisor" />
                            {authStore.role == Roles.Admin && organisationId == 1 ?
                                <><FormControlLabel checked={formModel.role == Roles.Admin} value="Admin" control={<Radio />} label="Admin" /></>
                                : <></>}


                        </RadioGroup>
                    </FormControl>
                </Grid>
                {formModel.role == Roles.User ? <>
                    <Grid item xs={12}>
                        <TeamPicker selectFirst={true} onTeamPicked={(team) => {
                            var copy = { ...formModel };
                            copy.team = team;
                            setModel(copy);
                        }} />
                    </Grid>
                </> : <></>}
                <Grid item xs={12} container justifyContent={"flex-end"}>
                    <Button onClick={props.onCancel} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                        {t("general.cancel-btn")}
                    </Button>
                    <LoadButton isLoading={loading}
                        startIcon={<Add />}
                        type="submit"
                        onClick={saveValidate}
                        className="no-box-shadow"
                        style={{ textTransform: "capitalize", marginLeft: "10px" }}
                        buttonText={t("general.create-btn")} />

                </Grid>
            </Grid>
        </ValidatorForm>
    </>
}