import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { CreateTeamDto, CreateWorkAssigmentDto, UpdateWorkAssigment } from '../../clients/globis/models';
import { useInjection } from '../../di/ioc-provider';
import { ITeamsClient } from '../../clients/globis/teamsClient';
import { TYPES } from "../../di/types";
import { parseAxiosError, timeoutQuery } from "../../components/helpers";
import Alert from '@mui/material/Alert';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { LoadButton } from "../../components/loadButton";
import { useOrganisation } from "../../hooks/useOrganisation";
import Add from "@mui/icons-material/AddCircle"
import { EvDropdown } from "../../components/evaluationFicheDropdown";
import { ITaskClient } from "../../clients/globis/taskClient";
export interface UpdateWorkAssignment {
    id : number,
    text:string,
    onCancel: () => void;
    onSaved: (dto: UpdateWorkAssigment) => void;
}


export const UpdateWorkAssignment = (props: UpdateWorkAssignment) => {
    const formRef = useRef(null);
    const [err, setErr] = React.useState("");
    const [t] = useTranslation("common");
    const [, organisationId] = useOrganisation();
    const _taskClient = useInjection<ITaskClient>(TYPES.ITaskClient);
    const [formModel, setModel] = React.useState<UpdateWorkAssigment>({
        id : props.id,
        text:props.text
    });

    const [loading, setLoading] = React.useState(false);
    const handleFormChanged = (col: string, value: string) => {
        var copy = { ...formModel };
        copy[col] = value;
        setModel(copy);
    };
    const saveBtnCalled = () => {
        setLoading(true);
        _taskClient.updateWorkAssignment(formModel).then((res) => {
            props.onSaved(formModel);
        }).catch((err) => {
            setErr(parseAxiosError(err));
        }).finally(() => {
            setLoading(false);
        })
      
    }
    const saveValidate = () => {
        if (formRef != null && formRef.current != null) {
            var testForm = formRef.current as any;
            testForm
                .isFormValid()
                .then((result: boolean) => {
                    if (result) {
                        saveBtnCalled();
                    }
                })
                .catch((err: any) => { });
        }
    };
    return <>

        {err != "" ? <Alert sx={{ mt: "4px", mb: "4px" }} severity="warning">{t(err)}</Alert> : null}
        <ValidatorForm ref={formRef} onSubmit={() => { }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid item xs={11}>
                        <TextValidator label={t("workAssignment.text")} style={{ backgroundColor: "white" }} disabled={loading} minRows={4} multiline onChange={(event) => { handleFormChanged("text", event.target.value) }}
                            id="outlined-basic" value={formModel.text} className={"app-input-full-width"} variant="outlined" size="small"
                            validators={["required"]}
                            errorMessages={[t("validation.required")]}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent={"flex-end"}>
                    <Button onClick={props.onCancel} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                        {t("general.cancel-btn")}
                    </Button>
                    <LoadButton isLoading={loading}
                        startIcon={<Add />}
                        type="submit"
                        onClick={saveValidate}
                        className="no-box-shadow"
                        style={{ textTransform: "capitalize", marginLeft: "10px" }}
                        buttonText={t("general.update-btn")} />

                </Grid>
            </Grid>
        </ValidatorForm>
    </>
}