import { useRef } from "react";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Grid } from '@mui/material';
import React from "react";
import { useImmer } from "use-immer";
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { LoadButton } from "../../components/loadButton";
import { EvaluationQuestionLevel } from '../../clients/globis/models';
import { ColorPicker } from "../../components/colorpicker";

export interface IQuestionFormModel {
    text: string,
    color?: string | null
}
export interface INewQuestionProps {
    onCancel: () => void;
    onSave: (model: IQuestionFormModel) => void;
    onSaveAddNew?: (model: IQuestionFormModel) => void;
    forLevel: EvaluationQuestionLevel,
    default?: IQuestionFormModel
}

export const NewQuestion = (props: INewQuestionProps) => {
    const formRef = useRef(null);
    const [formModel, setFormModel] = useImmer<IQuestionFormModel>({
        text: props.default == undefined ? "" : props.default.text,
        color: props.default == undefined ? undefined : props.default.color,
    });
    const [t] = useTranslation("common");
    const [loading, setLoading] = React.useState(false);

    const saveValidate = (addNew: boolean) => {

        if (formRef != null && formRef.current != null) {
            var testForm = formRef.current as any;
            testForm
                .isFormValid()
                .then((result: boolean) => {
                    if (result) {
                        setLoading(true);
                        if (!addNew)
                            props.onSave(formModel)
                        else
                            props.onSaveAddNew(formModel);
                    }       
                })
                .catch((err: any) => { });
        }
    };

    return <>
        <ValidatorForm ref={formRef} onSubmit={() => { }}>
            <Grid container spacing={2}>
                {props.forLevel == EvaluationQuestionLevel.HEADING ? <>
                    <Grid item xs={12}> <ColorPicker defaultColor={formModel.color} onChange={(color) => setFormModel((draft) => { draft.color = color })} /></Grid>

                </> : <>
                </>}
                <Grid item xs={12}>
                    <TextValidator autoFocus onChange={(event) => { setFormModel((draft) => { draft.text = event.target.value }) }}
                        id="outlined-basic" value={formModel.text} className={"app-input-full-width"} label={t("content.title")} variant="outlined" size="small"
                        validators={["required"]}
                        errorMessages={[t("validation.required")]}
                    />
                </Grid>



                <Grid item xs={12} container justifyContent={"flex-end"}>
                    <Button onClick={props.onCancel} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                        {t("general.cancel-btn")}
                    </Button>
                    <LoadButton isLoading={loading}
                        type="submit"
                        onClick={() => { saveValidate(false) }}
                        className="no-box-shadow"
                        style={{ textTransform: "capitalize", marginLeft: "10px" }}
                        buttonText={t("general.create-btn")} />
                    {props.forLevel == EvaluationQuestionLevel.QUESTION && props.onSaveAddNew != undefined ? <>
                        <LoadButton isLoading={loading}
                            type="submit"
                            onClick={() => { saveValidate(true) }}
                            className="no-box-shadow"
                            style={{ textTransform: "capitalize", marginLeft: "10px" }}
                            buttonText={t("general.createAddNew-btn")} /></> : <></>}

                </Grid>
            </Grid>
        </ValidatorForm>
    </>
}