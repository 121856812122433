import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OverviewBlock from '../components/overviewBlock';
import { useInjection } from '../di/ioc-provider';
import { ITeamsClient } from '../clients/globis/teamsClient';
import { TYPES } from '../di/types';
import React from 'react';
import { TableFilterState } from '../components/dcDataTable';
import { defaultFilter, beautifyDate } from '../components/helpers';
import { useOrganisation } from '../hooks/useOrganisation';
import { TeamListDto, AvgScoreDto, EvaluationQuestionLevel } from '../clients/globis/models';
import Button from '@mui/material/Button';
import GeneralDialog from '../components/generalDialog';
import { CreateTeamForm } from '../forms/team/createTeam';
import { UpdateTeamForm } from '../forms/team/updateTeam';
import { IContentClient } from '../clients/globis/contentClient';
import { List } from 'linqts';
import Add from "@mui/icons-material/AddCircle"
import sffjs from 'sffjs';


export const TeamsPage = () => {

    const theme = useTheme();
    const [t] = useTranslation("common");
    const _teamClient = useInjection<ITeamsClient>(TYPES.ITeamsClient);
    const _contentClient = useInjection<IContentClient>(TYPES.IContentClient);
    const [filterState, setFilter] = React.useState<TableFilterState>(defaultFilter);
    const [loading,setLoading] = React.useState(false);
    const [, organisationId] = useOrganisation();
    const [teams,setTeams] = React.useState<TeamListDto[]>([]);
    const [enitityCount,setEntityCount] = React.useState(0);
    const [updateTeamId, setUpdateTeamId] = React.useState<TeamListDto | undefined>(undefined);
    const [openCreateTeam,setOpenCreateTeam] = React.useState(false);
    const [scores,setScores] = React.useState<AvgScoreDto[]>([])
    React.useEffect(() => {
        fetchData();
    },[filterState,organisationId])
    const fetchData = () => {
        setLoading(true);
        _teamClient.fetchTeams({
            skip: filterState.skip,
            take: filterState.take,
            sortColumn: filterState.sortColumn,
            sortOrder: filterState.sortOrder,
            payload: {
                organisation: organisationId,
                search: ""
            }
        }).then((res) => {
            setEntityCount(res.data.entityCount);
            setTeams(res.data.data);
        })
    }
    React.useEffect(() => {
        fetchTeamStats();
    },[teams])
    const fetchTeamStats = () => {
        _contentClient.getAvgScoresByTeams({teamIds:new List(teams).Select(w=>w.id).ToArray()})
        .then((res) => {
            setScores(res.data.scores);
        })
    }
    const renderScore = (teamId : number) =>{
        var scores_ = new List(scores).Where(w => w.level == EvaluationQuestionLevel.HEADING).Where(w => w.team == teamId);

        if (scores_.Any()) {
            return scores_.ToArray().map((el,index) => {
                return <><span title={el.question}>{el.score} {scores_.Count() > index+1?<>/ </>:<></>}</span></>
            })
        }
        return <></>
    }
    const renderTeam = (req : TeamListDto) => {
       
        return <>
            <OverviewBlock enableHoverAnimation={true} onClick={() => {
                setUpdateTeamId(req);
            }} >
                <Grid container justifyContent={"space-between"}>
                    <Grid item xs={6}>
                        <Typography
                            variant="body1">
                            {req.name}
                        </Typography>
                        {req.lastEvaluationCreated != null ? <><Typography
                            style={{ fontSize: "0.8rem", marginTop: "10px" }}
                            variant="body2">{sffjs(t("general.lastEvaluationTeam"), beautifyDate(req.lastEvaluationCreated), req.lastEvaluationCreatedByName)}
                        </Typography></> : <></>}
                        
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "end", display: "flex", justifyContent: "end", alignItems: "center" }}>
                        <Typography
                            variant="body2">
                            {renderScore(req.id)}
                        </Typography>
                    </Grid>
                </Grid>
            </OverviewBlock></>
    }
    return <>
    {updateTeamId == undefined? <></>:<>
            <GeneralDialog open={updateTeamId != undefined} onClose={() => { 
                
                setUpdateTeamId(undefined) }}
                title={t("general.updateTeam")} >
                <>
                    <UpdateTeamForm showDeleteButton={updateTeamId.amtEmployees == 0} id={updateTeamId.id} onSaved={() => {
                        setFilter({ ...defaultFilter });
                        setUpdateTeamId(undefined);
                    }} onCancel={() => { setFilter({ ...defaultFilter }); setUpdateTeamId(undefined); }}></UpdateTeamForm>
                </>
            </GeneralDialog>
    </>}
        <GeneralDialog onClose={() => { setOpenCreateTeam(false) }} title={t("general.createTeam")} open={openCreateTeam}>
            <>
                <CreateTeamForm onSaved={() => {
                    setFilter({ ...defaultFilter });
                    setOpenCreateTeam(false);
                }} onCancel={() => { setOpenCreateTeam(false) }}></CreateTeamForm>
            </>
        </GeneralDialog>
        <Grid container justifyContent={"space-between"}>
            <Grid item>
                <Typography
                    color={(theme as any).palette.text.heading}
                    variant="h1">
                    {t("route.teams")}
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    startIcon={<Add/>}
                    className="no-box-shadow full-width"
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                    onClick={(e) => { setOpenCreateTeam(true) }}
                    color="primary">
                    {t("general.createTeam")}
                </Button>
                {/* {renderTeamPicker()} */}
            </Grid>
        </Grid>
        <Grid container style={{ marginTop: "20px" }} spacing={2}>
            {teams.map((el) => {
                return <>
                    <Grid item xs={12}>
                        {renderTeam(el)}
                    </Grid>
                </>
            })}
        </Grid>
    </>
}
