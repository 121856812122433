import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Language from "@mui/icons-material/Language";
import Logout from "@mui/icons-material/Logout";
import { useTheme, Grid } from '@mui/material';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MuiList from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import jwt_decode from "jwt-decode";
import { List } from "linqts";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Roles } from '../config/roles';
import { useMultipleInjection } from '../di/ioc-provider';
import { TYPES } from '../di/types';
import { useOrganisation } from '../hooks/useOrganisation';
import { IRoute } from '../routing/routeInterface';
import { AuthStore } from '../stores/AuthStore';
import Corpo from '@mui/icons-material/MapsHomeWork';
import AutoFixHigh from "@mui/icons-material/AutoFixHigh"
import { LoadButton } from '../components/loadButton';
import GeneralDialog from '../components/generalDialog';
import { QuickAction } from '../pages/quickAction';
import { UIStore } from '../stores/UiStore';
import img from "../assets/globisLogoHD_thumbnail.png";
import { Welcome, Wiki } from '../components/welcome';
function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(7),
                },
            }),
        },
    }),
);
export interface MainLayoutProps {
    children?: any
}
const styles = {
    paper: {
        background: "blue"
    }
}
export const MainLayout = (props: MainLayoutProps) => {
  
    const _routes = useMultipleInjection<IRoute>(TYPES.IRoute);
    var history = useHistory();
    const [renderOrg,,renderMobileOrg] = useOrganisation();
    const apiKey = AuthStore.useState(w=>w.authSettings.jwtToken);
    const authObj = AuthStore.useState(w=>w.authSettings);
    const [openQuickAction,setOpenQuickAction] = React.useState(false);
    if(apiKey == null || apiKey == '')
        history.push("/login");
    const [t, i18n] = useTranslation("common");
    const open = UIStore.useState(w=>w.navbarOpen);
    React.useEffect(() => {
        if(authObj.email=="" && authObj.name == ""&&authObj.jwtToken!="" && authObj.jwtToken!=null){
            //Token is filled in but app isn't initialized with context yet 
            var info = jwt_decode(authObj.jwtToken) as any;
            var org = null;
            if (info.organisation != null) {
                org = parseInt(info.organisation);
            }
            var team = null;
            try {
                var x = Number.parseInt(info.team);
                if (!isNaN(x)) team = x;
            } catch { }
            var obj = { newUser: info.newUser === "True", jwtToken: authObj.jwtToken, team: team, name: info.name, email: info.email, role: Roles[info.role as keyof typeof Roles], organisation: org };
            AuthStore.update(w => { w.authSettings = obj });
        }
    },[authObj])
    const toggleDrawer = () => {
        UIStore.update((draft) => {draft.navbarOpen = !draft.navbarOpen});
       
    };
    const theme = useTheme();
    const usableLinks = React.useMemo(() => {
        if(authObj!=null){
            return new List(_routes).Where(w => w.allowInNavigation).Where(w => w.role<= authObj.role).ToArray()
        }else return [];
       
    }, [_routes, authObj])

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);

    }
    const goToRoute = (route: IRoute) => {
        history.push(route.path)
    }
    const renderQuickActionButton = (open : boolean) => {
        if (open) {
            return <> <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <LoadButton 
                    style={{ fontSize: "1.1em" }}
                startIcon={<AutoFixHigh fontSize='large'/>} 
                onClick={() => { setOpenQuickAction(true); }} 
                buttonText={t("general.WizardBtn")} 
                isLoading={false} 
                color="secondary" 
                variant='contained' ></LoadButton>
                </div></>
        }
        return <>
            <MuiList >
                <ListItemButton onClick={() => { setOpenQuickAction(true); }}>
                    <ListItemIcon >
                        <AutoFixHigh color={"secondary"} onClick={() => { setOpenQuickAction(true); }} />
                    </ListItemIcon>
                </ListItemButton>
            </MuiList>
        </>
    }
    
    const renderOrganisationButton = (open: boolean) => {
        if (authObj.role != Roles.Admin) return <></>
        if (open) {
            return <> <div style={{ textAlign: "center", marginBottom: "20px" }}>{renderOrg()}</div></>
        }
        return <>
            <MuiList >
                {renderMobileOrg()}
            </MuiList>
        </>
    }
    const renderLanguagePicker = (open: boolean) => {
        if (open) {
            return <div className='langlink' style={{ textAlign: "center", marginBottom: "10px" }} ><span onClick={() => { changeLanguage("nl") }} className={i18n.language == 'nl' ? 'activeLang' : ''}>NL</span> | <span onClick={() => { changeLanguage("fr") }} className={i18n.language == 'fr' ? 'activeLang' : ''}>FR</span> | <span onClick={() => { changeLanguage("en") }} className={i18n.language == 'en' ? 'activeLang' : ''}>EN</span></div>
        }
        return <>
            <MuiList >
                
                <ListItemButton onClick={() => { UIStore.update(w=>{w.navbarOpen = true})}}>
                    <ListItemIcon >
                        <Language color={"secondary"} />
                    </ListItemIcon>
                </ListItemButton>
            </MuiList>
        </>
    }
    const logout = () => {
        window.localStorage.removeItem("jwt");
        AuthStore.update(w=>{w.authSettings.jwtToken = ""});
        
    }
    const renderLogout = (open : boolean) => {
        if(open){
            return <div style={{ textAlign: "center", marginBottom: "10px" }} ><IconButton><Logout onClick={() => { logout()}} color={"secondary"} /></IconButton></div>
        }

        return <>
            <MuiList >

                <ListItemButton onClick={() => { }}>
                    <ListItemIcon >
                        <Logout onClick={() => { logout() }} color={"secondary"} />
                    </ListItemIcon>
                </ListItemButton>
            </MuiList>
        </> 
    }
    return <>
        <GeneralDialog onClose={() => { setOpenQuickAction(false) }} title={t("general.wizard")} open={openQuickAction}>
            <>
                <QuickAction onCancel={() => { setOpenQuickAction(false) }} />
            </>
        </GeneralDialog>
        {authObj.newUser? <><Welcome/></> : <></>}
        
        <Box sx={{ display: 'flex' }} >
            <CssBaseline />
           
            <Drawer variant="permanent" open={open} className='noprint'>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <img src={img} style={{width:"74%"}}/>
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <MuiList component="nav">
                    {usableLinks.map((el) => {
                        return <>
                            <ListItemButton onClick={() => { goToRoute(el) }}>
                                <ListItemIcon>
                                    {el.icon}
                                </ListItemIcon>
                                <ListItemText color={(theme as any).palette.text.primaryContrast} primary={t(`route.${el.routeKey}`)} />
                            </ListItemButton>
                        </>
                    })}
                </MuiList>
                <div style={{ marginTop: "auto" }}></div>
                {renderQuickActionButton(open)}
                {renderOrganisationButton(open)}
                {renderLanguagePicker(open)}
                {renderLogout(open)}
            </Drawer>
            <Box
                
                component="main"
                sx={{
                    backgroundColor: (theme) => (theme as any).background.primary,
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar><Grid container justifyContent={"flex-end"}><Grid item > <Wiki defaultOpen={false} /></Grid></Grid> </Toolbar>
                <Container maxWidth="xl" >
                    {props.children}
                    {/* <Copyright sx={{ pt: 4 }} /> */}
                </Container>
            </Box>
        </Box>
    </>
}
export default MainLayout
