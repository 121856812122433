import { Store } from "pullstate";


export const UIStore = new Store({
    globalMessage: undefined as INotificationMessage,
    bffAccessToken: "" as string,
    navbarOpen : true as boolean
});

export type MessageType = "warning" | "error" | "info" | "success";

export default interface INotificationMessage {
    type: MessageType,
    message: string

}