import { inject, injectable } from 'inversify';
import "reflect-metadata";
import { PageDataRequest, PagedData, SearchModel, UserListDto, CreateUserDto, LoginModel, OrganisationListDto, CreateOrganisationDto, CreateEmployeeDto, UpdateEmployeeDto, EmployeeDto, EmployeeSearchModel } from './models';
import Axios, { AxiosResponse } from "axios";
import IAppConfig from '../../config/config';
import { TYPES } from '../../di/types';
export interface IEmployeeClient {
    create(empDto: CreateEmployeeDto): Promise<AxiosResponse<number>>
    update(empDto: UpdateEmployeeDto): Promise<AxiosResponse>
    search(req: PageDataRequest<EmployeeSearchModel>): Promise<AxiosResponse<PagedData<EmployeeDto>>>
    get(id: number): Promise<AxiosResponse<EmployeeDto>>;
}

@injectable()
export class EmployeeClient implements IEmployeeClient {


    apiUrl = process.env.REACT_APP_BFF_URL
    apiPrefix = "api/Employees";

    @inject(TYPES.IAppConfig) private _config: IAppConfig;
    get(id: number): Promise<AxiosResponse<EmployeeDto>> {
        return Axios.get(`${this._config.endpointUrl}${this.apiPrefix}/GetEmployee?id=${id}`);
    }
    create(req: CreateEmployeeDto): Promise<AxiosResponse<number>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/CreateEmployee`, req);
    }
    update(req: UpdateEmployeeDto): Promise<AxiosResponse<any>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/UpdateEmployee`, req);
    }
    search(req: PageDataRequest<EmployeeSearchModel>): Promise<AxiosResponse<PagedData<EmployeeDto>>> {
        return Axios.post(`${this._config.endpointUrl}${this.apiPrefix}/Fetch`, req);
    }
}