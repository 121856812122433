import { useTranslation } from "react-i18next"
import GeneralDialog from "./generalDialog"
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { LoadButton } from "./loadButton";
import Add from '@mui/icons-material/AddCircle';
import HelpCenter from "@mui/icons-material/HelpCenter"
import Info from "@mui/icons-material/Info";
import ArrowForward from "@mui/icons-material/ArrowForwardIos";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from "@mui/material/ListItemButton";
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import CrisisAlert from "@mui/icons-material/CrisisAlert";
import CorporateFare from "@mui/icons-material/CorporateFare";
import ContentPaste from "@mui/icons-material/ContentPaste";
import Groups from "@mui/icons-material/Groups";
import Dashboard from "@mui/icons-material/Dashboard";
import QuestionAswered from "@mui/icons-material/QuestionAnswer";
import React from "react";
import { MultilineText } from "./multilineText";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PeopleIcon from "@mui/icons-material/People";
import LocalPhone from "@mui/icons-material/LocalPhone";
import img1 from "../assets/tutorial/1.png";
import img2 from "../assets/tutorial/2.png";
import img3 from "../assets/tutorial/3.png";
import img4 from "../assets/tutorial/4.png";
import img5 from "../assets/tutorial/5.png";
import img6 from "../assets/tutorial/6.png";
import img7 from "../assets/tutorial/7.png";
import img8 from "../assets/tutorial/8.png";
import img9 from "../assets/tutorial/9.png";
import img10 from "../assets/tutorial/10.png";
import img11 from "../assets/tutorial/11.png";
import img12 from "../assets/tutorial/12.png";
import img13 from "../assets/tutorial/13.png";
import img14 from "../assets/tutorial/14.png";
import img15 from "../assets/tutorial/15.png";
import img16 from "../assets/tutorial/16.png";
import img17 from "../assets/tutorial/17.png";
import img18 from "../assets/tutorial/18.png";
import { AuthStore } from "../stores/AuthStore";
export const Welcome = () => {
    const [dialogOpen,setDialogOpen] = React.useState(true);
    const [renderWiki, setRenderWiki] = React.useState(false);
    const [t] = useTranslation("common");
    return<> <GeneralDialog onClose={() => { }} title={<span><Info />{t("welcome.welcomeMsg1.title")}</span>} open={dialogOpen}>
        <>
            <MultilineText text={"welcome.welcomeMsg1.body"} />
            <Grid item xs={12} container justifyContent={"flex-end"}>
                <Button onClick={() => { setDialogOpen(false); AuthStore.update((draft) => {draft.authSettings.newUser = false;}) }} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                    {t("welcome.skipTutorialBtnText")}
                </Button>
                <LoadButton isLoading={false}

                    endIcon={<ArrowForward />}
                    type="submit"
                    onClick={() => { setRenderWiki(true);
                    setDialogOpen(false) }}
                    className="no-box-shadow"
                    style={{ textTransform: "capitalize", marginLeft: "10px" }}
                    buttonText={t("welcome.startTutorialBtnText")} />

            </Grid>
        </>
    </GeneralDialog>
    {renderWiki ? <><Wiki defaultOpen={true} defaultSelected={0}/></>:<></>}
    </>
}
export interface WikiListObject {
    title: string,
    icon: any,
    component: any
}
export interface WikiProps {
    defaultOpen : boolean,
    defaultSelected?:number
}
export const Wiki = (props: WikiProps) => {
    const [t] = useTranslation("common");
    const options = [{
        title: t("wiki.setup"),
        icon: <CrisisAlert color="primary" />,
        component: <WikiSetup />
    },
    {
        title: t("wiki.evaluationFiches"),
        icon: <ContentPaste color="primary" />,
        component: <WikiEvFiches />
    },
    {
        title: t("wiki.employees"),
        icon: <Groups color="primary" />,
        component: <WikiEmployee />
        }, {
        title: t("wiki.users"),
        icon: <PeopleIcon color="primary" />,
        component: <WikiUsers />
        },
    {
        title: t("wiki.evaluations"),
        icon: <Dashboard color="primary" />,
        component: <WikiEvaluations />
    },
    {
        title: t("wiki.actions"),
        icon: <QuestionAswered color="primary" />,
        component: <WikiActions />
    },
        {
            title: t("wiki.contact"),
            icon: <LocalPhone color="primary" />,
            component: <WikiContact/>
        }] as WikiListObject[]
    const [wikiIsOpen, setWikiIsOpen] = React.useState(props.defaultOpen);
    const [topicSelected, setTopicSelected] = React.useState<WikiListObject | undefined>(undefined);

    React.useEffect(() => {
        if(props.defaultSelected!=undefined){
            setTopicSelected(options[props.defaultSelected])
        }
    },[props.defaultSelected])
    return <>
        {props.defaultOpen ? <></> : <><IconButton onClick={() => { setWikiIsOpen(true) }}>
            <HelpCenter />
        </IconButton></>}
        <GeneralDialog fullWidth={true} maxWidth="xl" onClose={() => { }} title={<span><HelpCenter />{t("wiki.title")}</span>} open={wikiIsOpen}>
            <Grid container spacing={2}>
                <Grid item md={4}>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        {options.map((el) => {
                            return <ListItemButton selected={topicSelected?.title == el.title} onClick={() => { setTopicSelected(el) }}>
                                <ListItemAvatar color="white">
                                    <Avatar variant="rounded" sx={{ bgcolor: "#90CAF9" }}>
                                        {el.icon}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={el.title} />
                            </ListItemButton>
                        })}
                    </List>
                </Grid>
                <Grid style={{overflow:"auto", height:"70vh"}} item md={8}>
                    {topicSelected == undefined ? <> <MultilineText text={t("wiki.welcome")} /></> : <>
                        {topicSelected.component}</>}
                </Grid>
                {/* {t("welcome.welcomeMsg1.body").split('\n').map(item => <p>{item}</p>)} */}
                <Grid item xs={12} container justifyContent={"flex-end"}>
                    <Button onClick={() => { setWikiIsOpen(false) }} variant="outlined" style={{ textTransform: "capitalize" }} color="primary">
                        {t("general.cancel-btn")}
                    </Button>
                    {/* <LoadButton isLoading={false}

                        endIcon={<ArrowForward />}
                        type="submit"
                        onClick={() => { }}
                        className="no-box-shadow"
                        style={{ textTransform: "capitalize", marginLeft: "10px" }}
                        buttonText={t("welcome.startTutorialBtnText")} /> */}

                </Grid>
            </Grid>
        </GeneralDialog>
    </>
}
export interface BaseWikiSetup{
   
}
export const WikiSetup = (props: BaseWikiSetup) => {
    const [t] = useTranslation("common");
    return <><Grid container>
        <Grid item xs={12}>
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.setup")}
            </Typography>
          
            <MultilineText text="wiki.setupTopic.firstSection" />
            <img className="tutimg" src={img2} />
            <img className="tutimg" src={img4} />
            <img className="tutimg" src={img5} />
            <img className="tutimg" src={img6} />
            <img className="tutimg" src={img7} />
            <img className="tutimg" src={img8} />
       
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.setupTopic.bodyTitle")}
            </Typography>
            <MultilineText text="wiki.setupTopic.body" />
 
            <img className="tutimg" src={img9} />
            <img className="tutimg" src={img10} />
            <img className="tutimg" src={img11} />
            <img className="tutimg" src={img12} />
            <img className="tutimg" src={img13} />
            <img className="tutimg" src={img14} />
            <img className="tutimg" src={img15} />
         
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.setupTopic.evFicheToTeamTitle")}
            </Typography>
            <MultilineText text="wiki.setupTopic.evFicheToTeam" />
            <img className="tutimg" src={img16} />
            <img className="tutimg" src={img17} />
            <img className="tutimg" src={img18} />
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.setupTopic.userTitle")}
            </Typography>
            <MultilineText text="wiki.setupTopic.userBody" />
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.setupTopic.whatsNextTitle")}
            </Typography>
            <MultilineText text="wiki.setupTopic.whatsNextBody" />
        </Grid>
    </Grid></>
}
export const WikiEvFiches = () => {
    const [t] = useTranslation("common");
    return <><Grid container>
        <Grid item xs={12}>
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.evaluationFiches")}
            </Typography>

            <MultilineText text="wiki.evaluationFichesTopic.body" />
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.evaluationFichesTopic.sjabloonToTeamTitle")}
            </Typography>

            <MultilineText text="wiki.evaluationFichesTopic.sjabloonToTeam" />
            
        </Grid>
    </Grid></>
}

export const WikiEmployee = () => {
    const [t] = useTranslation("common");
    return <><Grid container>
        <Grid item xs={12}>
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.employees")}
            </Typography>

            <MultilineText text="wiki.employeesTopic.body" />
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.employeesTopic.employeeOverviewTitle")}
            </Typography>

            <MultilineText text="wiki.employeesTopic.employeeOverview" />

        </Grid>
    </Grid></>
}
export const WikiUsers = () => {
    const [t] = useTranslation("common");
    return <><Grid container>
        <Grid item xs={12}>
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.users")}
            </Typography>

            <MultilineText text="wiki.usersTopic.body" />
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.usersTopic.loginTitle")}
            </Typography>

            <MultilineText text="wiki.usersTopic.login" />

        </Grid>
    </Grid></>
}
export const WikiEvaluations = () => {
    const [t] = useTranslation("common");
    return <><Grid container>
        <Grid item xs={12}>
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.evaluations")}
            </Typography>

            <MultilineText text="wiki.evaluationsTopic.body" />
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.evaluationsTopic.dashboardTitle")}
            </Typography>

            <MultilineText text="wiki.evaluationsTopic.dashboard" />

        </Grid>
    </Grid></>
}
export const WikiActions = () => {
    const [t] = useTranslation("common");
    return <><Grid container>
        <Grid item xs={12}>
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.actions")}
            </Typography>

            <MultilineText text="wiki.actionsTopic.body" />
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.actionsTopic.workTitle")}
            </Typography>

            <MultilineText text="wiki.actionsTopic.work" />

        </Grid>
    </Grid></>
}
export const WikiContact = () => {
    const [t] = useTranslation("common");
    return <><Grid container>
        <Grid item xs={12}>
            <Typography
                color="primary"
                variant="h3">
                {t("wiki.contact")}
            </Typography>

            <MultilineText text="wiki.contactTopic.body" />
            <p><a href="https://globis-consulting.be/" target="_blank" >globis-consulting.be</a></p>
            <p>{t("wiki.madeBy")} <a href="https://www.deercode.be/nl/home/" target="_blank" >Arne De Bremme</a></p>
        </Grid>
    </Grid></>
}
export const WikiOrganisatie = () => {
    return <>
    
    </>
}